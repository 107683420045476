import React from "react";

const SubscriptionList = () => {
  return (
    <div>
      <img src={"coming_soon_2.png"} alt="" width="100%" />
    </div>
  );
};

export default SubscriptionList;
