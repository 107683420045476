// import React from "react";
// import { Link } from "react-router-dom";

// function Quiz({ quiz }) {
//   return (
//     <div className="shadow-lg p-3 mb-5 bg-white rounded">
//       <h1>{quiz.name}</h1>
//       <br></br>
//       <p style={{ textAlign: "left" }}>Subject : {quiz.subject}</p>
//       <p style={{ textAlign: "left" }}>Class : {quiz.classs}</p>
//       <p style={{ textAlign: "left" }}>Category : {quiz.dificulty}</p>
//       <Link to="/questions" state={{ questions: quiz.questions }}>
//         <button className="btn btn-primary">Start Quiz</button>
//       </Link>
//     </div>
//   );
// }

// export default Quiz;
//-----------------------------------------------------------------------------------
// import React from "react";
// import { Link } from "react-router-dom";
// import "./Quiz.css";

// function Quiz({ quiz }) {
//   const modifyImageUrl = (imageUrl) => {
//     const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
//     return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
//   };

//   // Define a common style object for button shadows
//   const buttonShadowStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//     transition: "background-color 0.3s",
//   };

//   return (
//     <div className="shadow-lg p-3 mb-5 bg-white" id="round">
//       <img
//         src={modifyImageUrl(quiz.quiz_image)}
//         alt="Quiz"
//         width="200px"
//         height="200px"
//         className="img-fluid"
//       />

//       <br></br>
//       <br></br>
//       <h1
//         style={{
//           fontFamily: "'Georgia Ref', Georgia, serif",
//           fontWeight: "bold",
//           fontSize: "20px",
//           textAlign: "center",
//         }}
//       >
//         {quiz.name}
//       </h1>
//       <br />
//       {/* <p style={{ textAlign: "left" }}>Subject: {quiz.subject}</p>
//       <p style={{ textAlign: "left" }}>Class: {quiz.classs}</p>
//       <p style={{ textAlign: "left" }}>Category: {quiz.difficulty}</p> */}
//       <Link
//         to="/questions"
//         state={{
//           name: quiz.name,
//           questions: quiz.questions,
//           division: quiz.division,
//         }}
//       >
//         <button
//           className="btn btn-primary"
//           style={{
//             ...buttonShadowStyle,
//             fontFamily: "'Georgia Ref', Georgia, serif",
//             //fontWeight: "bold",
//             fontSize: "25px",
//             textAlign: "center",
//           }}
//           //onClick={handleAttemptedQuiz(quiz._id)}
//         >
//           Start Quiz
//         </button>
//       </Link>
//     </div>
//   );
// }

// export default Quiz;
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import "./Quiz.css";

// function Quiz({ quiz, userId }) {
//   const navigate = useNavigate();

//   const modifyImageUrl = (imageUrl) => {
//     const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
//     return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
//   };

//   // Define a common style object for button shadows
//   const buttonShadowStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//     transition: "background-color 0.3s",
//   };

//   const handleStartQuiz = async () => {
//     try {
//       await axios.post("/api/users/attemptquiz", {
//         userId,
//         quizId: quiz._id,
//       });
//       navigate("/questions", {
//         state: {
//           name: quiz.name,
//           questions: quiz.questions,
//           division: quiz.division,
//         },
//       });
//     } catch (error) {
//       console.error("Error updating attempted quizzes", error);
//     }
//   };

//   return (
//     <div className="shadow-lg p-3 mb-5 bg-white" id="round">
//       <img
//         src={modifyImageUrl(quiz.quiz_image)}
//         alt="Quiz"
//         width="200px"
//         height="200px"
//         className="img-fluid"
//       />

//       <br></br>
//       <br></br>
//       <h1
//         style={{
//           fontFamily: "'Georgia Ref', Georgia, serif",
//           fontWeight: "bold",
//           fontSize: "20px",
//           textAlign: "center",
//         }}
//       >
//         {quiz.name}
//       </h1>
//       <br />
//       <button
//         className="btn btn-primary"
//         style={{
//           ...buttonShadowStyle,
//           fontFamily: "'Georgia Ref', Georgia, serif",
//           fontSize: "25px",
//           textAlign: "center",
//         }}
//         onClick={handleStartQuiz}
//       >
//         Start Quiz
//       </button>
//     </div>
//   );
// }

// export default Quiz;
////////////////////////////////////////////////////////////////////////////////////////////////////
// import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import "./Quiz.css";

// function Quiz({ quiz, userId, attemptedQuizIds }) {
//   const navigate = useNavigate();

//   const modifyImageUrl = (imageUrl) => {
//     const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
//     return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
//   };

//   // Define a common style object for button shadows
//   const buttonShadowStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//     transition: "background-color 0.3s",
//   };

//   const handleStartOrResumeQuiz = async () => {
//     try {
//       if (!attemptedQuizIds.includes(quiz._id)) {
//         await axios.post("/api/users/attemptquiz", {
//           userId,
//           quizId: quiz._id,
//         });
//       }
//       navigate("/questions", {
//         state: {
//           name: quiz.name,
//           questions: quiz.questions,
//           division: quiz.division,
//         },
//       });
//     } catch (error) {
//       console.error("Error updating attempted quizzes", error);
//     }
//   };

//   return (
//     <div className="shadow-lg p-3 mb-5 bg-white" id="round">
//       <img
//         src={modifyImageUrl(quiz.quiz_image)}
//         alt="Quiz"
//         width="200px"
//         height="200px"
//         className="img-fluid"
//       />

//       <br></br>
//       <br></br>
//       <h1
//         style={{
//           fontFamily: "'Georgia Ref', Georgia, serif",
//           fontWeight: "bold",
//           fontSize: "20px",
//           textAlign: "center",
//         }}
//       >
//         {quiz.name}
//       </h1>
//       <br />
//       <button
//         className="btn btn-primary"
//         style={{
//           ...buttonShadowStyle,
//           fontFamily: "'Georgia Ref', Georgia, serif",
//           fontSize: "25px",
//           textAlign: "center",
//         }}
//         onClick={handleStartOrResumeQuiz}
//       >
//         {attemptedQuizIds.includes(quiz._id) ? "Resume Quiz" : "Start Quiz"}
//       </button>
//     </div>
//   );
// }

// export default Quiz;
////////////////////////////////////////////////////////////////////////////////////////
// import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import "./Quiz.css";

// function Quiz({ quiz, userId, attemptedQuizIds }) {
//   const navigate = useNavigate();

//   const modifyImageUrl = (imageUrl) => {
//     const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
//     return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
//   };

//   // Define a common style object for button shadows
//   const buttonShadowStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//     transition: "background-color 0.3s",
//   };

//   const handleStartOrResumeQuiz = async () => {
//     try {
//       if (!attemptedQuizIds.includes(quiz._id)) {
//         await axios.post("/api/users/attemptquiz", {
//           userId,
//           quizId: quiz._id,
//         });
//       }
//       navigate("/questions", {
//         state: {
//           name: quiz.name,
//           questions: quiz.questions,
//           division: quiz.division,
//           userId: userId, // Pass userId to QuestionPage
//           quizId: quiz._id, // Pass quizId to QuestionPage
//         },
//       });
//     } catch (error) {
//       console.error("Error updating attempted quizzes", error);
//     }
//   };

//   return (
//     <div className="shadow-lg p-3 mb-5 bg-white" id="round">
//       <img
//         src={modifyImageUrl(quiz.quiz_image)}
//         alt="Quiz"
//         width="200px"
//         height="200px"
//         className="img-fluid"
//       />
//       <br></br>
//       <br></br>
//       <h1
//         style={{
//           fontFamily: "'Georgia Ref', Georgia, serif",
//           fontWeight: "bold",
//           fontSize: "20px",
//           textAlign: "center",
//         }}
//       >
//         {quiz.name}
//       </h1>
//       <br />
//       <button
//         className="btn btn-primary"
//         style={{
//           ...buttonShadowStyle,
//           fontFamily: "'Georgia Ref', Georgia, serif",
//           fontSize: "25px",
//           textAlign: "center",
//         }}
//         onClick={handleStartOrResumeQuiz}
//       >
//         {attemptedQuizIds.includes(quiz._id) ? "Resume Quiz" : "Start Quiz"}
//       </button>
//     </div>
//   );
// }

// export default Quiz;
///////////////////////////////////////////////////////////////////////////////////////
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Quiz.css";

function Quiz({
  quiz,
  userId,
  userName,
  userSchoolcode,
  userClass,
  userSection,
  attemptedQuizIds,
  completedQuizIds,
}) {
  const navigate = useNavigate();

  const modifyImageUrl = (imageUrl) => {
    const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
    return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
  };

  // Define a common style object for button shadows
  const buttonShadowStyle = {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
    transition: "background-color 0.3s",
  };

  const handleStartOrResumeQuiz = async () => {
    try {
      if (
        !attemptedQuizIds.includes(quiz._id) &&
        !completedQuizIds.includes(quiz._id)
      ) {
        await axios.post("/api/users/attemptquiz", {
          userId,
          quizId: quiz._id,
          totalQuestions: quiz.questions.length,
          correctAnswers: "",
          wrongAnswers: "",
          attemptedQuestions: "",
          division: quiz.division,
          userName,
          userSchoolcode,
          userClass,
          userSection,
          quizName: quiz.name,
          quizCategory: quiz.difficulty,
          quizSubject: quiz.subject,
        });
      }
      navigate("/questions", {
        state: {
          userName: userName,
          userSchoolcode: userSchoolcode,
          userClass: userClass,
          userSection: userSection,
          name: quiz.name,
          questions: quiz.questions,
          division: quiz.division,
          userId: userId, // Pass userId to QuestionPage
          quizId: quiz._id, // Pass quizId to QuestionPage
          quizName: quiz.name,
          quizCategory: quiz.difficulty,
          quizSubject: quiz.subject,
        },
      });
    } catch (error) {
      console.error("Error at Start/Resume Quiz", error);
    }
  };

  const getButtonLabel = () => {
    if (completedQuizIds.includes(quiz._id)) {
      return "Retake Quiz";
    }
    if (attemptedQuizIds.includes(quiz._id)) {
      return "Resume Quiz";
    }
    return "Start Quiz";
  };

  return (
    <div className="shadow-lg p-3 mb-5 bg-white" id="round">
      {quiz.quiz_image && (
        <img
          src={modifyImageUrl(quiz.quiz_image)}
          alt="Quiz"
          width="200px"
          height="200px"
          className="img-fluid"
        />
      )}
      <br></br>
      <br></br>
      {quiz.name && (
        <h1
          style={{
            fontFamily: "'Georgia Ref', Georgia, serif",
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {quiz.name}
        </h1>
      )}
      <br />
      <button
        className="btn btn-primary"
        style={{
          ...buttonShadowStyle,
          fontFamily: "'Georgia Ref', Georgia, serif",
          fontSize: "25px",
          textAlign: "center",
        }}
        onClick={handleStartOrResumeQuiz}
        //disabled={completedQuizIds.includes(quiz._id)} // Disable button if quiz is completed
      >
        {getButtonLabel()}
      </button>
    </div>
  );
}

export default Quiz;
/////////////////////////////////////////////////////////////////////
