// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { registerUser, getAllUsers } from "../actions/userActions";
// import { getAllSchoolcodes } from "../actions/schoolcodeActions";
// import Error from "../components/Error";
// import Success from "../components/Success";
// import Loading from "../components/Loading";

// export default function Registerscreen() {
//   const [name, setname] = useState("");
//   const [school, setschool] = useState("");
//   const [schoolcode, setschoolcode] = useState("");
//   const [classs, setclasss] = useState("");
//   const [city, setcity] = useState("");
//   const [mobile, setmobile] = useState("");
//   const [email, setemail] = useState("");
//   const [password, setpassword] = useState("");
//   const [cpassword, setcpassword] = useState("");
//   const [registrationType, setRegistrationType] = useState("schoolCode");
//   const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
//   const [isSchoolCodeValid, setIsSchoolCodeValid] = useState(true); // Initialize to true
//   const users = useSelector((state) => state.getAllUsersReducer.users);
//   const registerstate = useSelector((state) => state.registerUserReducer);
//   const { error, loading, success } = registerstate;
//   const schoolcodes = useSelector(
//     (state) => state.getAllSchoolcodesReducer.schoolcodes
//   );
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllSchoolcodes());
//     dispatch(getAllUsers()); // Fetch all users from the database
//   }, [dispatch]);

//   useEffect(() => {
//     const allFieldsFilled =
//       name !== "" &&
//       classs !== "" &&
//       city !== "" &&
//       mobile !== "" &&
//       email !== "" &&
//       password !== "" &&
//       cpassword !== "" &&
//       (registrationType === "schoolCode" ? schoolcode !== "" : true);
//     setIsAllFieldsFilled(allFieldsFilled);
//   }, [
//     name,
//     schoolcode,
//     classs,
//     city,
//     mobile,
//     email,
//     password,
//     cpassword,
//     registrationType,
//   ]);

//   function register() {
//     if (password !== cpassword) {
//       alert("Passwords do not match");
//     } else if (users.some((user) => user.email === email)) {
//       alert("Email Already Registered");
//     } else {
//       if (registrationType === "schoolCode") {
//         const isValid = schoolcodes.some((code) => code.code === schoolcode);
//         setIsSchoolCodeValid(isValid);
//         if (!isValid) {
//           alert("School Code is Invalid");
//           return;
//         }
//       }
//       const user = {
//         name,
//         mobile,
//         school,
//         schoolcode: registrationType === "schoolCode" ? schoolcode : "",
//         classs,
//         city,
//         email,
//         password,
//       };
//       console.log(user);
//       dispatch(registerUser(user));
//     }
//   }

//   return (
//     <div>
//       <div className="row justify-content-center mt-5">
//         <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 bg-white rounded">
//           {loading && <Loading />}
//           {success && <Success success="User Registered Successfully" />}
//           {error && <Error error="Email already registered" />}
//           {/* {!isSchoolCodeValid && registrationType === "schoolCode" && (
//             <Error error="School Code is Invalid" />
//           )} */}

//           <h2 className="text-center m-2" style={{ fontSize: "35px" }}>
//             Register
//           </h2>
//           <div>
//             <input
//               required
//               type="text"
//               placeholder="Your Name"
//               className="form-control"
//               value={name}
//               onChange={(e) => {
//                 setname(e.target.value);
//               }}
//             />
//             <input
//               required
//               type="text"
//               placeholder="Class"
//               className="form-control"
//               value={classs}
//               onChange={(e) => {
//                 setclasss(e.target.value);
//               }}
//             />
//             <input
//               required
//               type="text"
//               placeholder="School Name"
//               className="form-control"
//               value={school}
//               onChange={(e) => {
//                 setschool(e.target.value);
//               }}
//             />
//             <input
//               required
//               type="text"
//               placeholder="City"
//               className="form-control"
//               value={city}
//               onChange={(e) => {
//                 setcity(e.target.value);
//               }}
//             />
//             <input
//               required
//               type="text"
//               placeholder="Mobile"
//               className="form-control"
//               value={mobile}
//               onChange={(e) => {
//                 setmobile(e.target.value);
//               }}
//             />
//             <input
//               required
//               type="text"
//               placeholder="Email"
//               className="form-control"
//               value={email}
//               onChange={(e) => {
//                 setemail(e.target.value);
//               }}
//             />
//             <input
//               required
//               type="password"
//               placeholder="Password"
//               className="form-control"
//               value={password}
//               onChange={(e) => {
//                 setpassword(e.target.value);
//               }}
//             />
//             <input
//               required
//               type="password"
//               placeholder="Confirm Password"
//               className="form-control mb-3"
//               value={cpassword}
//               onChange={(e) => {
//                 setcpassword(e.target.value);
//               }}
//             />

//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="schoolCode"
//                 value="schoolCode"
//                 checked={registrationType === "schoolCode"}
//                 onChange={() => setRegistrationType("schoolCode")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="schoolCode"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register with school code
//               </label>
//             </div>
//             {/* <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="individual"
//                 value="individual"
//                 checked={registrationType === "individual"}
//                 onChange={() => setRegistrationType("individual")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="individual"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register as an individual without school code
//               </label>
//             </div> */}

//             {registrationType === "schoolCode" && (
//               <div>
//                 <input
//                   required
//                   type="text"
//                   placeholder="School Code"
//                   className="form-control"
//                   value={schoolcode}
//                   onChange={(e) => {
//                     setschoolcode(e.target.value);
//                   }}
//                 />
//               </div>
//             )}

//             <button
//               onClick={register}
//               className="btn mt-3 mb-3"
//               disabled={!isAllFieldsFilled}
//             >
//               REGISTER
//             </button>
//             <br></br>
//             <a style={{ color: "black" }} href="/login">
//               Click Here To Login
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
/////////////////////////////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { registerUser, getAllUsers } from "../actions/userActions";
// import { getAllSchoolcodes } from "../actions/schoolcodeActions";
// import { getAllClassss } from "../actions/classsActions";
// import Error from "../components/Error";
// import Success from "../components/Success";
// import Loading from "../components/Loading";

// export default function Registerscreen() {
//   const [firstname, setfirstname] = useState("");
//   const [lastname, setlastname] = useState("");
//   const [name, setname] = useState("");
//   const [school, setschool] = useState("");
//   const [schoolcode, setschoolcode] = useState("");
//   const [classs, setclasss] = useState("");
//   const [section, setsection] = useState("");
//   const [city, setcity] = useState("");
//   const [mobile, setmobile] = useState("");
//   const [email, setemail] = useState("");
//   const [password, setpassword] = useState("");
//   const [cpassword, setcpassword] = useState("");
//   const [registrationType, setRegistrationType] = useState("schoolCode");
//   const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
//   const [isSchoolCodeValid, setIsSchoolCodeValid] = useState(true); // Initialize to true
//   const users = useSelector((state) => state.getAllUsersReducer.users);
//   const registerstate = useSelector((state) => state.registerUserReducer);
//   const { error, loading, success } = registerstate;
//   const schoolcodes = useSelector(
//     (state) => state.getAllSchoolcodesReducer.schoolcodes
//   );
//   const classssState = useSelector((state) => state.getAllClassssReducer);
//   const {
//     classss: classes,
//     loading: classssLoading,
//     error: classssError,
//   } = classssState;

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllSchoolcodes());
//     dispatch(getAllUsers()); // Fetch all users from the database
//     dispatch(getAllClassss()); // Fetch all classes
//   }, [dispatch]);

//   useEffect(() => {
//     const allFieldsFilled =
//       firstname !== "" &&
//       lastname !== "" &&
//       name !== "" &&
//       classs !== "" &&
//       section !== "" &&
//       city !== "" &&
//       mobile !== "" &&
//       email !== "" &&
//       password !== "" &&
//       cpassword !== "" &&
//       (registrationType === "schoolCode" ? schoolcode !== "" : true);
//     setIsAllFieldsFilled(allFieldsFilled);
//   }, [
//     firstname,
//     lastname,
//     name,
//     schoolcode,
//     classs,
//     section,
//     city,
//     mobile,
//     email,
//     password,
//     cpassword,
//     registrationType,
//   ]);

//   function register() {
//     if (password !== cpassword) {
//       alert("Passwords do not match");
//     } else if (users.some((user) => user.email === email)) {
//       alert("Email Already Registered");
//     } else {
//       if (registrationType === "schoolCode") {
//         const isValid = schoolcodes.some((code) => code.code === schoolcode);
//         setIsSchoolCodeValid(isValid);
//         if (!isValid) {
//           alert("School Code is Invalid");
//           return;
//         }
//       }
//       const user = {
//         firstname,
//         lastname,
//         name,
//         mobile,
//         school,
//         schoolcode: registrationType === "schoolCode" ? schoolcode : "",
//         classs,
//         section,
//         city,
//         email,
//         password,
//       };
//       console.log(user);
//       {
//         registrationType === "schoolCode"
//           ? dispatch(registerUser(user))
//           : (window.location.href = "/paymentplans");
//       }
//     }
//   }

//   return (
//     <div>
//       <div className="row justify-content-center mt-5">
//         <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 bg-white rounded">
//           {loading && <Loading />}
//           {success && <Success success="User Registered Successfully" />}
//           {error && <Error error="Email already registered" />}
//           {/* {!isSchoolCodeValid && registrationType === "schoolCode" && (
//             <Error error="School Code is Invalid" />
//           )} */}

//           <h2 className="text-center m-2" style={{ fontSize: "35px" }}>
//             Register
//           </h2>
//           <div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="First Name"
//                 className="form-control"
//                 value={firstname}
//                 onChange={(e) => {
//                   setfirstname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Last Name"
//                 className="form-control"
//                 value={lastname}
//                 onChange={(e) => {
//                   setlastname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Full Name"
//                 className="form-control"
//                 value={name}
//                 onChange={(e) => {
//                   setname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black" }}
//               />
//             </div>

//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={classs}
//                 onChange={(e) => setclasss(e.target.value)}
//                 style={{ marginBottom: "0px" }}
//               >
//                 <option value="">Select Class</option>
//                 {classes &&
//                   classes.map((cls) => (
//                     <option key={cls._id} value={cls.cls}>
//                       {cls.cls}
//                     </option>
//                   ))}
//               </select>
//             </div>
//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={section}
//                 onChange={(e) => setsection(e.target.value)}
//                 style={{ marginBottom: "20px" }}
//               >
//                 <option value="">Select Class Section</option>
//                 <option key="A" value="A">
//                   A
//                 </option>
//                 <option key="B" value="B">
//                   B
//                 </option>
//                 <option key="C" value="C">
//                   C
//                 </option>
//                 <option key="D" value="D">
//                   D
//                 </option>
//                 <option key="E" value="E">
//                   E
//                 </option>
//                 <option key="F" value="F">
//                   F
//                 </option>
//                 <option key="G" value="G">
//                   G
//                 </option>
//                 <option key="H" value="H">
//                   H
//                 </option>
//               </select>
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="School Name"
//                 className="form-control"
//                 value={school}
//                 onChange={(e) => {
//                   setschool(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="City"
//                 className="form-control"
//                 value={city}
//                 onChange={(e) => {
//                   setcity(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Mobile"
//                 className="form-control"
//                 value={mobile}
//                 onChange={(e) => {
//                   setmobile(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Email"
//                 className="form-control"
//                 value={email}
//                 onChange={(e) => {
//                   setemail(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>

//             <input
//               required
//               type="password"
//               placeholder="Password"
//               className="form-control"
//               value={password}
//               onChange={(e) => {
//                 setpassword(e.target.value);
//               }}
//               style={{ border: "1px solid black", marginBottom: "10px" }}
//             />
//             <input
//               required
//               type="password"
//               placeholder="Confirm Password"
//               className="form-control mb-3"
//               value={cpassword}
//               onChange={(e) => {
//                 setcpassword(e.target.value);
//               }}
//               style={{ border: "1px solid black", marginBottom: "10px" }}
//             />

//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="schoolCode"
//                 value="schoolCode"
//                 checked={registrationType === "schoolCode"}
//                 onChange={() => setRegistrationType("schoolCode")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="schoolCode"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register with school code
//               </label>
//             </div>
//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="individual"
//                 value="individual"
//                 checked={registrationType === "individual"}
//                 onChange={() => setRegistrationType("individual")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="individual"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register as an individual without school code
//               </label>
//             </div>

//             {registrationType === "schoolCode" && (
//               <div>
//                 <input
//                   required
//                   type="text"
//                   placeholder="School Code"
//                   className="form-control"
//                   value={schoolcode}
//                   onChange={(e) => {
//                     setschoolcode(e.target.value);
//                   }}
//                 />
//               </div>
//             )}

//             <button
//               onClick={register}
//               className="btn mt-3 mb-3"
//               disabled={!isAllFieldsFilled}
//             >
//               REGISTER
//             </button>
//             <br></br>
//             <a style={{ color: "black" }} href="/login">
//               Click Here To Login
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
/////////////////////////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { registerUser, getAllUsers } from "../actions/userActions";
// import { getAllSchoolcodes } from "../actions/schoolcodeActions";
// import { getAllClassss } from "../actions/classsActions";
// import Error from "../components/Error";
// import Success from "../components/Success";
// import Loading from "../components/Loading";

// export default function Registerscreen() {
//   const [firstname, setfirstname] = useState("");
//   const [lastname, setlastname] = useState("");
//   const [name, setname] = useState("");
//   const [school, setschool] = useState("");
//   const [schoolcode, setschoolcode] = useState("");
//   const [classs, setclasss] = useState("");
//   const [section, setsection] = useState("");
//   const [city, setcity] = useState("");
//   const [mobile, setmobile] = useState("");
//   const [email, setemail] = useState("");
//   const [password, setpassword] = useState("");
//   const [cpassword, setcpassword] = useState("");
//   const [registrationType, setRegistrationType] = useState("schoolCode");
//   const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
//   const [isSchoolCodeValid, setIsSchoolCodeValid] = useState(true); // Initialize to true
//   const [rollNumber, setRollNumber] = useState("");

//   const users = useSelector((state) => state.getAllUsersReducer.users);
//   const registerstate = useSelector((state) => state.registerUserReducer);
//   const { error, loading, success } = registerstate;
//   const schoolcodes = useSelector(
//     (state) => state.getAllSchoolcodesReducer.schoolcodes
//   );
//   const classssState = useSelector((state) => state.getAllClassssReducer);
//   const {
//     classss: classes,
//     loading: classssLoading,
//     error: classssError,
//   } = classssState;

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllSchoolcodes());
//     dispatch(getAllUsers()); // Fetch all users from the database
//     dispatch(getAllClassss()); // Fetch all classes
//   }, [dispatch]);

//   useEffect(() => {
//     const allFieldsFilled =
//       firstname !== "" &&
//       lastname !== "" &&
//       name !== "" &&
//       classs !== "" &&
//       section !== "" &&
//       city !== "" &&
//       mobile !== "" &&
//       email !== "" &&
//       password !== "" &&
//       cpassword !== "" &&
//       (registrationType === "schoolCode" ? schoolcode !== "" : true) &&
//       rollNumber !== "";
//     setIsAllFieldsFilled(allFieldsFilled);
//   }, [
//     firstname,
//     lastname,
//     name,
//     schoolcode,
//     classs,
//     section,
//     city,
//     mobile,
//     email,
//     password,
//     cpassword,
//     registrationType,
//     rollNumber,
//   ]);

//   function register() {
//     if (password !== cpassword) {
//       alert("Passwords do not match");
//     } else if (users.some((user) => user.email === email)) {
//       alert("Email Already Registered");
//     } else {
//       if (registrationType === "schoolCode") {
//         const isValid = schoolcodes.some((code) => code.code === schoolcode);
//         setIsSchoolCodeValid(isValid);
//         if (!isValid) {
//           alert("School Code is Invalid");
//           return;
//         }
//       }
//       const user = {
//         firstname,
//         lastname,
//         name,
//         mobile,
//         school,
//         schoolcode: registrationType === "schoolCode" ? schoolcode : "",
//         classs,
//         section,
//         city,
//         email,
//         password,
//         rollNumber,
//       };
//       console.log(user);
//       {
//         registrationType === "schoolCode"
//           ? dispatch(registerUser(user))
//           : (window.location.href = "/paymentplans");
//       }
//     }
//   }

//   return (
//     <div>
//       <div className="row justify-content-center mt-5">
//         <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 bg-white rounded">
//           {loading && <Loading />}
//           {success && <Success success="User Registered Successfully" />}
//           {error && <Error error="Email already registered" />}

//           <h2 className="text-center m-2" style={{ fontSize: "35px" }}>
//             Register
//           </h2>
//           <div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="First Name"
//                 className="form-control"
//                 value={firstname}
//                 onChange={(e) => {
//                   setfirstname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Last Name"
//                 className="form-control"
//                 value={lastname}
//                 onChange={(e) => {
//                   setlastname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Full Name"
//                 className="form-control"
//                 value={name}
//                 onChange={(e) => {
//                   setname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black" }}
//               />
//             </div>

//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={rollNumber}
//                 onChange={(e) => setRollNumber(e.target.value)}
//                 style={{ border: "1px solid black", marginBottom: "0px" }}
//               >
//                 <option value="">Select Roll Number</option>
//                 {Array.from({ length: 100 }, (_, i) => i + 1).map((num) => (
//                   <option key={num} value={num}>
//                     {num}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={classs}
//                 onChange={(e) => setclasss(e.target.value)}
//                 style={{ marginBottom: "0px" }}
//               >
//                 <option value="">Select Class</option>
//                 {classes &&
//                   classes.map((cls) => (
//                     <option key={cls._id} value={cls.cls}>
//                       {cls.cls}
//                     </option>
//                   ))}
//               </select>
//             </div>
//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={section}
//                 onChange={(e) => setsection(e.target.value)}
//                 style={{ marginBottom: "20px" }}
//               >
//                 <option value="">Select Class Section</option>
//                 <option key="A" value="A">
//                   A
//                 </option>
//                 <option key="B" value="B">
//                   B
//                 </option>
//                 <option key="C" value="C">
//                   C
//                 </option>
//                 <option key="D" value="D">
//                   D
//                 </option>
//                 <option key="E" value="E">
//                   E
//                 </option>
//                 <option key="F" value="F">
//                   F
//                 </option>
//                 <option key="G" value="G">
//                   G
//                 </option>
//                 <option key="H" value="H">
//                   H
//                 </option>
//               </select>
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="School Name"
//                 className="form-control"
//                 value={school}
//                 onChange={(e) => {
//                   setschool(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="City"
//                 className="form-control"
//                 value={city}
//                 onChange={(e) => {
//                   setcity(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Mobile"
//                 className="form-control"
//                 value={mobile}
//                 onChange={(e) => {
//                   setmobile(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Email"
//                 className="form-control"
//                 value={email}
//                 onChange={(e) => {
//                   setemail(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>

//             <input
//               required
//               type="password"
//               placeholder="Password"
//               className="form-control"
//               value={password}
//               onChange={(e) => {
//                 setpassword(e.target.value);
//               }}
//               style={{ border: "1px solid black", marginBottom: "10px" }}
//             />
//             <input
//               required
//               type="password"
//               placeholder="Confirm Password"
//               className="form-control mb-3"
//               value={cpassword}
//               onChange={(e) => {
//                 setcpassword(e.target.value);
//               }}
//               style={{ border: "1px solid black", marginBottom: "10px" }}
//             />

//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="schoolCode"
//                 value="schoolCode"
//                 checked={registrationType === "schoolCode"}
//                 onChange={() => setRegistrationType("schoolCode")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="schoolCode"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register with school code
//               </label>
//             </div>
//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="individual"
//                 value="individual"
//                 checked={registrationType === "individual"}
//                 onChange={() => setRegistrationType("individual")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="individual"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register as an individual without school code
//               </label>
//             </div>

//             {registrationType === "schoolCode" && (
//               <div>
//                 <input
//                   required
//                   type="text"
//                   placeholder="School Code"
//                   className="form-control"
//                   value={schoolcode}
//                   onChange={(e) => {
//                     setschoolcode(e.target.value);
//                   }}
//                   style={{ border: "1px solid black", marginBottom: "10px" }}
//                 />
//               </div>
//             )}

//             <button
//               onClick={register}
//               className="btn mt-3 mb-3"
//               disabled={!isAllFieldsFilled}
//             >
//               REGISTER
//             </button>
//             <br></br>
//             <a style={{ color: "black" }} href="/login">
//               Click Here To Login
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
/////////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { registerUser, getAllUsers } from "../actions/userActions";
// import { getAllSchoolcodes } from "../actions/schoolcodeActions";
// import { getAllClassss } from "../actions/classsActions";
// import { getAllStudents } from "../actions/studentActions"; // Import getAllStudents action
// import Error from "../components/Error";
// import Success from "../components/Success";
// import Loading from "../components/Loading";

// export default function Registerscreen() {
//   const [firstname, setfirstname] = useState("");
//   const [lastname, setlastname] = useState("");
//   const [middlename, setmiddlename] = useState("");
//   const [name, setname] = useState("");
//   const [school, setschool] = useState("");
//   const [schoolcode, setschoolcode] = useState("");
//   const [classs, setclasss] = useState("");
//   const [section, setsection] = useState("");
//   const [city, setcity] = useState("");
//   const [mobile, setmobile] = useState("");
//   const [email, setemail] = useState("");
//   const [paid, setpaid] = useState(false);
//   const [password, setpassword] = useState("");
//   const [cpassword, setcpassword] = useState("");
//   const [registrationType, setRegistrationType] = useState("schoolCode");
//   const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
//   const [isSchoolCodeValid, setIsSchoolCodeValid] = useState(true); // Initialize to true
//   const [rollNumber, setRollNumber] = useState("");

//   const users = useSelector((state) => state.getAllUsersReducer.users);
//   const registerstate = useSelector((state) => state.registerUserReducer);
//   const { error, loading, success } = registerstate;
//   const schoolcodes = useSelector(
//     (state) => state.getAllSchoolcodesReducer.schoolcodes
//   );
//   const classssState = useSelector((state) => state.getAllClassssReducer);
//   const {
//     classss: classes,
//     loading: classssLoading,
//     error: classssError,
//   } = classssState;

//   const studentsstate = useSelector((state) => state.getAllStudentsReducer);
//   const { students } = studentsstate;

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllSchoolcodes());
//     dispatch(getAllUsers()); // Fetch all users from the database
//     dispatch(getAllClassss()); // Fetch all classes
//     dispatch(getAllStudents()); // Fetch all students
//   }, [dispatch]);

//   useEffect(() => {
//     const allFieldsFilled =
//       firstname !== "" &&
//       lastname !== "" &&
//       //name !== "" &&
//       classs !== "" &&
//       section !== "" &&
//       city !== "" &&
//       mobile !== "" &&
//       email !== "" &&
//       password !== "" &&
//       cpassword !== "" &&
//       (registrationType === "schoolCode" ? schoolcode !== "" : true) &&
//       rollNumber !== "";
//     setIsAllFieldsFilled(allFieldsFilled);
//   }, [
//     firstname,
//     lastname,
//     //name,
//     schoolcode,
//     classs,
//     section,
//     city,
//     mobile,
//     email,
//     password,
//     cpassword,
//     registrationType,
//     rollNumber,
//   ]);

//   function register() {
//     if (password !== cpassword) {
//       alert("Passwords do not match");
//     } else if (users.some((user) => user.email === email)) {
//       alert("Email already registered. Register with another email account.");
//     } else {
//       if (registrationType === "schoolCode") {
//         const isValid = schoolcodes.some((code) => code.code === schoolcode);
//         setIsSchoolCodeValid(isValid);
//         if (!isValid) {
//           alert("School Code is Invalid");
//           return;
//         }
//       }
//       const matchedStudent = students.find(
//         (student) =>
//           student.firstname.toLowerCase() === firstname.toLowerCase() &&
//           student.classs == classs &&
//           student.rollnumber == rollNumber &&
//           student.email.toLowerCase() == email.toLowerCase()
//       );

//       if (registrationType === "schoolCode" && !matchedStudent) {
//         alert("You are not yet enrolled with us. Please contact school admin.");
//         return;
//       }

//       const user = {
//         firstname,
//         middlename,
//         lastname,
//         name: firstname + " " + middlename + " " + lastname,
//         mobile,
//         school,
//         schoolcode: registrationType === "schoolCode" ? schoolcode : "",
//         paid: registrationType === "schoolCode" ? true : false,
//         classs,
//         section,
//         city,
//         email,
//         password,
//         rollNumber,
//       };

//       console.log(user);
//       dispatch(registerUser(user));

//       // {
//       //   registrationType === "schoolCode"
//       //     ? dispatch(registerUser(user))
//       //     : (window.location.href = "/paymentplans");
//       // }
//     }
//   }

//   return (
//     <div>
//       <div className="row justify-content-center mt-5">
//         <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 bg-white rounded">
//           {loading && <Loading />}
//           {success && <Success success="User Registered Successfully" />}
//           {error && <Error error="Email already registered" />}

//           <h2 className="text-center m-2" style={{ fontSize: "35px" }}>
//             Register
//           </h2>
//           <div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="First Name"
//                 className="form-control"
//                 value={firstname}
//                 onChange={(e) => {
//                   setfirstname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Middle Name"
//                 className="form-control"
//                 value={middlename}
//                 onChange={(e) => {
//                   setmiddlename(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Last Name"
//                 className="form-control"
//                 value={lastname}
//                 onChange={(e) => {
//                   setlastname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             {/* <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Full Name"
//                 className="form-control"
//                 value={name}
//                 onChange={(e) => {
//                   setname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black" }}
//               />
//             </div> */}

//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={rollNumber}
//                 onChange={(e) => setRollNumber(e.target.value)}
//                 style={{ border: "1px solid black", marginBottom: "0px" }}
//               >
//                 <option value="">Select Roll Number</option>
//                 {Array.from({ length: 100 }, (_, i) => i + 1).map((num) => (
//                   <option key={num} value={num}>
//                     {num}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={classs}
//                 onChange={(e) => setclasss(e.target.value)}
//                 style={{ marginBottom: "0px" }}
//               >
//                 <option value="">Select Class</option>
//                 {classes &&
//                   classes.map((cls) => (
//                     <option key={cls._id} value={cls.cls}>
//                       {cls.cls}
//                     </option>
//                   ))}
//               </select>
//             </div>
//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={section}
//                 onChange={(e) => setsection(e.target.value)}
//                 style={{ marginBottom: "20px" }}
//               >
//                 <option value="">Select Class Section</option>
//                 <option key="A" value="A">
//                   A
//                 </option>
//                 <option key="B" value="B">
//                   B
//                 </option>
//                 <option key="C" value="C">
//                   C
//                 </option>
//                 <option key="D" value="D">
//                   D
//                 </option>
//                 <option key="E" value="E">
//                   E
//                 </option>
//                 <option key="F" value="F">
//                   F
//                 </option>
//                 <option key="G" value="G">
//                   G
//                 </option>
//                 <option key="H" value="H">
//                   H
//                 </option>
//               </select>
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="School Name"
//                 className="form-control"
//                 value={school}
//                 onChange={(e) => {
//                   setschool(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="City"
//                 className="form-control"
//                 value={city}
//                 onChange={(e) => {
//                   setcity(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Mobile"
//                 className="form-control"
//                 value={mobile}
//                 onChange={(e) => {
//                   setmobile(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Email"
//                 className="form-control"
//                 value={email}
//                 onChange={(e) => {
//                   setemail(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>

//             <input
//               required
//               type="password"
//               placeholder="Password"
//               className="form-control"
//               value={password}
//               onChange={(e) => {
//                 setpassword(e.target.value);
//               }}
//               style={{ border: "1px solid black", marginBottom: "10px" }}
//             />
//             <input
//               required
//               type="password"
//               placeholder="Confirm Password"
//               className="form-control mb-3"
//               value={cpassword}
//               onChange={(e) => {
//                 setcpassword(e.target.value);
//               }}
//               style={{ border: "1px solid black", marginBottom: "10px" }}
//             />

//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="schoolCode"
//                 value="schoolCode"
//                 checked={registrationType === "schoolCode"}
//                 onChange={() => setRegistrationType("schoolCode")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="schoolCode"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register with school code
//               </label>
//             </div>
//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="individual"
//                 value="individual"
//                 checked={registrationType === "individual"}
//                 onChange={() => setRegistrationType("individual")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="individual"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register as an individual without school code
//               </label>
//             </div>

//             {registrationType === "schoolCode" && (
//               <div>
//                 <input
//                   required
//                   type="text"
//                   placeholder="School Code"
//                   className="form-control"
//                   value={schoolcode}
//                   onChange={(e) => {
//                     setschoolcode(e.target.value);
//                   }}
//                   style={{ border: "1px solid black", marginBottom: "10px" }}
//                 />
//               </div>
//             )}

//             <button
//               onClick={register}
//               className="btn mt-3 mb-3"
//               disabled={!isAllFieldsFilled}
//             >
//               REGISTER
//             </button>
//             <br></br>
//             <a style={{ color: "black" }} href="/login">
//               Click Here To Login
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
//////////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { registerUser, getAllUsers } from "../actions/userActions";
// import { getAllSchoolcodes } from "../actions/schoolcodeActions";
// import { getAllClassss } from "../actions/classsActions";
// import { getAllStudents } from "../actions/studentActions"; // Import getAllStudents action
// import Error from "../components/Error";
// import Success from "../components/Success";
// import Loading from "../components/Loading";

// export default function Registerscreen() {
//   const [firstname, setfirstname] = useState("");
//   const [lastname, setlastname] = useState("");
//   const [middlename, setmiddlename] = useState("");
//   const [name, setname] = useState("");
//   const [school, setschool] = useState("");
//   const [schoolcode, setschoolcode] = useState("");
//   const [classs, setclasss] = useState("");
//   const [section, setsection] = useState("");
//   const [city, setcity] = useState("");
//   const [mobile, setmobile] = useState("");
//   const [email, setemail] = useState("");
//   const [paid, setpaid] = useState(false);
//   const [password, setpassword] = useState("");
//   const [cpassword, setcpassword] = useState("");
//   const [registrationType, setRegistrationType] = useState("schoolCode");
//   const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
//   const [isSchoolCodeValid, setIsSchoolCodeValid] = useState(true); // Initialize to true
//   const [rollNumber, setRollNumber] = useState("");

//   const users = useSelector((state) => state.getAllUsersReducer.users);
//   const registerstate = useSelector((state) => state.registerUserReducer);
//   const { error, loading, success } = registerstate;
//   const schoolcodes = useSelector(
//     (state) => state.getAllSchoolcodesReducer.schoolcodes
//   );
//   const classssState = useSelector((state) => state.getAllClassssReducer);
//   const {
//     classss: classes,
//     loading: classssLoading,
//     error: classssError,
//   } = classssState;

//   const studentsstate = useSelector((state) => state.getAllStudentsReducer);
//   const { students } = studentsstate;

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllSchoolcodes());
//     dispatch(getAllUsers()); // Fetch all users from the database
//     dispatch(getAllClassss()); // Fetch all classes
//     dispatch(getAllStudents()); // Fetch all students
//   }, [dispatch]);

//   useEffect(() => {
//     const allFieldsFilled =
//       firstname !== "" &&
//       lastname !== "" &&
//       //name !== "" &&
//       classs !== "" &&
//       section !== "" &&
//       city !== "" &&
//       mobile !== "" &&
//       email !== "" &&
//       password !== "" &&
//       cpassword !== "" &&
//       (registrationType === "schoolCode" ? schoolcode !== "" : true) &&
//       rollNumber !== "";
//     setIsAllFieldsFilled(allFieldsFilled);
//   }, [
//     firstname,
//     lastname,
//     //name,
//     schoolcode,
//     classs,
//     section,
//     city,
//     mobile,
//     email,
//     password,
//     cpassword,
//     registrationType,
//     rollNumber,
//   ]);

//   const handleMobileChange = (e) => {
//     const value = e.target.value;
//     if (value === "" || /^[0-9\b]+$/.test(value)) {
//       setmobile(value);
//     }
//   };

//   function register() {
//     if (password !== cpassword) {
//       alert("Passwords do not match");
//     } else if (users.some((user) => user.email === email)) {
//       alert("Email already registered. Register with another email account.");
//     } else {
//       if (registrationType === "schoolCode") {
//         const isValid = schoolcodes.some((code) => code.code === schoolcode);
//         setIsSchoolCodeValid(isValid);
//         if (!isValid) {
//           alert("School Code is Invalid");
//           return;
//         }
//       }
//       const matchedStudent = students.find(
//         (student) =>
//           student.firstname.toLowerCase() === firstname.toLowerCase() &&
//           student.classs == classs &&
//           student.rollnumber == rollNumber &&
//           student.email.toLowerCase() == email.toLowerCase()
//       );

//       if (registrationType === "schoolCode" && !matchedStudent) {
//         alert("You are not yet enrolled with us. Please contact school admin.");
//         return;
//       }

//       const user = {
//         firstname,
//         middlename,
//         lastname,
//         name: firstname + " " + middlename + " " + lastname,
//         mobile,
//         school,
//         schoolcode: registrationType === "schoolCode" ? schoolcode : "",
//         paid: registrationType === "schoolCode" ? true : false,
//         classs,
//         section,
//         city,
//         email,
//         password,
//         rollNumber,
//       };

//       console.log(user);
//       dispatch(registerUser(user));

//       // {
//       //   registrationType === "schoolCode"
//       //     ? dispatch(registerUser(user))
//       //     : (window.location.href = "/paymentplans");
//       // }
//     }
//   }

//   return (
//     <div>
//       <div className="row justify-content-center mt-5">
//         <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 bg-white rounded">
//           {loading && <Loading />}
//           {success && <Success success="User Registered Successfully" />}
//           {error && <Error error="Email already registered" />}

//           <h2 className="text-center m-2" style={{ fontSize: "35px" }}>
//             Register
//           </h2>
//           <div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="First Name"
//                 className="form-control"
//                 value={firstname}
//                 onChange={(e) => {
//                   setfirstname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Middle Name"
//                 className="form-control"
//                 value={middlename}
//                 onChange={(e) => {
//                   setmiddlename(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Last Name"
//                 className="form-control"
//                 value={lastname}
//                 onChange={(e) => {
//                   setlastname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             {/* <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Full Name"
//                 className="form-control"
//                 value={name}
//                 onChange={(e) => {
//                   setname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black" }}
//               />
//             </div> */}

//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={rollNumber}
//                 onChange={(e) => setRollNumber(e.target.value)}
//                 style={{ border: "1px solid black", marginBottom: "0px" }}
//               >
//                 <option value="">Select Roll Number</option>
//                 {Array.from({ length: 100 }, (_, i) => i + 1).map((num) => (
//                   <option key={num} value={num}>
//                     {num}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={classs}
//                 onChange={(e) => setclasss(e.target.value)}
//                 style={{ marginBottom: "0px" }}
//               >
//                 <option value="">Select Class</option>
//                 {classes &&
//                   classes.map((cls) => (
//                     <option key={cls._id} value={cls.cls}>
//                       {cls.cls}
//                     </option>
//                   ))}
//               </select>
//             </div>
//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={section}
//                 onChange={(e) => setsection(e.target.value)}
//                 style={{ marginBottom: "20px" }}
//               >
//                 <option value="">Select Class Section</option>
//                 <option key="A" value="A">
//                   A
//                 </option>
//                 <option key="B" value="B">
//                   B
//                 </option>
//                 <option key="C" value="C">
//                   C
//                 </option>
//                 <option key="D" value="D">
//                   D
//                 </option>
//                 <option key="E" value="E">
//                   E
//                 </option>
//                 <option key="F" value="F">
//                   F
//                 </option>
//                 <option key="G" value="G">
//                   G
//                 </option>
//                 <option key="H" value="H">
//                   H
//                 </option>
//               </select>
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="School Name"
//                 className="form-control"
//                 value={school}
//                 onChange={(e) => {
//                   setschool(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="City"
//                 className="form-control"
//                 value={city}
//                 onChange={(e) => {
//                   setcity(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="10-digit Mobile Number"
//                 className="form-control"
//                 value={mobile}
//                 onChange={handleMobileChange}
//                 maxLength="10"
//                 pattern="\d{10}"
//                 title="Please enter a valid 10-digit mobile number"
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Email"
//                 className="form-control"
//                 value={email}
//                 onChange={(e) => {
//                   setemail(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>

//             <input
//               required
//               type="password"
//               placeholder="Password"
//               className="form-control"
//               value={password}
//               onChange={(e) => {
//                 setpassword(e.target.value);
//               }}
//               style={{ border: "1px solid black", marginBottom: "10px" }}
//             />
//             <input
//               required
//               type="password"
//               placeholder="Confirm Password"
//               className="form-control mb-3"
//               value={cpassword}
//               onChange={(e) => {
//                 setcpassword(e.target.value);
//               }}
//               style={{ border: "1px solid black", marginBottom: "10px" }}
//             />

//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="schoolCode"
//                 value="schoolCode"
//                 checked={registrationType === "schoolCode"}
//                 onChange={() => setRegistrationType("schoolCode")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="schoolCode"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register with school code
//               </label>
//             </div>
//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="individual"
//                 value="individual"
//                 checked={registrationType === "individual"}
//                 onChange={() => setRegistrationType("individual")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="individual"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register as an individual without school code
//               </label>
//             </div>

//             {registrationType === "schoolCode" && (
//               <div>
//                 <input
//                   required
//                   type="text"
//                   placeholder="School Code"
//                   className="form-control"
//                   value={schoolcode}
//                   onChange={(e) => {
//                     setschoolcode(e.target.value);
//                   }}
//                   style={{ border: "1px solid black", marginBottom: "10px" }}
//                 />
//               </div>
//             )}

//             <button
//               onClick={register}
//               className="btn mt-3 mb-3"
//               disabled={!isAllFieldsFilled}
//             >
//               REGISTER
//             </button>
//             <br></br>
//             <a style={{ color: "black" }} href="/login">
//               Click Here To Login
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
/////////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { registerUser, getAllUsers } from "../actions/userActions";
// import { getAllSchoolcodes } from "../actions/schoolcodeActions";
// import { getAllClassss } from "../actions/classsActions";
// import { getAllStudents } from "../actions/studentActions"; // Import getAllStudents action
// import Error from "../components/Error";
// import Success from "../components/Success";
// import Loading from "../components/Loading";

// export default function Registerscreen() {
//   const [firstname, setfirstname] = useState("");
//   const [lastname, setlastname] = useState("");
//   const [middlename, setmiddlename] = useState("");
//   const [name, setname] = useState("");
//   const [school, setschool] = useState("");
//   const [schoolcode, setschoolcode] = useState("");
//   const [classs, setclasss] = useState("");
//   const [section, setsection] = useState("");
//   const [city, setcity] = useState("");
//   const [mobile, setmobile] = useState("");
//   const [email, setemail] = useState("");
//   const [paid, setpaid] = useState(false);
//   const [password, setpassword] = useState("");
//   const [cpassword, setcpassword] = useState("");
//   const [registrationType, setRegistrationType] = useState("schoolCode");
//   const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
//   const [isSchoolCodeValid, setIsSchoolCodeValid] = useState(true); // Initialize to true
//   const [rollNumber, setRollNumber] = useState("");

//   const users = useSelector((state) => state.getAllUsersReducer.users);
//   const registerstate = useSelector((state) => state.registerUserReducer);
//   const { error, loading, success } = registerstate;
//   const schoolcodes = useSelector(
//     (state) => state.getAllSchoolcodesReducer.schoolcodes
//   );
//   const classssState = useSelector((state) => state.getAllClassssReducer);
//   const {
//     classss: classes,
//     loading: classssLoading,
//     error: classssError,
//   } = classssState;

//   const studentsstate = useSelector((state) => state.getAllStudentsReducer);
//   const { students } = studentsstate;

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllSchoolcodes());
//     dispatch(getAllUsers()); // Fetch all users from the database
//     dispatch(getAllClassss()); // Fetch all classes
//     dispatch(getAllStudents()); // Fetch all students
//   }, [dispatch]);

//   useEffect(() => {
//     const allFieldsFilled =
//       firstname !== "" &&
//       lastname !== "" &&
//       //name !== "" &&
//       classs !== "" &&
//       section !== "" &&
//       city !== "" &&
//       mobile !== "" &&
//       email !== "" &&
//       password !== "" &&
//       cpassword !== "" &&
//       (registrationType === "schoolCode" ? schoolcode !== "" : true) &&
//       rollNumber !== "";
//     setIsAllFieldsFilled(allFieldsFilled);
//   }, [
//     firstname,
//     lastname,
//     //name,
//     schoolcode,
//     classs,
//     section,
//     city,
//     mobile,
//     email,
//     password,
//     cpassword,
//     registrationType,
//     rollNumber,
//   ]);

//   const handleMobileChange = (e) => {
//     const value = e.target.value;
//     if (value === "" || /^[0-9\b]+$/.test(value)) {
//       setmobile(value);
//     }
//   };

//   const handleTextChange = (setter) => (e) => {
//     const value = e.target.value;
//     if (/^[a-zA-Z\s]*$/.test(value)) {
//       setter(value);
//     }
//   };

//   function register() {
//     if (password !== cpassword) {
//       alert("Passwords do not match");
//     } else if (users.some((user) => user.email === email)) {
//       alert("Email already registered. Register with another email account.");
//     } else {
//       if (registrationType === "schoolCode") {
//         const isValid = schoolcodes.some((code) => code.code === schoolcode);
//         setIsSchoolCodeValid(isValid);
//         if (!isValid) {
//           alert("School Code is Invalid");
//           return;
//         }
//       }
//       const matchedStudent = students.find(
//         (student) =>
//           student.firstname.toLowerCase() === firstname.toLowerCase() &&
//           student.classs == classs &&
//           student.rollnumber == rollNumber &&
//           student.email.toLowerCase() == email.toLowerCase()
//       );

//       if (registrationType === "schoolCode" && !matchedStudent) {
//         alert(
//           "You are not yet enrolled with us. Please contact school admin or register without the school code."
//         );
//         return;
//       }

//       if (mobile.length < 10) {
//         alert("Entered mobile number is less than 10-digit.");
//         return;
//       }

//       const user = {
//         firstname,
//         middlename,
//         lastname,
//         name: firstname + " " + middlename + " " + lastname,
//         key: firstname + classs + rollNumber,
//         mobile,
//         school,
//         schoolcode: registrationType === "schoolCode" ? schoolcode : "",
//         paid: registrationType === "schoolCode" ? true : false,
//         classs,
//         rollNumber,
//         section,
//         city,
//         email,
//         password,
//         rollNumber,
//       };

//       console.log(user);
//       dispatch(registerUser(user));

//       // {
//       //   registrationType === "schoolCode"
//       //     ? dispatch(registerUser(user))
//       //     : (window.location.href = "/paymentplans");
//       // }
//     }
//   }

//   return (
//     <div>
//       <div className="row justify-content-center mt-5">
//         <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 bg-white rounded">
//           {loading && <Loading />}
//           {success && <Success success="User Registered Successfully" />}
//           {error && <Error error="Email already registered" />}

//           <h2 className="text-center m-2" style={{ fontSize: "35px" }}>
//             Register
//           </h2>
//           <div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="First Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//                 className="form-control"
//                 value={firstname}
//                 onChange={handleTextChange(setfirstname)}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Middle Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//                 className="form-control"
//                 value={middlename}
//                 onChange={handleTextChange(setmiddlename)}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Last Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//                 className="form-control"
//                 value={lastname}
//                 onChange={handleTextChange(setlastname)}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             {/* <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Full Name"
//                 className="form-control"
//                 value={name}
//                 onChange={(e) => {
//                   setname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black" }}
//               />
//             </div> */}

//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={rollNumber}
//                 onChange={(e) => setRollNumber(e.target.value)}
//                 style={{ border: "1px solid black", marginBottom: "0px" }}
//               >
//                 <option value="">Select Roll Number</option>
//                 {Array.from({ length: 100 }, (_, i) => i + 1).map((num) => (
//                   <option key={num} value={num}>
//                     {num}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={classs}
//                 onChange={(e) => setclasss(e.target.value)}
//                 style={{ marginBottom: "0px" }}
//               >
//                 <option value="">Select Class</option>
//                 {classes &&
//                   classes.map((cls) => (
//                     <option key={cls._id} value={cls.cls}>
//                       {cls.cls}
//                     </option>
//                   ))}
//               </select>
//             </div>
//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={section}
//                 onChange={(e) => setsection(e.target.value)}
//                 style={{ marginBottom: "20px" }}
//               >
//                 <option value="">Select Class Section</option>
//                 <option key="A" value="A">
//                   A
//                 </option>
//                 <option key="B" value="B">
//                   B
//                 </option>
//                 <option key="C" value="C">
//                   C
//                 </option>
//                 <option key="D" value="D">
//                   D
//                 </option>
//                 <option key="E" value="E">
//                   E
//                 </option>
//                 <option key="F" value="F">
//                   F
//                 </option>
//                 <option key="G" value="G">
//                   G
//                 </option>
//                 <option key="H" value="H">
//                   H
//                 </option>
//               </select>
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="School Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//                 className="form-control"
//                 value={school}
//                 onChange={handleTextChange(setschool)}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="City     [ only alphabets ( A-Z , a-z ) are allowed ]"
//                 className="form-control"
//                 value={city}
//                 onChange={handleTextChange(setcity)}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="10-digit Mobile Number"
//                 className="form-control"
//                 value={mobile}
//                 onChange={handleMobileChange}
//                 maxLength="10"
//                 pattern="\d{10}"
//                 title="Please enter a valid 10-digit mobile number"
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Email"
//                 className="form-control"
//                 value={email}
//                 onChange={(e) => {
//                   setemail(e.target.value);
//                 }}
//                 style={{ border: "1px solid black", marginBottom: "10px" }}
//               />
//             </div>

//             <input
//               required
//               type="password"
//               placeholder="Password"
//               className="form-control"
//               value={password}
//               onChange={(e) => {
//                 setpassword(e.target.value);
//               }}
//               style={{ border: "1px solid black", marginBottom: "10px" }}
//             />
//             <input
//               required
//               type="password"
//               placeholder="Confirm Password"
//               className="form-control mb-3"
//               value={cpassword}
//               onChange={(e) => {
//                 setcpassword(e.target.value);
//               }}
//               style={{ border: "1px solid black", marginBottom: "10px" }}
//             />

//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="schoolCode"
//                 value="schoolCode"
//                 checked={registrationType === "schoolCode"}
//                 onChange={() => setRegistrationType("schoolCode")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="schoolCode"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register with school code
//               </label>
//             </div>
//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="individual"
//                 value="individual"
//                 checked={registrationType === "individual"}
//                 onChange={() => setRegistrationType("individual")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="individual"
//                 style={{ marginLeft: "5px" }}
//               >
//                 Register as an individual without school code
//               </label>
//             </div>

//             {registrationType === "schoolCode" && (
//               <div>
//                 <input
//                   required
//                   type="text"
//                   placeholder="School Code"
//                   className="form-control"
//                   value={schoolcode}
//                   onChange={(e) => {
//                     setschoolcode(e.target.value);
//                   }}
//                   style={{ border: "1px solid black", marginBottom: "10px" }}
//                 />
//               </div>
//             )}

//             <button
//               onClick={register}
//               className="btn mt-3 mb-3"
//               disabled={!isAllFieldsFilled}
//             >
//               REGISTER
//             </button>
//             <br></br>
//             <a style={{ color: "black" }} href="/login">
//               Click Here To Login
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { registerUser, getAllUsers } from "../actions/userActions";
// import { getAllSchoolcodes } from "../actions/schoolcodeActions";
// import { getAllClassss } from "../actions/classsActions";
// import { getAllRollnumbers } from "../actions/rollnumberActions";
// import { getAllStudents } from "../actions/studentActions"; // Import getAllStudents action
// import Error from "../components/Error";
// import Success from "../components/Success";
// import Loading from "../components/Loading";

// export default function Registerscreen() {
//   const [firstname, setfirstname] = useState("");
//   const [lastname, setlastname] = useState("");
//   const [middlename, setmiddlename] = useState("");
//   const [name, setname] = useState("");
//   const [school, setschool] = useState("");
//   const [schoolcode, setschoolcode] = useState("");
//   const [classs, setclasss] = useState("");
//   const [section, setsection] = useState("");
//   const [city, setcity] = useState("");
//   const [mobile, setmobile] = useState("");
//   const [email, setemail] = useState("");
//   const [paid, setpaid] = useState(false);
//   const [password, setpassword] = useState("");
//   const [cpassword, setcpassword] = useState("");
//   const [registrationType, setRegistrationType] = useState("schoolCode");
//   const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
//   const [isSchoolCodeValid, setIsSchoolCodeValid] = useState(true); // Initialize to true
//   const [rollNumber, setRollNumber] = useState("");

//   const users = useSelector((state) => state.getAllUsersReducer.users);
//   const registerstate = useSelector((state) => state.registerUserReducer);
//   const { error, loading, success } = registerstate;
//   const schoolcodes = useSelector(
//     (state) => state.getAllSchoolcodesReducer.schoolcodes
//   );

//   const classssState = useSelector((state) => state.getAllClassssReducer);
//   const {
//     classss: classes,
//     loading: classssLoading,
//     error: classssError,
//   } = classssState;

//   const rollnumbersState = useSelector(
//     (state) => state.getAllRollnumbersReducer
//   );
//   const {
//     rollnumbers: rollnumbers,
//     loading: rollnumbersLoading,
//     error: rollnumbersError,
//   } = rollnumbersState;

//   const studentsstate = useSelector((state) => state.getAllStudentsReducer);
//   const { students } = studentsstate;

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllSchoolcodes());
//     dispatch(getAllUsers()); // Fetch all users from the database
//     dispatch(getAllClassss()); // Fetch all classes
//     dispatch(getAllRollnumbers());
//     dispatch(getAllStudents()); // Fetch all students
//   }, [dispatch]);

//   useEffect(() => {
//     const allFieldsFilled =
//       firstname !== "" &&
//       lastname !== "" &&
//       //name !== "" &&
//       classs !== "" &&
//       section !== "" &&
//       city !== "" &&
//       mobile !== "" &&
//       email !== "" &&
//       password !== "" &&
//       cpassword !== "" &&
//       (registrationType === "schoolCode" ? schoolcode !== "" : true) &&
//       (registrationType === "schoolCode" ? rollNumber !== "" : true);
//     setIsAllFieldsFilled(allFieldsFilled);
//   }, [
//     firstname,
//     lastname,
//     //name,
//     schoolcode,
//     classs,
//     section,
//     city,
//     mobile,
//     email,
//     password,
//     cpassword,
//     registrationType,
//     rollNumber,
//   ]);

//   const handleMobileChange = (e) => {
//     const value = e.target.value;
//     if (value === "" || /^[0-9\b]+$/.test(value)) {
//       setmobile(value);
//     }
//   };

//   const handleTextChange = (setter) => (e) => {
//     const value = e.target.value;
//     if (/^[a-zA-Z\s]*$/.test(value)) {
//       setter(value);
//     }
//   };

//   function register() {
//     if (password !== cpassword) {
//       alert("Passwords do not match");
//     } else if (users.some((user) => user.email === email)) {
//       alert("Email already registered. Register with another email account.");
//     } else {
//       if (registrationType === "schoolCode") {
//         const isValid = schoolcodes.some(
//           (code) => code.code === schoolcode.trim()
//         );
//         setIsSchoolCodeValid(isValid);
//         if (!isValid) {
//           alert("School Code is Invalid");
//           return;
//         }
//       }
//       const matchedStudent = students.find(
//         (student) =>
//           student.firstname &&
//           student.classs &&
//           student.rollnumber &&
//           student.firstname.trim().toLowerCase() ===
//             firstname.trim().toLowerCase() &&
//           student.classs == classs &&
//           student.rollnumber.trim() == rollNumber.trim()
//         //&& student.email.toLowerCase() == email.toLowerCase()
//       );

//       if (registrationType === "schoolCode" && !matchedStudent) {
//         alert(
//           "You are not yet enrolled with us. Please contact school admin or register without the school code."
//         );
//         return;
//       }

//       if (mobile.length < 10) {
//         alert("Entered mobile number is less than 10-digit.");
//         return;
//       }

//       const user = {
//         firstname: firstname.trim(),
//         middlename: middlename.trim(),
//         lastname: lastname.trim(),
//         name: `${firstname.trim()} ${middlename.trim()} ${lastname.trim()}`,
//         key: `${firstname.trim()}${classs.trim()}${rollNumber.trim()}`,
//         mobile: mobile.trim(),
//         school: school.trim(),
//         schoolcode:
//           registrationType === "schoolCode" ? schoolcode.trim() : "Empty",
//         paid: registrationType === "schoolCode" ? true : false,
//         classs: classs.trim(),
//         rollNumber: registrationType === "schoolCode" ? rollNumber.trim() : "",
//         section: section.trim(),
//         city: city.trim(),
//         email: email.trim(),
//         password: password.trim(),
//       };

//       console.log(user);
//       dispatch(registerUser(user));

//       // {
//       //   registrationType === "schoolCode"
//       //     ? dispatch(registerUser(user))
//       //     : (window.location.href = "/paymentplans");
//       // }
//     }
//   }

//   return (
//     <div>
//       <div className="row justify-content-center mt-5">
//         <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 bg-white rounded-5">
//           {loading && <Loading />}
//           {success && <Success success="User Registered Successfully" />}
//           {/* {error && <Error error="Email already registered" />} */}

//           <h2
//             className="text-center m-2"
//             style={{
//               fontSize: "35px",
//               fontFamily: '"Libre Baskerville", serif',
//               fontWeight: "bold",
//             }}
//           >
//             Register
//           </h2>
//           <div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="First Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//                 className="form-control"
//                 value={firstname}
//                 onChange={handleTextChange(setfirstname)}
//                 style={{
//                   border: "1px solid black",
//                   marginBottom: "10px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Middle Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//                 className="form-control"
//                 value={middlename}
//                 onChange={handleTextChange(setmiddlename)}
//                 style={{
//                   border: "1px solid black",
//                   marginBottom: "10px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Last Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//                 className="form-control"
//                 value={lastname}
//                 onChange={handleTextChange(setlastname)}
//                 style={{
//                   border: "1px solid black",
//                   marginBottom: "20px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               />
//             </div>
//             {/* <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Full Name"
//                 className="form-control"
//                 value={name}
//                 onChange={(e) => {
//                   setname(e.target.value);
//                 }}
//                 style={{ border: "1px solid black" }}
//               />
//             </div> */}
//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="schoolCode"
//                 value="schoolCode"
//                 checked={registrationType === "schoolCode"}
//                 onChange={() => setRegistrationType("schoolCode")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="schoolCode"
//                 style={{
//                   marginLeft: "5px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               >
//                 Register with school code
//               </label>
//             </div>
//             <div className="form-check mb-3">
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 name="registrationType"
//                 id="individual"
//                 value="individual"
//                 checked={registrationType === "individual"}
//                 onChange={() => setRegistrationType("individual")}
//                 style={{
//                   border: "1px solid #ced4da",
//                   boxShadow: "none",
//                   marginRight: "5px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor="individual"
//                 style={{
//                   marginLeft: "5px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               >
//                 Register as an individual without school code
//               </label>
//             </div>
//             {registrationType === "schoolCode" && (
//               <div>
//                 <input
//                   required
//                   type="text"
//                   placeholder="School Code"
//                   className="form-control"
//                   value={schoolcode}
//                   onChange={(e) => {
//                     setschoolcode(e.target.value);
//                   }}
//                   style={{
//                     border: "1px solid black",
//                     marginBottom: "0px",
//                     fontFamily: '"Libre Baskerville", serif',
//                     fontWeight: "bold",
//                   }}
//                 />
//               </div>
//             )}

//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={classs}
//                 onChange={(e) => setclasss(e.target.value)}
//                 style={{
//                   marginBottom: "0px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               >
//                 <option value="">Select Class</option>
//                 {classes &&
//                   classes.map((cls) => (
//                     <option key={cls._id} value={cls.cls}>
//                       {cls.cls}
//                     </option>
//                   ))}
//               </select>
//             </div>
//             <div className="form-group">
//               <label></label>
//               <select
//                 className="form-control"
//                 value={section}
//                 onChange={(e) => setsection(e.target.value)}
//                 style={{
//                   marginBottom: "0px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               >
//                 <option value="">Select Class Section</option>
//                 <option key="Section Unavailable" value="Section Unavailable">
//                   Section Unavailable
//                 </option>
//                 <option key="A" value="A">
//                   A
//                 </option>
//                 <option key="B" value="B">
//                   B
//                 </option>
//                 <option key="C" value="C">
//                   C
//                 </option>
//                 <option key="D" value="D">
//                   D
//                 </option>
//                 <option key="E" value="E">
//                   E
//                 </option>
//                 <option key="F" value="F">
//                   F
//                 </option>
//                 <option key="G" value="G">
//                   G
//                 </option>
//                 <option key="H" value="H">
//                   H
//                 </option>
//               </select>
//             </div>

//             {registrationType === "schoolCode" && (
//               <div className="form-group">
//                 <input
//                   className="form-control"
//                   placeholder="Roll Number"
//                   value={rollNumber}
//                   onChange={(e) => setRollNumber(e.target.value)}
//                   style={{
//                     border: "1px solid black",
//                     marginBottom: "10px",
//                     fontFamily: '"Libre Baskerville", serif',
//                     fontWeight: "bold",
//                   }}
//                 />
//               </div>
//             )}

//             {registrationType === "schoolCode" && (
//               <div>
//                 <input
//                   required
//                   type="text"
//                   placeholder="School Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//                   className="form-control"
//                   value={school}
//                   onChange={handleTextChange(setschool)}
//                   style={{
//                     border: "1px solid black",
//                     marginBottom: "10px",
//                     fontFamily: '"Libre Baskerville", serif',
//                     fontWeight: "bold",
//                   }}
//                 />
//               </div>
//             )}

//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="City     [ only alphabets ( A-Z , a-z ) are allowed ]"
//                 className="form-control"
//                 value={city}
//                 onChange={handleTextChange(setcity)}
//                 style={{
//                   border: "1px solid black",
//                   marginBottom: "10px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="10-digit Mobile Number"
//                 className="form-control"
//                 value={mobile}
//                 onChange={handleMobileChange}
//                 maxLength="10"
//                 pattern="\d{10}"
//                 title="Please enter a valid 10-digit mobile number"
//                 style={{
//                   border: "1px solid black",
//                   marginBottom: "10px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               />
//             </div>
//             <div>
//               <input
//                 required
//                 type="text"
//                 placeholder="Email"
//                 className="form-control"
//                 value={email}
//                 onChange={(e) => {
//                   setemail(e.target.value);
//                 }}
//                 style={{
//                   border: "1px solid black",
//                   marginBottom: "10px",
//                   fontFamily: '"Libre Baskerville", serif',
//                   fontWeight: "bold",
//                 }}
//               />
//             </div>
//             <input
//               required
//               type="password"
//               placeholder="Password"
//               className="form-control"
//               value={password}
//               onChange={(e) => {
//                 setpassword(e.target.value);
//               }}
//               style={{
//                 border: "1px solid black",
//                 marginBottom: "10px",
//                 fontFamily: '"Libre Baskerville", serif',
//                 fontWeight: "bold",
//               }}
//             />
//             <input
//               required
//               type="password"
//               placeholder="Confirm Password"
//               className="form-control mb-3"
//               value={cpassword}
//               onChange={(e) => {
//                 setcpassword(e.target.value);
//               }}
//               style={{
//                 border: "1px solid black",
//                 marginBottom: "10px",
//                 fontFamily: '"Libre Baskerville", serif',
//                 fontWeight: "bold",
//               }}
//             />

//             <button
//               onClick={register}
//               className="btn mt-3 mb-3"
//               disabled={!isAllFieldsFilled}
//               style={{
//                 fontFamily: '"Libre Baskerville", serif',
//                 fontWeight: "bold",
//               }}
//             >
//               REGISTER
//             </button>
//             <br></br>
//             <a
//               style={{
//                 color: "black",
//                 fontFamily: '"Libre Baskerville", serif',
//                 fontWeight: "bold",
//               }}
//               href="/login"
//             >
//               Click Here To Login
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
/////////////////////////////////////////////////////////////////

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../actions/userActions";
import { getAllSchoolcodes } from "../actions/schoolcodeActions";
import { getAllClassss } from "../actions/classsActions";
import { getAllRollnumbers } from "../actions/rollnumberActions";
import { getAllStudents } from "../actions/studentActions"; // Import getAllStudents action
import Error from "../components/Error";
import Success from "../components/Success";
import Loading from "../components/Loading";
import axios from "axios"; // Import axios to make API requests

export default function Registerscreen() {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [middlename, setmiddlename] = useState("");
  const [name, setname] = useState("");
  const [school, setschool] = useState("");
  const [schoolcode, setschoolcode] = useState("");
  const [classs, setclasss] = useState("");
  const [section, setsection] = useState("");
  const [city, setcity] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [paid, setpaid] = useState(false);
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [registrationType, setRegistrationType] = useState("schoolCode");
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
  const [isSchoolCodeValid, setIsSchoolCodeValid] = useState(true); // Initialize to true
  const [rollNumber, setRollNumber] = useState("");

  const registerstate = useSelector((state) => state.registerUserReducer);
  const { error, loading, success } = registerstate;
  const schoolcodes = useSelector(
    (state) => state.getAllSchoolcodesReducer.schoolcodes
  );

  const classssState = useSelector((state) => state.getAllClassssReducer);
  const {
    classss: classes,
    loading: classssLoading,
    error: classssError,
  } = classssState;

  const rollnumbersState = useSelector(
    (state) => state.getAllRollnumbersReducer
  );
  const {
    rollnumbers: rollnumbers,
    loading: rollnumbersLoading,
    error: rollnumbersError,
  } = rollnumbersState;

  const studentsstate = useSelector((state) => state.getAllStudentsReducer);
  const { students } = studentsstate;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSchoolcodes());
    dispatch(getAllClassss()); // Fetch all classes
    dispatch(getAllRollnumbers());
    dispatch(getAllStudents()); // Fetch all students
  }, [dispatch]);

  useEffect(() => {
    const allFieldsFilled =
      firstname !== "" &&
      lastname !== "" &&
      classs !== "" &&
      section !== "" &&
      city !== "" &&
      mobile !== "" &&
      email !== "" &&
      password !== "" &&
      cpassword !== "" &&
      (registrationType === "schoolCode" ? schoolcode !== "" : true) &&
      (registrationType === "schoolCode" ? rollNumber !== "" : true);
    setIsAllFieldsFilled(allFieldsFilled);
  }, [
    firstname,
    lastname,
    schoolcode,
    classs,
    section,
    city,
    mobile,
    email,
    password,
    cpassword,
    registrationType,
    rollNumber,
  ]);

  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setmobile(value);
    }
  };

  const handleTextChange = (setter) => (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setter(value);
    }
  };

  async function register() {
    if (password !== cpassword) {
      alert("Passwords do not match");
    } else {
      try {
        // Check if email already exists
        const response = await axios.post("/api/users/checkemail", { email });
        if (response.data.exists) {
          alert(
            "Email already registered. Register with another email account."
          );
          return;
        }

        if (registrationType === "schoolCode") {
          const isValid = schoolcodes.some(
            (code) => code.code === schoolcode.trim()
          );
          setIsSchoolCodeValid(isValid);
          if (!isValid) {
            alert("School Code is Invalid");
            return;
          }
        }

        const matchedStudent = students.find(
          (student) =>
            student.firstname &&
            student.classs &&
            student.rollnumber &&
            student.firstname.trim().toLowerCase() ===
              firstname.trim().toLowerCase() &&
            student.classs == classs &&
            student.rollnumber.trim() == rollNumber.trim()
        );

        if (registrationType === "schoolCode" && !matchedStudent) {
          alert(
            "You are not yet enrolled with us. Please contact school admin or register without the school code."
          );
          return;
        }

        if (mobile.length < 10) {
          alert("Entered mobile number is less than 10-digit.");
          return;
        }

        const user = {
          firstname: firstname.trim(),
          middlename: middlename.trim(),
          lastname: lastname.trim(),
          name: `${firstname.trim()} ${middlename.trim()} ${lastname.trim()}`,
          key: `${firstname.trim()}${classs.trim()}${rollNumber.trim()}`,
          mobile: mobile.trim(),
          school: school.trim(),
          schoolcode:
            registrationType === "schoolCode" ? schoolcode.trim() : "Empty",
          paid: registrationType === "schoolCode" ? true : false,
          classs: classs.trim(),
          rollNumber:
            registrationType === "schoolCode" ? rollNumber.trim() : "",
          section: section.trim(),
          city: city.trim(),
          email: email.trim(),
          password: password.trim(),
        };

        console.log(user);
        // Send the new user to the backend to register
        dispatch(registerUser(user));
      } catch (error) {
        console.error("Error during registration", error);
        alert("An error occurred during registration. Please try again.");
      }
    }
  }

  return (
    <div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 bg-white rounded-5">
          {loading && <Loading />}
          {success && <Success success="User Registered Successfully" />}
          {/* {error && <Error error="Email already registered" />} */}

          <h2
            className="text-center m-2"
            style={{
              fontSize: "35px",
              fontFamily: '"Libre Baskerville", serif',
              fontWeight: "bold",
            }}
          >
            Register
          </h2>
          <div>
            <div>
              <input
                required
                type="text"
                placeholder="First Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
                className="form-control"
                value={firstname}
                onChange={handleTextChange(setfirstname)}
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <div>
              <input
                required
                type="text"
                placeholder="Middle Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
                className="form-control"
                value={middlename}
                onChange={handleTextChange(setmiddlename)}
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <div>
              <input
                required
                type="text"
                placeholder="Last Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
                className="form-control"
                value={lastname}
                onChange={handleTextChange(setlastname)}
                style={{
                  border: "1px solid black",
                  marginBottom: "20px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="registrationType"
                id="schoolCode"
                value="schoolCode"
                checked={registrationType === "schoolCode"}
                onChange={() => setRegistrationType("schoolCode")}
                style={{
                  border: "1px solid #ced4da",
                  boxShadow: "none",
                  marginRight: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
              <label
                className="form-check-label"
                htmlFor="schoolCode"
                style={{
                  marginLeft: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              >
                Register with school code
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="registrationType"
                id="individual"
                value="individual"
                checked={registrationType === "individual"}
                onChange={() => setRegistrationType("individual")}
                style={{
                  border: "1px solid #ced4da",
                  boxShadow: "none",
                  marginRight: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
              <label
                className="form-check-label"
                htmlFor="individual"
                style={{
                  marginLeft: "5px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              >
                Register as an individual without school code
              </label>
            </div>
            {registrationType === "schoolCode" && (
              <div>
                <input
                  required
                  type="text"
                  placeholder="School Code"
                  className="form-control"
                  value={schoolcode}
                  onChange={(e) => {
                    setschoolcode(e.target.value);
                  }}
                  style={{
                    border: "1px solid black",
                    marginBottom: "0px",
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                />
              </div>
            )}

            <div className="form-group">
              <label></label>
              <select
                className="form-control"
                value={classs}
                onChange={(e) => setclasss(e.target.value)}
                style={{
                  marginBottom: "0px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              >
                <option value="">Select Class</option>
                {classes &&
                  classes.map((cls) => (
                    <option key={cls._id} value={cls.cls}>
                      {cls.cls}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group">
              <label></label>
              <select
                className="form-control"
                value={section}
                onChange={(e) => setsection(e.target.value)}
                style={{
                  marginBottom: "0px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              >
                <option value="">Select Class Section</option>
                <option key="Section Unavailable" value="Section Unavailable">
                  Section Unavailable
                </option>
                <option key="A" value="A">
                  A
                </option>
                <option key="B" value="B">
                  B
                </option>
                <option key="C" value="C">
                  C
                </option>
                <option key="D" value="D">
                  D
                </option>
                <option key="E" value="E">
                  E
                </option>
                <option key="F" value="F">
                  F
                </option>
                <option key="G" value="G">
                  G
                </option>
                <option key="H" value="H">
                  H
                </option>
              </select>
            </div>

            {registrationType === "schoolCode" && (
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Roll Number"
                  value={rollNumber}
                  onChange={(e) => setRollNumber(e.target.value)}
                  style={{
                    border: "1px solid black",
                    marginBottom: "10px",
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                />
              </div>
            )}

            {registrationType === "schoolCode" && (
              <div>
                <input
                  required
                  type="text"
                  placeholder="School Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
                  className="form-control"
                  value={school}
                  onChange={handleTextChange(setschool)}
                  style={{
                    border: "1px solid black",
                    marginBottom: "10px",
                    fontFamily: '"Libre Baskerville", serif',
                    fontWeight: "bold",
                  }}
                />
              </div>
            )}

            <div>
              <input
                required
                type="text"
                placeholder="City     [ only alphabets ( A-Z , a-z ) are allowed ]"
                className="form-control"
                value={city}
                onChange={handleTextChange(setcity)}
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <div>
              <input
                required
                type="text"
                placeholder="10-digit Mobile Number"
                className="form-control"
                value={mobile}
                onChange={handleMobileChange}
                maxLength="10"
                pattern="\d{10}"
                title="Please enter a valid 10-digit mobile number"
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <div>
              <input
                required
                type="text"
                placeholder="Email"
                className="form-control"
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                style={{
                  border: "1px solid black",
                  marginBottom: "10px",
                  fontFamily: '"Libre Baskerville", serif',
                  fontWeight: "bold",
                }}
              />
            </div>
            <input
              required
              type="password"
              placeholder="Password"
              className="form-control"
              value={password}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
              style={{
                border: "1px solid black",
                marginBottom: "10px",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            />
            <input
              required
              type="password"
              placeholder="Confirm Password"
              className="form-control mb-3"
              value={cpassword}
              onChange={(e) => {
                setcpassword(e.target.value);
              }}
              style={{
                border: "1px solid black",
                marginBottom: "10px",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            />

            <button
              onClick={register}
              className="btn mt-3 mb-3"
              disabled={!isAllFieldsFilled}
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            >
              REGISTER
            </button>
            <br></br>
            <a
              style={{
                color: "black",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
              href="/login"
            >
              Click Here To Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
