// import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { addStudent } from "../actions/studentActions";
// import Loading from "../components/Loading";
// import Error from "../components/Error";
// import Success from "../components/Success";

// export default function AddStudent() {
//   const [firstname, setfirstname] = useState("");
//   const [lastname, setlastname] = useState("");
//   const [middlename, setmiddlename] = useState("");
//   const [name, setname] = useState("");
//   const [mobile, setmobile] = useState("");
//   const [email, setemail] = useState("");
//   const [school, setschool] = useState("");
//   const [classs, setclasss] = useState("");
//   const [section, setsection] = useState("");
//   const [rollnumber, setrollnumber] = useState("");
//   const [city, setcity] = useState("");
//   const [locality, setlocality] = useState("");
//   const [guardian, setguardian] = useState("");
//   const [schoolcode, setschoolcode] = useState("");
//   //   const [name, setname] = useState("");
//   //   const [smallprice, setsmallprice] = useState();
//   //   const [mediumprice, setmediumprice] = useState();
//   //   const [largeprice, setlargeprice] = useState();
//   //   const [image, setimage] = useState("");
//   //   const [description, setdescription] = useState("");
//   //   const [category, setcategory] = useState("");
//   const dispatch = useDispatch();

//   const addstudentstate = useSelector((state) => state.addStudentReducer);
//   const { success, error, loading } = addstudentstate;
//   function formHandler(e) {
//     e.preventDefault();
//     const student = {
//       firstname,
//       lastname,
//       middlename,
//       name: firstname + " " + middlename + " " + lastname,
//       mobile,
//       email,
//       school,
//       classs,
//       section,
//       rollnumber,
//       city,
//       locality,
//       guardian,
//       schoolcode,
//     };
//     console.log(student);
//     dispatch(addStudent(student));
//   }

//   return (
//     <div>
//       <div className="text-start">
//         <h1> Add Student Details :</h1>

//         {loading && <Loading />}
//         {error && <Error error="Somethig went wrong" />}
//         {success && <Success success="New Student added successfully" />}

//         <form onSubmit={formHandler}>
//           <input
//             className="form-control"
//             type="text"
//             placeholder="First Name"
//             value={firstname}
//             onChange={(e) => {
//               setfirstname(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Middle Name"
//             value={middlename}
//             onChange={(e) => {
//               setmiddlename(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Last Name"
//             value={lastname}
//             onChange={(e) => {
//               setlastname(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Mobile"
//             value={mobile}
//             onChange={(e) => {
//               setmobile(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Email"
//             value={email}
//             onChange={(e) => {
//               setemail(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="school"
//             value={school}
//             onChange={(e) => {
//               setschool(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Class [1,2,3,etc]"
//             value={classs}
//             onChange={(e) => {
//               setclasss(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Class Section [A-H]"
//             value={section}
//             onChange={(e) => {
//               setsection(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Roll Number"
//             value={rollnumber}
//             onChange={(e) => {
//               setrollnumber(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="City"
//             value={city}
//             onChange={(e) => {
//               setcity(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Locality"
//             value={locality}
//             onChange={(e) => {
//               setlocality(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Guardian Name"
//             value={guardian}
//             onChange={(e) => {
//               setguardian(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="School Code"
//             value={schoolcode}
//             onChange={(e) => {
//               setschoolcode(e.target.value);
//             }}
//           />

//           <button className="btn mt-3" type="submit">
//             Add Item
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }
////////////////////////////////////////////////////////////////////////////////
// import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { addStudent } from "../actions/studentActions";
// import Loading from "../components/Loading";
// import Error from "../components/Error";
// import Success from "../components/Success";

// export default function AddStudent() {
//   const [firstname, setfirstname] = useState("");
//   const [lastname, setlastname] = useState("");
//   const [middlename, setmiddlename] = useState("");
//   const [mobile, setmobile] = useState("");
//   const [email, setemail] = useState("");
//   const [school, setschool] = useState("");
//   const [classs, setclasss] = useState("");
//   const [section, setsection] = useState("");
//   const [rollnumber, setrollnumber] = useState("");
//   const [city, setcity] = useState("");
//   const [locality, setlocality] = useState("");
//   const [guardian, setguardian] = useState("");
//   const [schoolcode, setschoolcode] = useState("");

//   const dispatch = useDispatch();

//   const addstudentstate = useSelector((state) => state.addStudentReducer);
//   const { success, error, loading } = addstudentstate;

//   const handleMobileChange = (e) => {
//     const value = e.target.value;
//     if (value === "" || /^[0-9\b]+$/.test(value)) {
//       setmobile(value);
//     }
//   };

//   const handleTextChange = (setter) => (e) => {
//     const value = e.target.value;
//     if (/^[a-zA-Z\s]*$/.test(value)) {
//       setter(value);
//     }
//   };

//   function formHandler(e) {
//     e.preventDefault();
//     const student = {
//       firstname,
//       lastname,
//       middlename,
//       name: firstname + " " + middlename + " " + lastname,
//       mobile,
//       email,
//       school,
//       classs,
//       section,
//       rollnumber,
//       city,
//       locality,
//       guardian,
//       schoolcode,
//     };
//     console.log(student);
//     dispatch(addStudent(student));
//   }

//   return (
//     <div>
//       <div className="text-start">
//         <h1> Add Student Details :</h1>

//         {loading && <Loading />}
//         {error && <Error error="Somethig went wrong" />}
//         {success && <Success success="New Student added successfully" />}

//         <form onSubmit={formHandler}>
//           <input
//             className="form-control"
//             type="text"
//             placeholder="First Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//             value={firstname}
//             onChange={handleTextChange(setfirstname)}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Middle Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//             value={middlename}
//             onChange={handleTextChange(setmiddlename)}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Last Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//             value={lastname}
//             onChange={handleTextChange(setlastname)}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="10-digit Mobile Number"
//             value={mobile}
//             onChange={handleMobileChange}
//             maxLength="10"
//             pattern="\d{10}"
//             title="Please enter a valid 10-digit mobile number"
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Email"
//             value={email}
//             onChange={(e) => {
//               setemail(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="School Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//             value={school}
//             onChange={handleTextChange(setschool)}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Class [1,2,3,etc]"
//             value={classs}
//             onChange={(e) => {
//               setclasss(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Class Section [A-H]"
//             value={section}
//             onChange={(e) => {
//               setsection(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Roll Number"
//             value={rollnumber}
//             onChange={(e) => {
//               setrollnumber(e.target.value);
//             }}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="City     [ only alphabets ( A-Z , a-z ) are allowed ]"
//             value={city}
//             onChange={handleTextChange(setcity)}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Locality     [ only alphabets ( A-Z , a-z ) are allowed ]"
//             value={locality}
//             onChange={handleTextChange(setlocality)}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Guardian Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
//             value={guardian}
//             onChange={handleTextChange(setguardian)}
//           />
//           <input
//             className="form-control"
//             type="text"
//             placeholder="School Code"
//             value={schoolcode}
//             onChange={(e) => {
//               setschoolcode(e.target.value);
//             }}
//           />

//           <button className="btn mt-3" type="submit">
//             Add Item
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }
///////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStudent } from "../actions/studentActions";
import { getAllClassss } from "../actions/classsActions";
import { getAllRollnumbers } from "../actions/rollnumberActions";
import { getAllSchoolcodes } from "../actions/schoolcodeActions"; // Import getAllSchoolcodes action
import Loading from "../components/Loading";
import Error from "../components/Error";
import Success from "../components/Success";

export default function AddStudent() {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [middlename, setmiddlename] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [school, setschool] = useState("");
  const [classs, setclasss] = useState("");
  const [rollnumber, setrollnumber] = useState("");
  const [section, setsection] = useState("");
  const [city, setcity] = useState("");
  const [locality, setlocality] = useState("");
  const [guardian, setguardian] = useState("");
  const [schoolcode, setschoolcode] = useState("");

  const dispatch = useDispatch();

  const addstudentstate = useSelector((state) => state.addStudentReducer);
  const { success, error, loading } = addstudentstate;

  const classssState = useSelector((state) => state.getAllClassssReducer);
  const {
    classss: classes,
    loading: classssLoading,
    error: classssError,
  } = classssState;

  const rollnumbersState = useSelector(
    (state) => state.getAllRollnumbersReducer
  );
  const {
    rollnumbers: rollnumbers,
    loading: rollnumbersLoading,
    error: rollnumbersError,
  } = rollnumbersState;

  const schoolcodesState = useSelector(
    (state) => state.getAllSchoolcodesReducer
  );
  const {
    schoolcodes,
    loading: schoolcodesLoading,
    error: schoolcodesError,
  } = schoolcodesState;

  useEffect(() => {
    dispatch(getAllClassss()); // Fetch all classes
    dispatch(getAllRollnumbers());
    dispatch(getAllSchoolcodes()); // Fetch all school codes
  }, [dispatch]);

  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9\b]+$/.test(value)) {
      setmobile(value);
    }
  };

  const handleTextChange = (setter) => (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setter(value);
    }
  };

  const handleSchoolcodeChange = (e) => {
    const selectedCode = e.target.value;
    setschoolcode(selectedCode);
    const selectedSchool = schoolcodes.find(
      (code) => code.code === selectedCode
    );
    if (selectedSchool) {
      setschool(selectedSchool.school);
    } else {
      setschool("");
    }
  };

  function formHandler(e) {
    e.preventDefault();
    const student = {
      firstname: firstname.trim(),
      lastname: lastname.trim(),
      middlename: middlename.trim(),
      name: `${firstname.trim()} ${middlename.trim()} ${lastname.trim()}`,
      mobile: mobile.trim(),
      email: email.trim(),
      school: school.trim(),
      classs: classs.trim(),
      section: section.trim(),
      rollnumber: rollnumber.trim(),
      city: city.trim(),
      locality: locality.trim(),
      guardian: guardian.trim(),
      schoolcode: schoolcode.trim(),
    };
    console.log(student);
    dispatch(addStudent(student));
  }

  return (
    <div>
      <div className="text-start">
        <h1> Add Student Details :</h1>

        {loading && <Loading />}
        {error && <Error error="Something went wrong" />}
        {success && <Success success="New Student added successfully" />}

        <form onSubmit={formHandler}>
          <input
            className="form-control"
            type="text"
            placeholder="First Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
            value={firstname}
            onChange={handleTextChange(setfirstname)}
          />
          <input
            className="form-control"
            type="text"
            placeholder="Middle Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
            value={middlename}
            onChange={handleTextChange(setmiddlename)}
          />
          <input
            className="form-control"
            type="text"
            placeholder="Last Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
            value={lastname}
            onChange={handleTextChange(setlastname)}
          />
          <input
            className="form-control"
            type="text"
            placeholder="10-digit Mobile Number"
            value={mobile}
            onChange={handleMobileChange}
            maxLength="10"
            pattern="\d{10}"
            title="Please enter a valid 10-digit mobile number"
          />
          <input
            className="form-control"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setemail(e.target.value);
            }}
          />
          <div className="form-group">
            <label></label>
            <select
              className="form-control"
              value={schoolcode}
              onChange={handleSchoolcodeChange}
              style={{ border: "1px solid black", marginBottom: "10px" }}
            >
              <option value="">Select School Code</option>
              {schoolcodes &&
                schoolcodes.map((code) => (
                  <option key={code._id} value={code.code}>
                    {code.code}
                  </option>
                ))}
            </select>
          </div>
          <input
            className="form-control"
            type="text"
            placeholder="School Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
            value={school}
            onChange={handleTextChange(setschool)}
            readOnly
            style={{ border: "1px solid black", marginBottom: "10px" }}
          />
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Roll Number"
              value={rollnumber}
              onChange={(e) => setrollnumber(e.target.value)}
              style={{ border: "1px solid black", marginBottom: "10px" }}
            />
          </div>
          <div className="form-group">
            <label></label>
            <select
              className="form-control"
              value={classs}
              onChange={(e) => setclasss(e.target.value)}
              style={{ marginBottom: "10px" }}
            >
              <option value="">Select Class</option>
              {classes &&
                classes.map((cls) => (
                  <option key={cls._id} value={cls.cls}>
                    {cls.cls}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label></label>
            <select
              className="form-control"
              value={section}
              onChange={(e) => setsection(e.target.value)}
              style={{ marginBottom: "10px" }}
            >
              <option value="">Select Class Section</option>
              <option key="A" value="A">
                A
              </option>
              <option key="B" value="B">
                B
              </option>
              <option key="C" value="C">
                C
              </option>
              <option key="D" value="D">
                D
              </option>
              <option key="E" value="E">
                E
              </option>
              <option key="F" value="F">
                F
              </option>
              <option key="G" value="G">
                G
              </option>
              <option key="H" value="H">
                H
              </option>
            </select>
          </div>
          <input
            className="form-control"
            type="text"
            placeholder="City     [ only alphabets ( A-Z , a-z ) are allowed ]"
            value={city}
            onChange={handleTextChange(setcity)}
          />
          <input
            className="form-control"
            type="text"
            placeholder="Locality     [ only alphabets ( A-Z , a-z ) are allowed ]"
            value={locality}
            onChange={handleTextChange(setlocality)}
          />
          <input
            className="form-control"
            type="text"
            placeholder="Guardian Name     [ only alphabets ( A-Z , a-z ) are allowed ]"
            value={guardian}
            onChange={handleTextChange(setguardian)}
          />
          <button className="btn mt-3" type="submit">
            Add Item
          </button>
        </form>
      </div>
    </div>
  );
}
