// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { IoVolumeHighOutline } from "react-icons/io5";
// import axios from "axios";
// import "./qp.css"; // Import the CSS file

// function QuestionPageDesktop() {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const questions = location.state ? location.state.questions : null;
//   const name = location.state ? location.state.name : null;
//   const division = location.state ? location.state.division : null;
//   const userId = location.state ? location.state.userId : null;
//   const quizId = location.state ? location.state.quizId : null;
//   const quizName = location.state ? location.state.quizName : null;
//   const quizCategory = location.state ? location.state.quizCategory : null;
//   const quizSubject = location.state ? location.state.quizSubject : null;
//   const userName = location.state ? location.state.userName : null;
//   const userSchoolcode = location.state ? location.state.userSchoolcode : null;
//   const userClass = location.state ? location.state.userClass : null;
//   const userSection = location.state ? location.state.userSection : null;

//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [feedbackMessage, setFeedbackMessage] = useState("");
//   const [isCorrect, setIsCorrect] = useState(false);
//   const [showQuestionImage, setShowQuestionImage] = useState(true);
//   const [showAnswerImage, setShowAnswerImage] = useState(false);
//   const [shouldReadExplanation, setShouldReadExplanation] = useState(false);
//   const [result, setResult] = useState(Array(questions.length).fill(-1));
//   const [showAnswer, setShowAnswer] = useState(false);

//   useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to top when the component mounts
//   }, []);

//   useEffect(() => {
//     const handleContextMenu = (event) => {
//       event.preventDefault();
//     };

//     const handleKeyDown = (event) => {
//       if (event.ctrlKey) {
//         event.preventDefault();
//       }
//     };

//     document.addEventListener("contextmenu", handleContextMenu);
//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("contextmenu", handleContextMenu);
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, []);

//   const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value);
//     const currentQuestion = questions[currentQuestionIndex];
//     if (event.target.value === currentQuestion.correct_answer) {
//       setFeedbackMessage("Correct Option Chosen");
//       setIsCorrect(true);
//       setShowAnswerImage(true);
//       setShouldReadExplanation(true);
//       if (result[currentQuestionIndex] === -1) {
//         updateResult(1);
//       }
//     } else {
//       setFeedbackMessage("Wrong Answer Chosen");
//       setIsCorrect(false);
//       setShowAnswerImage(false);
//       setShouldReadExplanation(false);
//       if (result[currentQuestionIndex] === -1) {
//         updateResult(0);
//       }
//     }
//   };

//   const updateResult = (value) => {
//     setResult((prevResult) => {
//       const newResult = [...prevResult];
//       newResult[currentQuestionIndex] = value;
//       return newResult;
//     });
//   };

//   const clearOptions = () => {
//     setSelectedOption(null);
//     setFeedbackMessage("");
//     setShowAnswerImage(false);
//     setShouldReadExplanation(false);
//     setShowAnswer(false);
//     if (result[currentQuestionIndex] === -1) {
//       updateResult(-1);
//     }
//   };

//   const handleNextQuestion = () => {
//     if (currentQuestionIndex < questions.length - 1) {
//       setCurrentQuestionIndex(currentQuestionIndex + 1);
//       setSelectedOption(null);
//       setFeedbackMessage("");
//       setShowAnswerImage(false);
//       setShouldReadExplanation(false);
//       setShowQuestionImage(true);
//       setShowAnswer(false);
//       setIsCorrect(false);
//     }
//   };

//   const handlePreviousQuestion = () => {
//     if (currentQuestionIndex > 0) {
//       setCurrentQuestionIndex(currentQuestionIndex - 1);
//       setSelectedOption(null);
//       setFeedbackMessage("");
//       setShowAnswerImage(false);
//       setShouldReadExplanation(false);
//       setShowQuestionImage(true);
//       setShowAnswer(false);
//       setIsCorrect(false);
//     }
//   };

//   const handleQuestionChange = (index) => {
//     setCurrentQuestionIndex(index);
//     setSelectedOption(null);
//     setFeedbackMessage("");
//     setShowAnswerImage(false);
//     setShouldReadExplanation(false);
//     setShowQuestionImage(true);
//     setShowAnswer(false);
//     setIsCorrect(false);
//   };

//   const handleVoiceReading = () => {
//     const speech = new SpeechSynthesisUtterance();
//     const currentQuestion = questions[currentQuestionIndex];
//     let fullText = `Question ${currentQuestionIndex + 1}. ${
//       currentQuestion.question
//     }. ${currentQuestion.question_in_hindi}. Options are: `;
//     currentQuestion.options.forEach(
//       (option, index) => (fullText += `${option}. `)
//     );
//     if (shouldReadExplanation) {
//       fullText += `Explanation in English: ${currentQuestion.answer_explanation_in_english}. Explanation in Hindi: ${currentQuestion.answer_explanation_in_hindi}.`;
//     }
//     speech.text = fullText;
//     speech.volume = 1;
//     speech.rate = 1;
//     speech.pitch = 1;
//     speech.lang = "hi-IN"; // Set language to Hindi
//     window.speechSynthesis.speak(speech);
//   };

//   const modifyImageUrl = (imageUrl) => {
//     const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
//     return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
//   };

//   if (!questions) {
//     return <div className="container mt-3">Loading...</div>;
//   }

//   const handleReturnToQuizList = async () => {
//     try {
//       const totalQuestions = result.length;
//       const correctAnswers = result.filter((r) => r === 1).length;
//       const wrongAnswers = result.filter((r) => r === 0).length;
//       const attemptedQuestions = result.filter((r) => r !== -1).length;

//       await axios.post("/api/users/attemptquiz", {
//         userId,
//         quizId,
//         totalQuestions,
//         correctAnswers,
//         wrongAnswers,
//         attemptedQuestions,
//         division,
//         userName,
//         userSchoolcode,
//         userClass,
//         userSection,
//         quizName,
//         quizCategory,
//         quizSubject,
//       });

//       navigate(division === "Udbodh" ? "/udbodh" : "/bauddhadakshata");
//     } catch (error) {
//       console.error("Error at Exit Quiz", error);
//     }
//   };

//   const handleSubmitQuiz = async () => {
//     try {
//       const totalQuestions = result.length;
//       const correctAnswers = result.filter((r) => r === 1).length;
//       const wrongAnswers = result.filter((r) => r === 0).length;
//       const attemptedQuestions = result.filter((r) => r !== -1).length;

//       await axios.post("/api/users/completequiz", {
//         userId,
//         quizId,
//         totalQuestions,
//         correctAnswers,
//         wrongAnswers,
//         attemptedQuestions,
//         division,
//         userName,
//         userSchoolcode,
//         userClass,
//         userSection,
//         quizName,
//         quizCategory,
//         quizSubject,
//       });

//       navigate("/quizresult", {
//         state: {
//           totalQuestions,
//           correctAnswers,
//           wrongAnswers,
//           attemptedQuestions,
//           division,
//         },
//       });
//     } catch (error) {
//       console.error("Error at Submitting Quiz", error);
//     }
//   };

//   return (
//     <div className="question-page-desktop-container">
//       <div className="desktop-question-content mt-5">
//         <br />
//         <h2
//           style={{
//             backgroundColor: "black",
//             padding: "10px",
//             color: "white",
//             fontFamily: "'Georgia Ref', Georgia, serif",
//             fontSize: "35px",
//             textAlign: "center",
//           }}
//         >
//           {name}
//         </h2>
//         <br />
//         <h2
//           className="desktop-question-header"
//           style={{
//             fontFamily: "'Georgia Ref', Georgia, serif",
//             fontSize: "28px",
//           }}
//         >
//           Question {currentQuestionIndex + 1} :-
//           <button
//             className="desktop-voice-button btn btn-link"
//             onClick={handleVoiceReading}
//             style={{
//               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//               fontSize: "20px",
//               marginLeft: "400px",
//               color: "black",
//             }}
//           >
//             <IoVolumeHighOutline />
//           </button>
//           <button
//             className="btn btn-primary"
//             style={{
//               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//               marginLeft: "20px",
//               fontFamily: "'Georgia Ref', Georgia, serif",
//               fontSize: "20px",
//             }}
//             onClick={handleSubmitQuiz}
//           >
//             Submit
//           </button>
//           <button
//             className="btn btn-primary"
//             style={{
//               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//               marginLeft: "20px",
//               fontFamily: "'Georgia Ref', Georgia, serif",
//               fontSize: "20px",
//             }}
//             onClick={handleReturnToQuizList}
//           >
//             Exit Quiz
//           </button>
//         </h2>
//         <hr />
//         <p
//           className="desktop-question-text"
//           style={{
//             fontFamily: "'Maged Bold', Georgia, serif",
//             fontWeight: "bold",
//             fontSize: "20px",
//           }}
//         >
//           {questions[currentQuestionIndex].question}
//         </p>
//         <p
//           className="desktop-question-text-bold"
//           style={{
//             fontFamily: "'Maged Bold', Georgia, serif",
//             fontWeight: "bold",
//             fontSize: "20px",
//           }}
//         >
//           {questions[currentQuestionIndex].question_in_hindi}
//         </p>
//         {questions[currentQuestionIndex].question_image && (
//           <div>
//             <img
//               src={modifyImageUrl(
//                 questions[currentQuestionIndex].question_image
//               )}
//               alt="Question Image"
//               className="desktop-question-image"
//             />
//           </div>
//         )}
//         <form className="desktop-options-form ms-5">
//           {questions[currentQuestionIndex].options.map((option, index) => (
//             <div className="form-check desktop-option-item" key={index}>
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 id={`option${index}`}
//                 name="option"
//                 value={option}
//                 checked={option === selectedOption}
//                 onChange={handleOptionChange}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor={`option${index}`}
//                 style={{
//                   fontFamily: "'Georgia Ref', Georgia, serif",
//                   fontSize: "25px",
//                   textAlign: "center",
//                 }}
//               >
//                 {option}
//               </label>
//             </div>
//           ))}
//         </form>
//         <div>
//           {currentQuestionIndex > 0 && (
//             <button
//               className="btn btn-secondary"
//               style={{
//                 boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//                 marginRight: "10px",
//               }}
//               onClick={handlePreviousQuestion}
//             >
//               Previous
//             </button>
//           )}
//           {currentQuestionIndex < questions.length - 1 && (
//             <button
//               className="btn btn-primary"
//               style={{
//                 boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//                 marginRight: "10px",
//               }}
//               onClick={handleNextQuestion}
//             >
//               Next
//             </button>
//           )}
//           <button
//             className="btn btn-warning"
//             onClick={clearOptions}
//             style={{
//               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//               marginRight: "10px",
//             }}
//           >
//             Clear Options
//           </button>
//           <button
//             className="btn btn-info"
//             onClick={() => setShowAnswer(true)}
//             disabled={isCorrect || !selectedOption || isCorrect}
//             style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}
//           >
//             Show Answer
//           </button>
//           <div
//             style={{
//               marginTop: "10px",
//               fontWeight: "bold",
//               color: isCorrect ? "green" : "red",
//             }}
//           >
//             {feedbackMessage}
//           </div>
//           {(isCorrect || showAnswer) && (
//             <div className="desktop-answer-explanation-container">
//               <div className="desktop-explanation-text-container-left">
//                 <p
//                   className="desktop-explanation-text"
//                   style={{
//                     fontFamily: "'Maged Bold', Georgia, serif",
//                     fontWeight: "bold",
//                     fontSize: "20px",
//                   }}
//                 >
//                   {
//                     questions[currentQuestionIndex]
//                       .answer_explanation_in_english
//                   }
//                 </p>
//               </div>
//               {questions[currentQuestionIndex].answer_image && (
//                 <div className="desktop-answer-image-container">
//                   <img
//                     src={modifyImageUrl(
//                       questions[currentQuestionIndex].answer_image
//                     )}
//                     width="200px"
//                     height="auto"
//                     alt="Answer Image"
//                     className="desktop-answer-image"
//                   />
//                 </div>
//               )}
//               <div className="desktop-explanation-text-container-right">
//                 <p
//                   className="desktop-explanation-text"
//                   style={{
//                     fontFamily: "'Maged Bold', Georgia, serif",
//                     fontWeight: "bold",
//                     fontSize: "20px",
//                   }}
//                 >
//                   {questions[currentQuestionIndex].answer_explanation_in_hindi}
//                 </p>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className="desktop-navigation-box mt-5">
//         <h2
//           style={{
//             width: "100%",
//             textAlign: "left",
//             fontFamily: "'Georgia Ref', Georgia, serif",
//             fontSize: "28px",
//           }}
//         >
//           {
//             <div>
//               <hr></hr>
//             </div>
//           }
//           Questions :
//           {
//             <div>
//               <hr></hr>
//             </div>
//           }
//         </h2>
//         <div className="desktop-navigation-grid mt-1">
//           {questions.map((_, index) => (
//             <button
//               style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}
//               key={index}
//               className={`desktop-navigation-button ${
//                 currentQuestionIndex === index ? "active" : "btn btn-primary"
//               }`}
//               onClick={() => handleQuestionChange(index)}
//             >
//               {index + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default QuestionPageDesktop;
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { IoVolumeHighOutline } from "react-icons/io5";
// import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import "./qp.css"; // Import the CSS file

// function QuestionPageDesktop() {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const questions = location.state ? location.state.questions : null;
//   const name = location.state ? location.state.name : null;
//   const division = location.state ? location.state.division : null;
//   const userId = location.state ? location.state.userId : null;
//   const quizId = location.state ? location.state.quizId : null;
//   const quizName = location.state ? location.state.quizName : null;
//   const quizCategory = location.state ? location.state.quizCategory : null;
//   const quizSubject = location.state ? location.state.quizSubject : null;
//   const userName = location.state ? location.state.userName : null;
//   const userSchoolcode = location.state ? location.state.userSchoolcode : null;
//   const userClass = location.state ? location.state.userClass : null;
//   const userSection = location.state ? location.state.userSection : null;

//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [feedbackMessage, setFeedbackMessage] = useState("");
//   const [isCorrect, setIsCorrect] = useState(false);
//   const [showQuestionImage, setShowQuestionImage] = useState(true);
//   const [showAnswerImage, setShowAnswerImage] = useState(false);
//   const [shouldReadExplanation, setShouldReadExplanation] = useState(false);
//   const [result, setResult] = useState(Array(questions.length).fill(-1));
//   const [showAnswer, setShowAnswer] = useState(false);

//   const [showQuestionImageModal, setshowQuestionImageModal] = useState(false);
//   const [showAnswerImageModal, setshowAnswerImageModal] = useState(false);

//   useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to top when the component mounts
//   }, []);

//   useEffect(() => {
//     const handleContextMenu = (event) => {
//       event.preventDefault();
//     };

//     const handleKeyDown = (event) => {
//       if (event.ctrlKey) {
//         event.preventDefault();
//       }
//     };

//     document.addEventListener("contextmenu", handleContextMenu);
//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("contextmenu", handleContextMenu);
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, []);

//   const handleCloseQuestionImageModal = () => setshowQuestionImageModal(false);
//   const handleShowQuestionImageModal = () => setshowQuestionImageModal(true);

//   const handleCloseAnswerImageModal = () => setshowAnswerImageModal(false);
//   const handleShowAnswerImageModal = () => setshowAnswerImageModal(true);

//   const enterFullScreen = (element) => {
//     if (element.requestFullscreen) {
//       element.requestFullscreen();
//     } else if (element.mozRequestFullScreen) {
//       // Firefox
//       element.mozRequestFullScreen();
//     } else if (element.webkitRequestFullscreen) {
//       // Chrome, Safari and Opera
//       element.webkitRequestFullscreen();
//     } else if (element.msRequestFullscreen) {
//       // IE/Edge
//       element.msRequestFullscreen();
//     }
//   };

//   const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value);
//     const currentQuestion = questions[currentQuestionIndex];
//     if (event.target.value === currentQuestion.correct_answer) {
//       setFeedbackMessage("Correct Option Chosen");
//       setIsCorrect(true);
//       setShowAnswerImage(true);
//       setShouldReadExplanation(true);
//       if (result[currentQuestionIndex] === -1) {
//         updateResult(1);
//       }
//     } else {
//       setFeedbackMessage("Wrong Answer Chosen");
//       setIsCorrect(false);
//       setShowAnswerImage(false);
//       setShouldReadExplanation(false);
//       if (result[currentQuestionIndex] === -1) {
//         updateResult(0);
//       }
//     }
//   };

//   const updateResult = (value) => {
//     setResult((prevResult) => {
//       const newResult = [...prevResult];
//       newResult[currentQuestionIndex] = value;
//       return newResult;
//     });
//   };

//   const clearOptions = () => {
//     setSelectedOption(null);
//     setFeedbackMessage("");
//     setShowAnswerImage(false);
//     setShouldReadExplanation(false);
//     setShowAnswer(false);
//     if (result[currentQuestionIndex] === -1) {
//       updateResult(-1);
//     }
//   };

//   const handleNextQuestion = () => {
//     if (currentQuestionIndex < questions.length - 1) {
//       setCurrentQuestionIndex(currentQuestionIndex + 1);
//       setSelectedOption(null);
//       setFeedbackMessage("");
//       setShowAnswerImage(false);
//       setShouldReadExplanation(false);
//       setShowQuestionImage(true);
//       setShowAnswer(false);
//       setIsCorrect(false);
//     }
//   };

//   const handlePreviousQuestion = () => {
//     if (currentQuestionIndex > 0) {
//       setCurrentQuestionIndex(currentQuestionIndex - 1);
//       setSelectedOption(null);
//       setFeedbackMessage("");
//       setShowAnswerImage(false);
//       setShouldReadExplanation(false);
//       setShowQuestionImage(true);
//       setShowAnswer(false);
//       setIsCorrect(false);
//     }
//   };

//   const handleQuestionChange = (index) => {
//     setCurrentQuestionIndex(index);
//     setSelectedOption(null);
//     setFeedbackMessage("");
//     setShowAnswerImage(false);
//     setShouldReadExplanation(false);
//     setShowQuestionImage(true);
//     setShowAnswer(false);
//     setIsCorrect(false);
//   };

//   const handleVoiceReading = () => {
//     const speech = new SpeechSynthesisUtterance();
//     const currentQuestion = questions[currentQuestionIndex];
//     let fullText = `Question ${currentQuestionIndex + 1}. ${
//       currentQuestion.question
//     }. ${currentQuestion.question_in_hindi}. Options are: `;
//     currentQuestion.options.forEach(
//       (option, index) => (fullText += `${option}. `)
//     );
//     if (shouldReadExplanation) {
//       fullText += `Explanation in English: ${currentQuestion.answer_explanation_in_english}. Explanation in Hindi: ${currentQuestion.answer_explanation_in_hindi}.`;
//     }
//     speech.text = fullText;
//     speech.volume = 1;
//     speech.rate = 1;
//     speech.pitch = 1;
//     speech.lang = "hi-IN"; // Set language to Hindi
//     window.speechSynthesis.speak(speech);
//   };

//   const modifyImageUrl = (imageUrl) => {
//     const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
//     return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
//   };

//   if (!questions) {
//     return <div className="container mt-3">Loading...</div>;
//   }

//   const handleReturnToQuizList = async () => {
//     try {
//       const totalQuestions = result.length;
//       const correctAnswers = result.filter((r) => r === 1).length;
//       const wrongAnswers = result.filter((r) => r === 0).length;
//       const attemptedQuestions = result.filter((r) => r !== -1).length;

//       await axios.post("/api/users/attemptquiz", {
//         userId,
//         quizId,
//         totalQuestions,
//         correctAnswers,
//         wrongAnswers,
//         attemptedQuestions,
//         division,
//         userName,
//         userSchoolcode,
//         userClass,
//         userSection,
//         quizName,
//         quizCategory,
//         quizSubject,
//       });

//       navigate(division === "Udbodh" ? "/udbodh" : "/bauddhadakshata");
//     } catch (error) {
//       console.error("Error at Exit Quiz", error);
//     }
//   };

//   const handleSubmitQuiz = async () => {
//     try {
//       const totalQuestions = result.length;
//       const correctAnswers = result.filter((r) => r === 1).length;
//       const wrongAnswers = result.filter((r) => r === 0).length;
//       const attemptedQuestions = result.filter((r) => r !== -1).length;

//       await axios.post("/api/users/completequiz", {
//         userId,
//         quizId,
//         totalQuestions,
//         correctAnswers,
//         wrongAnswers,
//         attemptedQuestions,
//         division,
//         userName,
//         userSchoolcode,
//         userClass,
//         userSection,
//         quizName,
//         quizCategory,
//         quizSubject,
//       });

//       // await axios.post("/api/users/sendresult", {
//       //   userId,
//       //   totalQuestions,
//       //   attemptedQuestions,
//       //   correctAnswers,
//       //   wrongAnswers,
//       // });

//       navigate("/quizresult", {
//         state: {
//           totalQuestions,
//           correctAnswers,
//           wrongAnswers,
//           attemptedQuestions,
//           division,
//         },
//       });
//     } catch (error) {
//       console.error("Error at Submitting Quiz", error);
//     }
//   };

//   return (
//     <div className="question-page-desktop-container">
//       <div className="desktop-question-content mt-5">
//         <br />
//         <h2
//           style={{
//             backgroundColor: "black",
//             padding: "10px",
//             color: "white",
//             fontFamily: "'Georgia Ref', Georgia, serif",
//             fontSize: "35px",
//             textAlign: "center",
//           }}
//         >
//           {name}
//         </h2>
//         <br />
//         <p style={{ color: "red", fontStyle: "italic" }}>
//           Only First Attempted Option will be counted in Final Result
//         </p>
//         <h2
//           className="desktop-question-header"
//           style={{
//             fontFamily: "'Georgia Ref', Georgia, serif",
//             fontSize: "28px",
//           }}
//         >
//           Question {currentQuestionIndex + 1} :-
//           <button
//             className="desktop-voice-button btn btn-link"
//             onClick={handleVoiceReading}
//             style={{
//               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//               fontSize: "20px",
//               marginLeft: "400px",
//               color: "black",
//             }}
//           >
//             <IoVolumeHighOutline />
//           </button>
//           <button
//             className="btn btn-primary"
//             style={{
//               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//               marginLeft: "20px",
//               fontFamily: "'Georgia Ref', Georgia, serif",
//               fontSize: "20px",
//             }}
//             onClick={handleSubmitQuiz}
//           >
//             Submit
//           </button>
//           <button
//             className="btn btn-primary"
//             style={{
//               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//               marginLeft: "20px",
//               fontFamily: "'Georgia Ref', Georgia, serif",
//               fontSize: "20px",
//             }}
//             onClick={handleReturnToQuizList}
//           >
//             Exit Quiz
//           </button>
//         </h2>
//         <hr />
//         <p
//           className="desktop-question-text"
//           style={{
//             fontFamily: "'Maged Bold', Georgia, serif",
//             fontWeight: "bold",
//             fontSize: "20px",
//           }}
//         >
//           {questions[currentQuestionIndex].question}
//         </p>
//         <p
//           className="desktop-question-text-bold"
//           style={{
//             fontFamily: "'Maged Bold', Georgia, serif",
//             fontWeight: "bold",
//             fontSize: "20px",
//           }}
//         >
//           {questions[currentQuestionIndex].question_in_hindi}
//         </p>
//         {questions[currentQuestionIndex].question_image && (
//           <div>
//             <img
//               src={modifyImageUrl(
//                 questions[currentQuestionIndex].question_image
//               )}
//               alt="Question Image"
//               className="desktop-question-image"
//               onClick={
//                 //()=>
//                 // enterFullScreen(
//                 //   document.getElementById(
//                 //     `question-image-${currentQuestionIndex}`
//                 //   )
//                 // )
//                 handleShowQuestionImageModal
//               }
//               id={`question-image-${currentQuestionIndex}`}
//             />
//           </div>
//         )}

//         <form className="desktop-options-form ms-5">
//           {questions[currentQuestionIndex].options.map((option, index) => (
//             <div className="form-check desktop-option-item" key={index}>
//               <input
//                 className="form-check-input"
//                 type="radio"
//                 id={`option${index}`}
//                 name="option"
//                 value={option}
//                 checked={option === selectedOption}
//                 onChange={handleOptionChange}
//               />
//               <label
//                 className="form-check-label"
//                 htmlFor={`option${index}`}
//                 style={{
//                   fontFamily: "'Georgia Ref', Georgia, serif",
//                   fontSize: "25px",
//                   textAlign: "center",
//                 }}
//               >
//                 {option}
//               </label>
//             </div>
//           ))}
//         </form>
//         <div>
//           {currentQuestionIndex > 0 && (
//             <button
//               className="btn btn-secondary"
//               style={{
//                 boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//                 marginRight: "10px",
//               }}
//               onClick={handlePreviousQuestion}
//             >
//               Previous
//             </button>
//           )}
//           {currentQuestionIndex < questions.length - 1 && (
//             <button
//               className="btn btn-primary"
//               style={{
//                 boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//                 marginRight: "10px",
//               }}
//               onClick={handleNextQuestion}
//             >
//               Next
//             </button>
//           )}
//           <button
//             className="btn btn-warning"
//             onClick={clearOptions}
//             style={{
//               boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//               marginRight: "10px",
//             }}
//           >
//             Clear Options
//           </button>
//           <button
//             className="btn btn-info"
//             onClick={() => setShowAnswer(true)}
//             disabled={isCorrect || !selectedOption || isCorrect}
//             style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}
//           >
//             Show Answer
//           </button>
//           <div
//             style={{
//               marginTop: "10px",
//               fontWeight: "bold",
//               color: isCorrect ? "green" : "red",
//             }}
//           >
//             {feedbackMessage}
//           </div>
//           {(isCorrect || showAnswer) && (
//             <div className="desktop-answer-explanation-container">
//               <div className="desktop-explanation-text-container-left">
//                 <p
//                   className="desktop-explanation-text"
//                   style={{
//                     fontFamily: "'Maged Bold', Georgia, serif",
//                     fontWeight: "bold",
//                     fontSize: "20px",
//                   }}
//                 >
//                   {
//                     questions[currentQuestionIndex]
//                       .answer_explanation_in_english
//                   }
//                 </p>
//               </div>
//               {questions[currentQuestionIndex].answer_image && (
//                 <div className="desktop-answer-image-container">
//                   <img
//                     src={modifyImageUrl(
//                       questions[currentQuestionIndex].answer_image
//                     )}
//                     width="200px"
//                     height="auto"
//                     alt="Answer Image"
//                     className="desktop-answer-image"
//                     onClick={
//                       //() =>
//                       //   enterFullScreen(
//                       //     document.getElementById(
//                       //       `answer-image-${currentQuestionIndex}`
//                       //     )
//                       //   )
//                       handleShowAnswerImageModal
//                     }
//                     id={`answer-image-${currentQuestionIndex}`}
//                   />
//                 </div>
//               )}

//               <div className="desktop-explanation-text-container-right">
//                 <p
//                   className="desktop-explanation-text"
//                   style={{
//                     fontFamily: "'Maged Bold', Georgia, serif",
//                     fontWeight: "bold",
//                     fontSize: "20px",
//                   }}
//                 >
//                   {questions[currentQuestionIndex].answer_explanation_in_hindi}
//                 </p>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className="desktop-navigation-box mt-5">
//         <h2
//           style={{
//             width: "100%",
//             textAlign: "left",
//             fontFamily: "'Georgia Ref', Georgia, serif",
//             fontSize: "28px",
//           }}
//         >
//           {
//             <div>
//               <hr></hr>
//             </div>
//           }
//           Questions :
//           {
//             <div>
//               <hr></hr>
//             </div>
//           }
//         </h2>
//         <div className="desktop-navigation-grid mt-1">
//           {questions.map((_, index) => (
//             <button
//               style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}
//               key={index}
//               className={`desktop-navigation-button ${
//                 currentQuestionIndex === index ? "active" : "btn btn-primary"
//               }`}
//               onClick={() => handleQuestionChange(index)}
//             >
//               {index + 1}
//             </button>
//           ))}
//         </div>
//       </div>

//       {/* Question Image Modal */}
//       {questions[currentQuestionIndex].question_image && (
//         <Modal
//           show={showQuestionImageModal}
//           onHide={handleCloseQuestionImageModal}
//           size="lg" // Optional: 'sm', 'lg', 'xl'
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>Question {currentQuestionIndex + 1} Image</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <img
//               src={modifyImageUrl(
//                 questions[currentQuestionIndex].question_image
//               )}
//               className="img-fluid"
//               style={{ maxWidth: "100%", height: "auto" }}
//               alt=""
//             />
//           </Modal.Body>
//           <Modal.Footer>
//             <button className="btn" onClick={handleCloseQuestionImageModal}>
//               CLOSE
//             </button>
//           </Modal.Footer>
//         </Modal>
//       )}

//       {/* Answer Image Modal */}
//       {questions[currentQuestionIndex].answer_image && (
//         <Modal
//           show={showAnswerImageModal}
//           onHide={handleCloseAnswerImageModal}
//           size="lg" // Optional: 'sm', 'lg', 'xl'
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>Answer {currentQuestionIndex + 1} Image</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <img
//               src={modifyImageUrl(questions[currentQuestionIndex].answer_image)}
//               className="img-fluid"
//               style={{ maxWidth: "100%", height: "auto" }}
//               alt=""
//             />
//           </Modal.Body>
//           <Modal.Footer>
//             <button className="btn" onClick={handleCloseAnswerImageModal}>
//               CLOSE
//             </button>
//           </Modal.Footer>
//         </Modal>
//       )}
//     </div>
//   );
// }

// export default QuestionPageDesktop;
// ////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoVolumeHighOutline } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import "./qp.css"; // Import the CSS file

function QuestionPageDesktop() {
  const location = useLocation();
  const navigate = useNavigate();
  const questions = location.state ? location.state.questions : null;
  const name = location.state ? location.state.name : null;
  const division = location.state ? location.state.division : null;
  const userId = location.state ? location.state.userId : null;
  const quizId = location.state ? location.state.quizId : null;
  const quizName = location.state ? location.state.quizName : null;
  const quizCategory = location.state ? location.state.quizCategory : null;
  const quizSubject = location.state ? location.state.quizSubject : null;
  const userName = location.state ? location.state.userName : null;
  const userSchoolcode = location.state ? location.state.userSchoolcode : null;
  const userClass = location.state ? location.state.userClass : null;
  const userSection = location.state ? location.state.userSection : null;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [showQuestionImage, setShowQuestionImage] = useState(true);
  const [showAnswerImage, setShowAnswerImage] = useState(false);
  const [shouldReadExplanation, setShouldReadExplanation] = useState(false);
  const [result, setResult] = useState(Array(questions.length).fill(-1));
  const [showAnswer, setShowAnswer] = useState(false);

  const [showQuestionImageModal, setshowQuestionImageModal] = useState(false);
  const [showAnswerImageModal, setshowAnswerImageModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when the component mounts
    const element = document.getElementById("top");
    element?.scrollIntoView({
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const handleKeyDown = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleCloseQuestionImageModal = () => setshowQuestionImageModal(false);
  const handleShowQuestionImageModal = () => setshowQuestionImageModal(true);

  const handleCloseAnswerImageModal = () => setshowAnswerImageModal(false);
  const handleShowAnswerImageModal = () => setshowAnswerImageModal(true);

  const enterFullScreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      element.msRequestFullscreen();
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const currentQuestion = questions[currentQuestionIndex];
    if (event.target.value === currentQuestion.correct_answer) {
      setFeedbackMessage("Correct Option Chosen");
      setIsCorrect(true);
      setShowAnswerImage(true);
      setShouldReadExplanation(true);
      if (result[currentQuestionIndex] === -1) {
        updateResult(1);
      }

      setTimeout(function () {
        const element = document.getElementById("scrollcorrect");
        element?.scrollIntoView({
          behavior: "smooth",
        });
      }, 200);
    } else {
      setFeedbackMessage("Wrong Answer Chosen");
      setIsCorrect(false);
      setShowAnswerImage(false);
      setShouldReadExplanation(false);
      if (result[currentQuestionIndex] === -1) {
        updateResult(0);
      }

      setTimeout(function () {
        const element = document.getElementById("scrollwrong");
        element?.scrollIntoView({
          behavior: "smooth",
        });
      }, 200);
    }
  };

  const updateResult = (value) => {
    setResult((prevResult) => {
      const newResult = [...prevResult];
      newResult[currentQuestionIndex] = value;
      return newResult;
    });
  };

  const clearOptions = () => {
    setSelectedOption(null);
    setFeedbackMessage("");
    setShowAnswerImage(false);
    setShouldReadExplanation(false);
    setShowAnswer(false);
    if (result[currentQuestionIndex] === -1) {
      updateResult(-1);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption(null);
      setFeedbackMessage("");
      setShowAnswerImage(false);
      setShouldReadExplanation(false);
      setShowQuestionImage(true);
      setShowAnswer(false);
      setIsCorrect(false);
      setTimeout(function () {
        const element = document.getElementById("top");
        element?.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedOption(null);
      setFeedbackMessage("");
      setShowAnswerImage(false);
      setShouldReadExplanation(false);
      setShowQuestionImage(true);
      setShowAnswer(false);
      setIsCorrect(false);
      setTimeout(function () {
        const element = document.getElementById("top");
        element?.scrollIntoView({
          behavior: "smooth",
        });
      }, 200);
    }
  };

  const handleQuestionChange = (index) => {
    setCurrentQuestionIndex(index);
    setSelectedOption(null);
    setFeedbackMessage("");
    setShowAnswerImage(false);
    setShouldReadExplanation(false);
    setShowQuestionImage(true);
    setShowAnswer(false);
    setIsCorrect(false);
    setTimeout(function () {
      const element = document.getElementById("top");
      element?.scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  };

  const handleVoiceReading = () => {
    const speech = new SpeechSynthesisUtterance();
    const currentQuestion = questions[currentQuestionIndex];
    let fullText = `Question ${currentQuestionIndex + 1}. ${
      currentQuestion.question
    }. ${currentQuestion.question_in_hindi}. Options are: `;
    currentQuestion.options.forEach(
      (option, index) => (fullText += `${option}. `)
    );
    if (shouldReadExplanation) {
      fullText += `Explanation in English: ${currentQuestion.answer_explanation_in_english}. Explanation in Hindi: ${currentQuestion.answer_explanation_in_hindi}.`;
    }
    speech.text = fullText;
    speech.volume = 1;
    speech.rate = 1;
    speech.pitch = 1;
    speech.lang = "hi-IN"; // Set language to Hindi
    window.speechSynthesis.speak(speech);
  };

  const modifyImageUrl = (imageUrl) => {
    const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
    return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
  };

  if (!questions) {
    return <div className="container mt-3">Loading...</div>;
  }

  const handleReturnToQuizList = async () => {
    try {
      const totalQuestions = result.length;
      const correctAnswers = result.filter((r) => r === 1).length;
      const wrongAnswers = result.filter((r) => r === 0).length;
      const attemptedQuestions = result.filter((r) => r !== -1).length;

      await axios.post("/api/users/attemptquiz", {
        userId,
        quizId,
        totalQuestions,
        correctAnswers,
        wrongAnswers,
        attemptedQuestions,
        division,
        userName,
        userSchoolcode,
        userClass,
        userSection,
        quizName,
        quizCategory,
        quizSubject,
      });

      navigate(division === "Udbodh" ? "/udbodh" : "/bauddhadakshata");
    } catch (error) {
      console.error("Error at Exit Quiz", error);
    }
  };

  const handleSubmitQuiz = async () => {
    try {
      const totalQuestions = result.length;
      const correctAnswers = result.filter((r) => r === 1).length;
      const wrongAnswers = result.filter((r) => r === 0).length;
      const attemptedQuestions = result.filter((r) => r !== -1).length;

      await axios.post("/api/users/completequiz", {
        userId,
        quizId,
        totalQuestions,
        correctAnswers,
        wrongAnswers,
        attemptedQuestions,
        division,
        userName,
        userSchoolcode,
        userClass,
        userSection,
        quizName,
        quizCategory,
        quizSubject,
      });

      // await axios.post("/api/users/sendresult", {
      //   userId,
      //   totalQuestions,
      //   attemptedQuestions,
      //   correctAnswers,
      //   wrongAnswers,
      // });

      navigate("/quizresult", {
        state: {
          totalQuestions,
          correctAnswers,
          wrongAnswers,
          attemptedQuestions,
          division,
        },
      });
    } catch (error) {
      console.error("Error at Submitting Quiz", error);
    }
  };

  const handleShowAnswer = () => {
    setShowAnswer(true);

    setTimeout(function () {
      const element = document.getElementById("scrollcorrect");
      element?.scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  };

  return (
    <div className="question-page-desktop-container">
      <div className="desktop-question-content mt-5">
        <br />
        <p id="top" style={{ color: "white" }}>
          .
        </p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <h2
          style={{
            backgroundColor: "black",
            padding: "10px",
            color: "white",
            fontFamily: "'Georgia Ref', Georgia, serif",
            fontSize: "35px",
            textAlign: "center",
          }}
        >
          {name}
        </h2>
        <br />
        <p style={{ color: "red", fontStyle: "italic" }}>
          Only First Attempted Option will be counted in Final Result
        </p>
        <h2
          className="desktop-question-header"
          style={{
            fontFamily: "'Georgia Ref', Georgia, serif",
            fontSize: "28px",
          }}
        >
          Question {currentQuestionIndex + 1} :-
          <button
            className="desktop-voice-button btn btn-link"
            onClick={handleVoiceReading}
            style={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              fontSize: "20px",
              marginLeft: "400px",
              color: "black",
            }}
          >
            <IoVolumeHighOutline />
          </button>
          <button
            className="btn btn-primary"
            style={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              marginLeft: "20px",
              fontFamily: "'Georgia Ref', Georgia, serif",
              fontSize: "20px",
            }}
            onClick={handleSubmitQuiz}
          >
            Submit
          </button>
          <button
            className="btn btn-primary"
            style={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              marginLeft: "20px",
              fontFamily: "'Georgia Ref', Georgia, serif",
              fontSize: "20px",
            }}
            onClick={handleReturnToQuizList}
          >
            Exit Quiz
          </button>
        </h2>
        <hr />
        <p
          className="desktop-question-text"
          style={{
            fontFamily: "'Maged Bold', Georgia, serif",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {questions[currentQuestionIndex].question}
        </p>
        <p
          className="desktop-question-text-bold"
          style={{
            fontFamily: "'Maged Bold', Georgia, serif",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {questions[currentQuestionIndex].question_in_hindi}
        </p>
        {questions[currentQuestionIndex].question_image && (
          <div>
            <img
              src={modifyImageUrl(
                questions[currentQuestionIndex].question_image
              )}
              alt="Question Image"
              className="desktop-question-image"
              onClick={
                //()=>
                // enterFullScreen(
                //   document.getElementById(
                //     `question-image-${currentQuestionIndex}`
                //   )
                // )
                handleShowQuestionImageModal
              }
              id={`question-image-${currentQuestionIndex}`}
            />
          </div>
        )}
        <form className="desktop-options-form ms-5">
          {questions[currentQuestionIndex].options.map((option, index) => (
            <div className="form-check desktop-option-item" key={index}>
              <input
                className="form-check-input"
                type="radio"
                id={`option${index}`}
                name="option"
                value={option}
                checked={option === selectedOption}
                onChange={handleOptionChange}
              />
              <label
                className="form-check-label"
                htmlFor={`option${index}`}
                style={{
                  //fontFamily: "'Georgia Ref', Georgia, serif",
                  fontFamily: "'Maged Bold', Georgia, serif",
                  fontSize: "20px",
                }}
              >
                {option}
              </label>
            </div>
          ))}
        </form>
        <div>
          {currentQuestionIndex > 0 && (
            <button
              className="btn btn-secondary"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                marginRight: "10px",
              }}
              onClick={handlePreviousQuestion}
            >
              Previous
            </button>
          )}
          {currentQuestionIndex < questions.length - 1 && (
            <button
              className="btn btn-primary"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                marginRight: "10px",
              }}
              onClick={handleNextQuestion}
            >
              Next
            </button>
          )}
          <button
            className="btn btn-warning"
            onClick={clearOptions}
            style={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              marginRight: "10px",
            }}
          >
            Clear Options
          </button>
          <button
            className="btn btn-info"
            onClick={handleShowAnswer}
            disabled={isCorrect || !selectedOption || isCorrect}
            style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}
          >
            Show Answer
          </button>
          <div
            style={{
              marginTop: "10px",
              fontWeight: "bold",
              color: isCorrect ? "green" : "red",
            }}
          >
            {feedbackMessage}
          </div>
          <div id="scrollwrong" style={{ color: "white" }}>
            .
          </div>
          {(isCorrect || showAnswer) && (
            <div className="desktop-answer-explanation-container">
              <div className="desktop-explanation-text-container-left">
                <p
                  className="desktop-explanation-text"
                  style={{
                    fontFamily: "'Maged Bold', Georgia, serif",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  {
                    questions[currentQuestionIndex]
                      .answer_explanation_in_english
                  }
                </p>
              </div>
              {questions[currentQuestionIndex].answer_image && (
                <div className="desktop-answer-image-container">
                  <img
                    src={modifyImageUrl(
                      questions[currentQuestionIndex].answer_image
                    )}
                    width="200px"
                    height="auto"
                    alt="Answer Image"
                    className="desktop-answer-image"
                    onClick={
                      //() =>
                      //   enterFullScreen(
                      //     document.getElementById(
                      //       `answer-image-${currentQuestionIndex}`
                      //     )
                      //   )
                      handleShowAnswerImageModal
                    }
                    id={`answer-image-${currentQuestionIndex}`}
                  />
                </div>
              )}

              <div className="desktop-explanation-text-container-right">
                <p
                  className="desktop-explanation-text"
                  style={{
                    fontFamily: "'Maged Bold', Georgia, serif",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                  id="scrollcorrect"
                >
                  {questions[currentQuestionIndex].answer_explanation_in_hindi}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="desktop-navigation-box mt-5">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <h2
          style={{
            width: "100%",
            textAlign: "left",
            fontFamily: "'Georgia Ref', Georgia, serif",
            fontSize: "28px",
          }}
        >
          {
            <div>
              <hr></hr>
            </div>
          }
          Questions :
          {
            <div>
              <hr></hr>
            </div>
          }
        </h2>
        <div className="desktop-navigation-grid mt-1">
          {questions.map((_, index) => (
            <button
              style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}
              key={index}
              className={`desktop-navigation-button ${
                currentQuestionIndex === index ? "active" : "btn btn-primary"
              }`}
              onClick={() => handleQuestionChange(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      {/* Question Image Modal */}
      {questions[currentQuestionIndex].question_image && (
        <Modal
          show={showQuestionImageModal}
          onHide={handleCloseQuestionImageModal}
          size="lg" // Optional: 'sm', 'lg', 'xl'
        >
          <Modal.Header closeButton>
            <Modal.Title>Question {currentQuestionIndex + 1} Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={modifyImageUrl(
                questions[currentQuestionIndex].question_image
              )}
              className="img-fluid"
              style={{ maxWidth: "100%", height: "auto" }}
              alt=""
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn" onClick={handleCloseQuestionImageModal}>
              CLOSE
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Answer Image Modal */}
      {questions[currentQuestionIndex].answer_image && (
        <Modal
          show={showAnswerImageModal}
          onHide={handleCloseAnswerImageModal}
          size="lg" // Optional: 'sm', 'lg', 'xl'
        >
          <Modal.Header closeButton>
            <Modal.Title>Answer {currentQuestionIndex + 1} Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={modifyImageUrl(questions[currentQuestionIndex].answer_image)}
              className="img-fluid"
              style={{ maxWidth: "100%", height: "auto" }}
              alt=""
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn" onClick={handleCloseAnswerImageModal}>
              CLOSE
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default QuestionPageDesktop;
////////////////////////////////////////////////////////////////////////
