import axios from "axios";
export const getAllRollnumbers = () => async (dispatch) => {
  dispatch({ type: "GET_ROLLNUMERS_REQUEST" });

  try {
    const response = await axios.get(`/api/rollnumbers/getallrollnumbers`);
    console.log(response);
    dispatch({ type: "GET_ROLLNUMBERS_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({ type: "GET_ROLLNUMBERS_FAILED", payload: error });
  }
};

export const addRollnumber = (rollnumber) => async (dispatch) => {
  dispatch({ type: "ADD_ROLLNUMBER_REQUEST" });
  try {
    const response = await axios.post(`/api/rollnumbers/addrollnumber`, {
      rollnumber,
    });
    console.log(response);
    dispatch({ type: "ADD_ROLLNUMBER_SUCCESS" });
  } catch (error) {
    dispatch({ type: "ADD_ROLLNUMBER_FAILED", payload: error });
  }
};

export const getRollnumberById = (rollnumberid) => async (dispatch) => {
  dispatch({ type: "GET_PIZZABYID_REQUEST" });

  try {
    const response = await axios.post(`/api/rollnumbers/getrollnumberbyid`, {
      rollnumberid,
    });
    console.log(response);
    dispatch({ type: "GET_ROLLNUMBERBYID_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({ type: "GET_ROLLNUMBERBYID_FAILED", payload: error });
  }
};

export const deleteRollnumber = (rollnumberid) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/rollnumbers/deleterollnumber`, {
      rollnumberid,
    });
    alert("Rollnumber Deleted Successfully");
    console.log(response);
    //window.location.reload();
    window.location.href = "/adminpanel";
  } catch (error) {
    alert("Something went wrong");
    console.log(error);
  }
};
