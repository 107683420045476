import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function QuizResult() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    totalQuestions,
    correctAnswers,
    wrongAnswers,
    attemptedQuestions,
    division,
  } = location.state || {};

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container mt-5">
      <h2>Quiz Results</h2>
      <hr />
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="table-dark">
            <tr>
              <th>Total Questions</th>
              <th>Questions Attempted</th>
              <th>Correctly Answered Questions</th>
              <th>Wrongly Answered Questions</th>
              <th>Total Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{totalQuestions}</td>
              <td>{attemptedQuestions}</td>
              <td>{correctAnswers}</td>
              <td>{wrongAnswers}</td>
              <td>{correctAnswers}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button
        className="btn btn-primary mt-1"
        onClick={() =>
          navigate(
            division === "Bauddha Dakshata" ? "/bauddhadakshata" : "/udbodh"
          )
        }
      >
        Return to Quiz List
      </button>
      <div
        className="text-center mt-1"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <img
          width="250px"
          height="250px"
          src="student.gif"
          alt="Celebration GIF"
          className="img-fluid"
        />

        {!isMobile && (
          <>
            <img
              width="250px"
              height="250px"
              src="srch.gif"
              alt="Celebration GIF"
              className="img-fluid"
            />
            <img
              width="250px"
              height="250px"
              src="res-img.gif"
              alt="Celebration GIF"
              className="img-fluid"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default QuizResult;
