// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllQuizs } from "../actions/quizActions";
// import { getAllDifficultys } from "../actions/difficultyActions"; // Import the action to fetch difficulties
// import Quiz from "../components/Quiz";
// import Loading from "../components/Loading";
// import Error from "../components/Error";
// import Pagination from "../components/Pagination";

// const Udbogscreen = () => {
//   const dispatch = useDispatch();
//   const quizsState = useSelector((state) => state.getAllQuizsReducer);
//   const userLogin = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userLogin;
//   const { quizs, error, loading } = quizsState;

//   const difficultysState = useSelector(
//     (state) => state.getAllDifficultysReducer
//   );
//   const { difficultys } = difficultysState;

//   const [selectedDifficulty, setSelectedDifficulty] = useState("All");
//   const [selectedSubject, setSelectedSubject] = useState("All");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [quizzesPerPage] = useState(6);

//   useEffect(() => {
//     dispatch(getAllQuizs());
//     dispatch(getAllDifficultys()); // Fetch difficulties when component mounts
//   }, [dispatch]);

//   useEffect(() => {
//     setCurrentPage(1); // Reset currentPage to 1 whenever a filter is applied
//   }, [selectedDifficulty, selectedSubject]);

//   console.log("quizs : ", quizs);

//   const filteredQuizzes = quizs.filter(
//     (quiz) =>
//       quiz.enabled &&
//       (selectedDifficulty === "All" ||
//         quiz.difficulty === selectedDifficulty) &&
//       (selectedSubject === "All" || quiz.subject === selectedSubject) &&
//       quiz.classs === currentUser.classs &&
//       quiz.division === "Udbodh"
//   );

//   // Get unique subjects based on the selected difficulty and subject
//   const uniqueSubjects = [
//     ...new Set(filteredQuizzes.map((quiz) => quiz.subject)),
//   ];

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div className="container mt-4" style={{ paddingTop: "70px" }}>
//       <div className="row justify-content-center">
//         <div
//           className="col-md-12 d-flex justify-content-between mb-3"
//           style={{ backgroundColor: "black", padding: "10px" }}
//         >
//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Category:
//             </label>
//             <select
//               value={selectedDifficulty}
//               onChange={(e) => {
//                 setSelectedDifficulty(e.target.value);
//                 setSelectedSubject("All"); // Reset selectedSubject when difficulty changes
//               }}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               <option value="All">All</option>
//               {difficultys &&
//                 difficultys.map((diff) => (
//                   <option key={diff._id} value={diff.diff}>
//                     {diff.diff}
//                   </option>
//                 ))}
//             </select>
//           </div>
//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Subject:
//             </label>
//             <select
//               value={selectedSubject}
//               onChange={(e) => setSelectedSubject(e.target.value)}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               <option value="All">All</option>
//               {uniqueSubjects.map((subject) => (
//                 <option key={subject} value={subject}>
//                   {subject}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//         {loading ? (
//           <Loading />
//         ) : error ? (
//           <Error error="Something went wrong" />
//         ) : filteredQuizzes.length > 0 ? (
//           filteredQuizzes
//             .slice(
//               (currentPage - 1) * quizzesPerPage,
//               currentPage * quizzesPerPage
//             )
//             .map((quiz) => (
//               <div className="col-md-3 m-3" key={quiz._id.$oid}>
//                 <Quiz quiz={quiz} />
//               </div>
//             ))
//         ) : (
//           <div className="col-md-12 text-center mt-4">
//             <p className="text-danger">
//               No Quizzes Found for your class and selected filters.
//             </p>
//           </div>
//         )}
//         {filteredQuizzes.length > quizzesPerPage && (
//           <div className="col-md-12 mt-4">
//             <Pagination
//               quizzesPerPage={quizzesPerPage}
//               totalQuizzes={filteredQuizzes.length}
//               currentPage={currentPage}
//               paginate={paginate}
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Udbogscreen;
///////////////////////////////////////////////////////////////////////////////
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllQuizs } from "../actions/quizActions";
// import { getAllDifficultys } from "../actions/difficultyActions";
// import Quiz from "../components/Quiz";
// import Loading from "../components/Loading";
// import Error from "../components/Error";
// import Pagination from "../components/Pagination";
// import { Link } from "react-router-dom";

// function Udbodhscreen() {
//   const dispatch = useDispatch();
//   const quizsState = useSelector((state) => state.getAllQuizsReducer);
//   const userLogin = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userLogin;
//   const { quizs, error, loading } = quizsState;

//   const difficultysState = useSelector(
//     (state) => state.getAllDifficultysReducer
//   );
//   const { difficultys } = difficultysState;

//   const [selectedDifficulty, setSelectedDifficulty] = useState("All");
//   const [selectedSubject, setSelectedSubject] = useState("All");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [quizzesPerPage] = useState(6);

//   useEffect(() => {
//     dispatch(getAllQuizs());
//     dispatch(getAllDifficultys()); // Fetch difficulties when component mounts
//   }, [dispatch]);

//   useEffect(() => {
//     setCurrentPage(1); // Reset currentPage to 1 whenever a filter is applied
//   }, [selectedDifficulty, selectedSubject]);

//   const filteredQuizzes = quizs.filter(
//     (quiz) =>
//       quiz.enabled &&
//       (selectedDifficulty === "All" ||
//         quiz.difficulty === selectedDifficulty) &&
//       (selectedSubject === "All" || quiz.subject === selectedSubject) &&
//       quiz.classs === currentUser.classs &&
//       quiz.division === "Udbodh"
//   );

//   const filteredQuizzesBasedOnPaidStatus = filteredQuizzes.filter((quiz) => {
//     if (!currentUser.paid) {
//       return (
//         (selectedDifficulty === "All" && quiz.difficulty === "General Quiz") ||
//         quiz.difficulty === "General Quiz"
//       );
//     }
//     return true;
//   });

//   const uniqueSubjects = [
//     ...new Set(filteredQuizzesBasedOnPaidStatus.map((quiz) => quiz.subject)),
//   ];

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div className="container mt-4" style={{ paddingTop: "70px" }}>
//       <div className="row justify-content-center">
//         <div
//           className="col-md-12 d-flex justify-content-between mb-3"
//           style={{ backgroundColor: "black", padding: "10px" }}
//         >
//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Difficulty:
//             </label>
//             <select
//               value={selectedDifficulty}
//               onChange={(e) => {
//                 setSelectedDifficulty(e.target.value);
//                 setSelectedSubject("All");
//               }}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               <option value="All">All</option>
//               {difficultys &&
//                 difficultys.map((diff) => (
//                   <option key={diff._id} value={diff.diff}>
//                     {diff.diff}
//                   </option>
//                 ))}
//             </select>
//           </div>

//           <div>
//             <Link to="/dashboard">
//               <button
//                 className="btn btn-primary"
//                 style={{ backgroundColor: "red", color: "white" }}
//               >
//                 Dashboard
//               </button>
//             </Link>
//           </div>

//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Subject:
//             </label>
//             <select
//               value={selectedSubject}
//               onChange={(e) => setSelectedSubject(e.target.value)}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               <option value="All">All</option>
//               {uniqueSubjects.map((subject) => (
//                 <option key={subject} value={subject}>
//                   {subject}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//         {loading ? (
//           <Loading />
//         ) : error ? (
//           <Error error="Something went wrong" />
//         ) : filteredQuizzesBasedOnPaidStatus.length > 0 ? (
//           filteredQuizzesBasedOnPaidStatus
//             .slice(
//               (currentPage - 1) * quizzesPerPage,
//               currentPage * quizzesPerPage
//             )
//             .map((quiz) => (
//               <div className="col-md-3 m-3" key={quiz._id.$oid}>
//                 <Quiz quiz={quiz} userId={currentUser._id} />
//               </div>
//             ))
//         ) : (
//           <div className="col-md-12 text-center">
//             {!currentUser.paid &&
//             selectedDifficulty !== "All" &&
//             selectedDifficulty !== "General Quiz" ? (
//               <img
//                 src="PricingTableList.png" // replace with the path to your image
//                 alt="Not available for unpaid users"
//                 style={{ width: "70%", height: "auto" }}
//               />
//             ) : (
//               <p className="text-danger">
//                 No Quizzes Found for your class and selected filters.
//               </p>
//             )}
//           </div>
//         )}
//         {filteredQuizzesBasedOnPaidStatus.length > quizzesPerPage && (
//           <div className="col-md-12 mt-4">
//             <Pagination
//               quizzesPerPage={quizzesPerPage}
//               totalQuizzes={filteredQuizzesBasedOnPaidStatus.length}
//               currentPage={currentPage}
//               paginate={paginate}
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Udbodhscreen;
///////////////////////////////////////////////////////////////////////////////////////////
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllQuizs } from "../actions/quizActions";
// import { getAllDifficultys } from "../actions/difficultyActions";
// import Quiz from "../components/Quiz";
// import Loading from "../components/Loading";
// import Error from "../components/Error";
// import Pagination from "../components/Pagination";
// import { Link } from "react-router-dom";

// function Udbodhscreen() {
//   const dispatch = useDispatch();
//   const quizsState = useSelector((state) => state.getAllQuizsReducer);
//   const userLogin = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userLogin;
//   const { quizs, error, loading } = quizsState;

//   const difficultysState = useSelector(
//     (state) => state.getAllDifficultysReducer
//   );
//   const { difficultys } = difficultysState;

//   const [selectedDifficulty, setSelectedDifficulty] = useState("All");
//   const [selectedSubject, setSelectedSubject] = useState("All");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [quizzesPerPage] = useState(6);

//   useEffect(() => {
//     dispatch(getAllQuizs());
//     dispatch(getAllDifficultys()); // Fetch difficulties when component mounts
//   }, [dispatch]);

//   useEffect(() => {
//     setCurrentPage(1); // Reset currentPage to 1 whenever a filter is applied
//   }, [selectedDifficulty, selectedSubject]);

//   const filteredQuizzes = quizs.filter(
//     (quiz) =>
//       quiz.enabled &&
//       (selectedDifficulty === "All" ||
//         quiz.difficulty === selectedDifficulty) &&
//       (selectedSubject === "All" || quiz.subject === selectedSubject) &&
//       quiz.classs === currentUser.classs &&
//       quiz.division === "Udbodh"
//   );

//   const filteredQuizzesBasedOnPaidStatus = filteredQuizzes.filter((quiz) => {
//     if (!currentUser.paid) {
//       return (
//         (selectedDifficulty === "All" && quiz.difficulty === "General Quiz") ||
//         quiz.difficulty === "General Quiz"
//       );
//     }
//     return true;
//   });

//   const uniqueSubjects = [
//     ...new Set(filteredQuizzesBasedOnPaidStatus.map((quiz) => quiz.subject)),
//   ];

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div className="container mt-4" style={{ paddingTop: "70px" }}>
//       <div className="row justify-content-center">
//         <div
//           className="col-md-12 d-flex justify-content-between mb-3"
//           style={{ backgroundColor: "black", padding: "10px" }}
//         >
//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Category:
//             </label>
//             <select
//               value={selectedDifficulty}
//               onChange={(e) => {
//                 setSelectedDifficulty(e.target.value);
//                 setSelectedSubject("All");
//               }}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               <option value="All">All</option>
//               {difficultys &&
//                 difficultys.map((diff) => (
//                   <option key={diff._id} value={diff.diff}>
//                     {diff.diff}
//                   </option>
//                 ))}
//             </select>
//           </div>

//           <div>
//             <Link to="/dashboard">
//               <button
//                 className="btn btn-primary"
//                 style={{ backgroundColor: "red", color: "white" }}
//               >
//                 Dashboard
//               </button>
//             </Link>
//           </div>

//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Subject:
//             </label>
//             <select
//               value={selectedSubject}
//               onChange={(e) => setSelectedSubject(e.target.value)}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               <option value="All">All</option>
//               {uniqueSubjects.map((subject) => (
//                 <option key={subject} value={subject}>
//                   {subject}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//         {loading ? (
//           <Loading />
//         ) : error ? (
//           <Error error="Something went wrong" />
//         ) : filteredQuizzesBasedOnPaidStatus.length > 0 ? (
//           filteredQuizzesBasedOnPaidStatus
//             .slice(
//               (currentPage - 1) * quizzesPerPage,
//               currentPage * quizzesPerPage
//             )
//             .map((quiz) => (
//               <div className="col-md-3 m-3" key={quiz._id.$oid}>
//                 <Quiz
//                   quiz={quiz}
//                   userId={currentUser._id}
//                   userName={currentUser.name}
//                   userSchoolcode={currentUser.schoolcode}
//                   userClass={currentUser.classs}
//                   userSection={currentUser.section}
//                   attemptedQuizIds={currentUser.attemptedquizs.map(
//                     (q) => q._id
//                   )}
//                   completedQuizIds={currentUser.completedquizs.map(
//                     (q) => q._id
//                   )}
//                 />
//               </div>
//             ))
//         ) : (
//           <div className="col-md-12 text-center">
//             {!currentUser.paid &&
//             selectedDifficulty !== "All" &&
//             selectedDifficulty !== "General Quiz" ? (
//               <img
//                 src="PricingTableList.png" // replace with the path to your image
//                 alt="Not available for unpaid users"
//                 style={{ width: "70%", height: "auto" }}
//               />
//             ) : (
//               <p className="text-danger">
//                 No Quizzes Found for your class and selected filters.
//               </p>
//             )}
//           </div>
//         )}
//         {filteredQuizzesBasedOnPaidStatus.length > quizzesPerPage && (
//           <div className="col-md-12 mt-4">
//             <Pagination
//               quizzesPerPage={quizzesPerPage}
//               totalQuizzes={filteredQuizzesBasedOnPaidStatus.length}
//               currentPage={currentPage}
//               paginate={paginate}
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Udbodhscreen;
///////////////////////////////////////////////////////////////////////////////////////////
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllQuizs } from "../actions/quizActions";
// import { getAllDifficultys } from "../actions/difficultyActions";
// import Quiz from "../components/Quiz";
// import Loading from "../components/Loading";
// import Error from "../components/Error";
// import Pagination from "../components/Pagination";
// import { Link } from "react-router-dom";
// import axios from "axios"; // Import axios

// function Udbodhscreen() {
//   const dispatch = useDispatch();
//   const quizsState = useSelector((state) => state.getAllQuizsReducer);
//   const userLogin = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userLogin;
//   const { quizs, error, loading } = quizsState;

//   const difficultysState = useSelector(
//     (state) => state.getAllDifficultysReducer
//   );
//   const { difficultys } = difficultysState;

//   const [selectedDifficulty, setSelectedDifficulty] = useState("All");
//   const [selectedSubject, setSelectedSubject] = useState("All");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [quizzesPerPage] = useState(6);
//   const [userData, setUserData] = useState(null); // State to store fetched user data
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 767);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     dispatch(getAllQuizs());
//     dispatch(getAllDifficultys()); // Fetch difficulties when component mounts
//   }, [dispatch]);

//   useEffect(() => {
//     setCurrentPage(1); // Reset currentPage to 1 whenever a filter is applied
//   }, [selectedDifficulty, selectedSubject]);

//   // Fetch user data by ID
//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const { data } = await axios.get(
//           `/api/users/getuserbyid/${currentUser._id}`
//         );
//         setUserData(data);
//       } catch (error) {
//         console.error("Error fetching user data", error);
//       }
//     };

//     if (currentUser) {
//       fetchUserData();
//     }
//   }, []);

//   const filteredQuizzes = userData
//     ? quizs.filter(
//         (quiz) =>
//           (quiz.enabled.includes(userData.schoolcode) ||
//             quiz.difficulty === "General Quiz") && // Check if user's schoolcode is in quiz's enabled list
//           (selectedDifficulty === "All" ||
//             quiz.difficulty === selectedDifficulty) &&
//           (selectedSubject === "All" || quiz.subject === selectedSubject) &&
//           quiz.classs === userData.classs &&
//           quiz.division === "Udbodh"
//       )
//     : [];

//   const filteredQuizzesBasedOnPaidStatus = filteredQuizzes.filter((quiz) => {
//     if (userData && !userData.paid) {
//       return (
//         (selectedDifficulty === "All" && quiz.difficulty === "General Quiz") ||
//         quiz.difficulty === "General Quiz"
//       );
//     }
//     return true;
//   });

//   const uniqueSubjects = [
//     ...new Set(filteredQuizzesBasedOnPaidStatus.map((quiz) => quiz.subject)),
//   ];

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div className="container mt-4" style={{ paddingTop: "70px" }}>
//       <div className="row justify-content-center">
//         <div
//           className="col-md-12 d-flex justify-content-between mb-3"
//           style={{ backgroundColor: "black", padding: "10px" }}
//         >
//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Category:
//             </label>
//             <select
//               value={selectedDifficulty}
//               onChange={(e) => {
//                 setSelectedDifficulty(e.target.value);
//                 setSelectedSubject("All");
//               }}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               <option value="All">All</option>
//               {difficultys &&
//                 difficultys.map((diff) => (
//                   <option key={diff._id} value={diff.diff}>
//                     {diff.diff}
//                   </option>
//                 ))}
//             </select>
//           </div>

//           {!isMobile ? (
//             <div>
//               <Link to={currentUser.paid ? "/dashboard" : "/udbodh"}>
//                 <button
//                   className="btn btn-primary"
//                   style={{ backgroundColor: "red", color: "white" }}
//                   disabled={!currentUser.paid}
//                 >
//                   Dashboard
//                 </button>
//               </Link>
//             </div>
//           ) : (
//             <></>
//           )}

//           <div>
//             <label style={{ marginRight: "10px", color: "white" }}>
//               Filter by Subject:
//             </label>
//             <select
//               value={selectedSubject}
//               onChange={(e) => setSelectedSubject(e.target.value)}
//               className="form-control"
//               style={{
//                 width: "auto",
//                 display: "inline-block",
//                 backgroundColor: "red",
//                 color: "white",
//               }}
//             >
//               <option value="All">All</option>
//               {uniqueSubjects.map((subject) => (
//                 <option key={subject} value={subject}>
//                   {subject}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//         {loading ? (
//           <Loading />
//         ) : error ? (
//           <Error error="Something went wrong" />
//         ) : filteredQuizzesBasedOnPaidStatus.length > 0 ? (
//           filteredQuizzesBasedOnPaidStatus
//             .slice(
//               (currentPage - 1) * quizzesPerPage,
//               currentPage * quizzesPerPage
//             )
//             .map((quiz) => (
//               <div className="col-md-3 m-3" key={quiz._id.$oid}>
//                 <Quiz
//                   quiz={quiz}
//                   userId={userData._id}
//                   userName={userData.name}
//                   userSchoolcode={userData.schoolcode}
//                   userClass={userData.classs}
//                   userSection={userData.section}
//                   attemptedQuizIds={userData.attemptedquizs.map((q) => q._id)}
//                   completedQuizIds={userData.completedquizs.map((q) => q._id)}
//                 />
//               </div>
//             ))
//         ) : (
//           <div className="col-md-12 text-center">
//             {!userData?.paid &&
//             selectedDifficulty !== "All" &&
//             selectedDifficulty !== "General Quiz" ? (
//               <img
//                 src="PricingTableList.png" // replace with the path to your image
//                 alt="Not available for unpaid users"
//                 style={{ width: "70%", height: "auto" }}
//               />
//             ) : (
//               <p className="text-danger">
//                 No Quizzes Found for your class and selected filters.
//               </p>
//             )}
//           </div>
//         )}
//         {filteredQuizzesBasedOnPaidStatus.length > quizzesPerPage && (
//           <div className="col-md-12 mt-4">
//             <Pagination
//               quizzesPerPage={quizzesPerPage}
//               totalQuizzes={filteredQuizzesBasedOnPaidStatus.length}
//               currentPage={currentPage}
//               paginate={paginate}
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Udbodhscreen;
/////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuizs } from "../actions/quizActions";
import { getAllDifficultys } from "../actions/difficultyActions";
import Quiz from "../components/Quiz";
import Loading from "../components/Loading";
import Error from "../components/Error";
import Pagination from "../components/Pagination";
import { Link } from "react-router-dom";
import axios from "axios"; // Import axios
import Paymentplansscreen from "./Paymentplansscreen";

function Udbodhscreen() {
  const dispatch = useDispatch();
  const quizsState = useSelector((state) => state.getAllQuizsReducer);
  const userLogin = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userLogin;
  const { quizs, error, loading } = quizsState;

  const difficultysState = useSelector(
    (state) => state.getAllDifficultysReducer
  );
  const { difficultys } = difficultysState;

  const [selectedDifficulty, setSelectedDifficulty] = useState("All");
  const [selectedSubject, setSelectedSubject] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [quizzesPerPage] = useState(6);
  const [userData, setUserData] = useState(null); // State to store fetched user data
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getAllQuizs());
    dispatch(getAllDifficultys()); // Fetch difficulties when component mounts
  }, [dispatch]);

  useEffect(() => {
    setCurrentPage(1); // Reset currentPage to 1 whenever a filter is applied
  }, [selectedDifficulty, selectedSubject]);

  // Fetch user data by ID
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await axios.get(
          `/api/users/getuserbyid/${currentUser._id}`
        );
        setUserData(data);
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    if (currentUser) {
      fetchUserData();
    }
  }, []);

  const filteredQuizzes = userData
    ? quizs.filter(
        (quiz) =>
          (quiz.enabled.includes(userData.schoolcode) ||
            quiz.enabled.includes(userData.email) ||
            quiz.difficulty === "General Quiz") && // Check if user's schoolcode is in quiz's enabled list
          (selectedDifficulty === "All" ||
            quiz.difficulty === selectedDifficulty) &&
          (selectedSubject === "All" || quiz.subject === selectedSubject) &&
          (quiz.classs === userData.classs ||
            quiz.enabled.includes(userData.email)) &&
          quiz.division === "Udbodh"
      )
    : [];

  const filteredQuizzesBasedOnPaidStatus = filteredQuizzes.filter((quiz) => {
    if (userData && !userData.paid) {
      return (
        (selectedDifficulty === "All" && quiz.difficulty === "General Quiz") ||
        quiz.difficulty === "General Quiz"
      );
    }
    return true;
  });

  const uniqueSubjects = [
    ...new Set(filteredQuizzesBasedOnPaidStatus.map((quiz) => quiz.subject)),
  ];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mt-4" style={{ paddingTop: "70px" }}>
      <div className="row justify-content-center">
        <div
          className="col-md-12 d-flex justify-content-between mb-3"
          style={{ backgroundColor: "black", padding: "10px" }}
        >
          <div>
            <label style={{ marginRight: "10px", color: "white" }}>
              Filter by Category:
            </label>
            <select
              value={selectedDifficulty}
              onChange={(e) => {
                setSelectedDifficulty(e.target.value);
                setSelectedSubject("All");
              }}
              className="form-control"
              style={{
                width: "auto",
                display: "inline-block",
                backgroundColor: "red",
                color: "white",
              }}
            >
              <option value="All">All</option>
              {difficultys &&
                difficultys.map((diff) => (
                  <option key={diff._id} value={diff.diff}>
                    {diff.diff}
                  </option>
                ))}
            </select>
          </div>

          {!isMobile ? (
            <div>
              <Link to={currentUser.paid ? "/dashboard" : "/udbodh"}>
                <button
                  className="btn btn-primary"
                  style={{ backgroundColor: "red", color: "white" }}
                  disabled={!currentUser.paid}
                >
                  Dashboard
                </button>
              </Link>
            </div>
          ) : (
            <></>
          )}

          <div>
            <label style={{ marginRight: "10px", color: "white" }}>
              Filter by Subject:
            </label>
            <select
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              className="form-control"
              style={{
                width: "auto",
                display: "inline-block",
                backgroundColor: "red",
                color: "white",
              }}
            >
              <option value="All">All</option>
              {uniqueSubjects.map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : error ? (
          <Error error="Something went wrong" />
        ) : filteredQuizzesBasedOnPaidStatus.length > 0 ? (
          filteredQuizzesBasedOnPaidStatus
            .reverse()
            .slice(
              (currentPage - 1) * quizzesPerPage,
              currentPage * quizzesPerPage
            )
            .map((quiz) => (
              <div className="col-md-3 m-3" key={quiz._id.$oid}>
                <Quiz
                  quiz={quiz}
                  userId={userData._id}
                  userName={userData.name}
                  userSchoolcode={userData.schoolcode}
                  userClass={userData.classs}
                  userSection={userData.section}
                  attemptedQuizIds={userData.attemptedquizs.map((q) => q._id)}
                  completedQuizIds={userData.completedquizs.map((q) => q._id)}
                />
              </div>
            ))
        ) : (
          <div className="col-md-12 text-center">
            {!userData?.paid &&
            selectedDifficulty !== "All" &&
            selectedDifficulty !== "General Quiz" ? (
              // <img
              //   src="PricingTableList.png" // replace with the path to your image
              //   alt="Not available for unpaid users"
              //   style={{ width: "70%", height: "auto" }}
              // />
              <Paymentplansscreen />
            ) : (
              <p className="text-danger">
                No Quizzes Found for your class and selected filters.
              </p>
            )}
          </div>
        )}
        {filteredQuizzesBasedOnPaidStatus.length > quizzesPerPage && (
          <div className="col-md-12 mt-4">
            <Pagination
              quizzesPerPage={quizzesPerPage}
              totalQuizzes={filteredQuizzesBasedOnPaidStatus.length}
              currentPage={currentPage}
              paginate={paginate}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Udbodhscreen;
