// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import { getAllSubjects } from "../actions/subjectActions";
// import { getAllDifficultys } from "../actions/difficultyActions";
// import { getAllClassss } from "../actions/classsActions";

// const AddQuiz = () => {
//   const dispatch = useDispatch();
//   const [name, setName] = useState("");
//   const [difficulty, setDifficulty] = useState("");
//   const [classs, setClasss] = useState("");
//   const [division, setDivision] = useState("");
//   const [subject, setSubject] = useState("");
//   const [file, setFile] = useState(null);
//   const [questions, setQuestions] = useState([]);
//   const [submitSuccess, setSubmitSuccess] = useState(false);

//   const subjectsState = useSelector((state) => state.getAllSubjectsReducer);
//   const {
//     subjects,
//     loading: subjectsLoading,
//     error: subjectsError,
//   } = subjectsState;

//   const difficultysState = useSelector(
//     (state) => state.getAllDifficultysReducer
//   );
//   const {
//     difficultys,
//     loading: difficultysLoading,
//     error: difficultysError,
//   } = difficultysState;

//   const classssState = useSelector((state) => state.getAllClassssReducer);
//   const {
//     classss,
//     loading: classssLoading,
//     error: classssError,
//   } = classssState;

//   useEffect(() => {
//     dispatch(getAllSubjects());
//     dispatch(getAllDifficultys());
//     dispatch(getAllClassss());
//   }, [dispatch]);

//   const addQuestion = () => {
//     setQuestions([
//       ...questions,
//       {
//         id: questions.length + 1,
//         question: "",
//         question_in_hindi: "",
//         question_image: null,
//         options: ["", "", "", ""],
//         correct_answer: "",
//         answer_explanation_in_english: "",
//         answer_explanation_in_hindi: "",
//         answer_image: null,
//       },
//     ]);
//   };

//   const handleQuestionChange = (index, field, value) => {
//     const updatedQuestions = [...questions];
//     updatedQuestions[index][field] = value;
//     setQuestions(updatedQuestions);
//   };

//   const handleOptionChange = (questionIndex, optionIndex, value) => {
//     const updatedQuestions = [...questions];
//     updatedQuestions[questionIndex].options[optionIndex] = value;
//     setQuestions(updatedQuestions);
//   };

//   const handleFileChange = (index, field, file) => {
//     const updatedQuestions = [...questions];
//     updatedQuestions[index][field] = file;
//     setQuestions(updatedQuestions);
//   };

//   const submitHandler = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append("name", name);
//     formData.append("difficulty", difficulty);
//     formData.append("classs", classs);
//     formData.append("division", division);
//     formData.append("subject", subject);
//     if (file) {
//       formData.append("quiz_image", file);
//     }
//     formData.append(
//       "questions",
//       JSON.stringify(
//         questions.map((q) => {
//           const question_image = q.question_image
//             ? `${q.id}_question_image`
//             : null;
//           const answer_image = q.answer_image ? `${q.id}_answer_image` : null;
//           return { ...q, question_image, answer_image };
//         })
//       )
//     );
//     questions.forEach((q, index) => {
//       if (q.question_image) {
//         formData.append(
//           "questions_images",
//           q.question_image,
//           `${q.id}_question_image`
//         );
//       }
//       if (q.answer_image) {
//         formData.append(
//           "questions_images",
//           q.answer_image,
//           `${q.id}_answer_image`
//         );
//       }
//     });

//     try {
//       const response = await axios.post("/api/quizs/addquiz", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       console.log(response);
//       setSubmitSuccess(true);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div className="container">
//       <hr></hr>
//       <h1>Add New Quiz</h1>
//       <hr></hr>
//       <form onSubmit={submitHandler} encType="multipart/form-data">
//         <div className="form-group">
//           <label>Name</label>
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Enter quiz name"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             style={{ border: "1px solid black", marginBottom: "10px" }}
//           />
//         </div>
//         <div className="form-group">
//           <label>Category</label>
//           <select
//             className="form-control"
//             value={difficulty}
//             onChange={(e) => setDifficulty(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Category</option>
//             {difficultys &&
//               difficultys.map((diff) => (
//                 <option key={diff._id} value={diff.diff}>
//                   {diff.diff}
//                 </option>
//               ))}
//           </select>
//         </div>

//         <div className="form-group">
//           <label>Class</label>
//           <select
//             className="form-control"
//             value={classs}
//             onChange={(e) => setClasss(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Class</option>
//             {classss &&
//               classss.map((cls) => (
//                 <option key={cls._id} value={cls.cls}>
//                   {cls.cls}
//                 </option>
//               ))}
//           </select>
//         </div>

//         <div className="form-group">
//           <label>Division</label>
//           <select
//             className="form-control"
//             value={division}
//             onChange={(e) => setDivision(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Division</option>

//             <option value="Udbodh">Udbodh</option>
//             <option value="Bauddha Dakshata">Bauddha Dakshata</option>
//           </select>
//         </div>

//         <div className="form-group">
//           <label>Subject</label>
//           <select
//             className="form-control"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Subject</option>
//             {subjects &&
//               subjects.map((sub) => (
//                 <option key={sub._id} value={sub.sub}>
//                   {sub.sub}
//                 </option>
//               ))}
//           </select>
//         </div>
//         <div className="form-group pd-5">
//           <label>Upload Quiz Image</label>
//           <input
//             type="file"
//             className="form-control-file"
//             name="quiz_image"
//             onChange={(e) => setFile(e.target.files[0])}
//             style={{ marginBottom: "30px" }}
//           />
//         </div>
//         {questions.map((question, index) => (
//           <div key={index} className="mb-3">
//             <label>Question {index + 1}</label>
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter question ${index + 1}`}
//               value={question.question || ""}
//               onChange={(e) =>
//                 handleQuestionChange(index, "question", e.target.value)
//               }
//             />
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter question ${index + 1} in Hindi`}
//               value={question.question_in_hindi || ""}
//               onChange={(e) =>
//                 handleQuestionChange(index, "question_in_hindi", e.target.value)
//               }
//             />
//             <label>Options</label>
//             {question.options.map((option, optionIndex) => (
//               <input
//                 key={optionIndex}
//                 type="text"
//                 className="form-control mb-2"
//                 placeholder={`Enter option ${optionIndex + 1}`}
//                 value={option || ""}
//                 onChange={(e) =>
//                   handleOptionChange(index, optionIndex, e.target.value)
//                 }
//               />
//             ))}
//             <div className="form-group">
//               <label>Correct Answer</label>
//               <select
//                 className="form-control"
//                 value={question.correct_answer || ""}
//                 onChange={(e) =>
//                   handleQuestionChange(index, "correct_answer", e.target.value)
//                 }
//                 onBlur={(e) =>
//                   handleQuestionChange(index, "correct_answer", e.target.value)
//                 }
//               >
//                 <option key="" value="">
//                   Select Correct Option
//                 </option>
//                 {question.options.map((option, optionIndex) => (
//                   <option key={optionIndex} value={option}>
//                     {option}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="form-group">
//               <label>Upload Question Image</label>
//               <input
//                 type="file"
//                 className="form-control-file"
//                 name="question_image"
//                 onChange={(e) =>
//                   handleFileChange(index, "question_image", e.target.files[0])
//                 }
//               />
//             </div>
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter answer explanation in English`}
//               value={question.answer_explanation_in_english || ""}
//               onChange={(e) =>
//                 handleQuestionChange(
//                   index,
//                   "answer_explanation_in_english",
//                   e.target.value
//                 )
//               }
//             />
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter answer explanation in Hindi`}
//               value={question.answer_explanation_in_hindi || ""}
//               onChange={(e) =>
//                 handleQuestionChange(
//                   index,
//                   "answer_explanation_in_hindi",
//                   e.target.value
//                 )
//               }
//             />
//             <div className="form-group">
//               <label>Upload Answer Image</label>
//               <input
//                 type="file"
//                 className="form-control-file"
//                 name="answer_image"
//                 onChange={(e) =>
//                   handleFileChange(index, "answer_image", e.target.files[0])
//                 }
//               />
//             </div>
//           </div>
//         ))}
//         {submitSuccess && (
//           <div className="alert alert-success" role="alert">
//             Quiz submitted successfully!
//           </div>
//         )}
//         <button
//           type="button"
//           className="btn btn-primary mb-3 mx-5"
//           onClick={addQuestion}
//         >
//           Add Question
//         </button>
//         <button
//           type="submit"
//           className="btn btn-primary"
//           style={{ marginBottom: "20px" }}
//         >
//           Submit
//         </button>
//       </form>
//       {/* {submitSuccess && (
//         <div className="alert alert-success" role="alert">
//           Quiz submitted successfully!
//         </div>
//       )} */}
//     </div>
//   );
// };

// export default AddQuiz;
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import { getAllSubjects } from "../actions/subjectActions";
// import { getAllDifficultys } from "../actions/difficultyActions";
// import { getAllClassss } from "../actions/classsActions";

// const AddQuiz = () => {
//   const dispatch = useDispatch();
//   const [name, setName] = useState("");
//   const [difficulty, setDifficulty] = useState("");
//   const [classs, setClasss] = useState("");
//   const [division, setDivision] = useState("");
//   const [subject, setSubject] = useState("");
//   const [file, setFile] = useState(null);
//   const [questions, setQuestions] = useState([]);
//   const [submitSuccess, setSubmitSuccess] = useState(false);

//   const subjectsState = useSelector((state) => state.getAllSubjectsReducer);
//   const {
//     subjects,
//     loading: subjectsLoading,
//     error: subjectsError,
//   } = subjectsState;

//   const difficultysState = useSelector(
//     (state) => state.getAllDifficultysReducer
//   );
//   const {
//     difficultys,
//     loading: difficultysLoading,
//     error: difficultysError,
//   } = difficultysState;

//   const classssState = useSelector((state) => state.getAllClassssReducer);
//   const {
//     classss,
//     loading: classssLoading,
//     error: classssError,
//   } = classssState;

//   useEffect(() => {
//     dispatch(getAllSubjects());
//     dispatch(getAllDifficultys());
//     dispatch(getAllClassss());
//   }, [dispatch]);

//   const addQuestion = () => {
//     setQuestions([
//       ...questions,
//       {
//         id: questions.length + 1,
//         question: "",
//         question_in_hindi: "",
//         question_image: null,
//         options: ["", "", "", ""],
//         correct_answer: "",
//         answer_explanation_in_english: "",
//         answer_explanation_in_hindi: "",
//         answer_image: null,
//       },
//     ]);
//   };

//   const removeQuestion = (index) => {
//     setQuestions(questions.filter((_, i) => i !== index));
//   };

//   const handleQuestionChange = (index, field, value) => {
//     const updatedQuestions = [...questions];
//     updatedQuestions[index][field] = value;
//     setQuestions(updatedQuestions);
//   };

//   const handleOptionChange = (questionIndex, optionIndex, value) => {
//     const updatedQuestions = [...questions];
//     updatedQuestions[questionIndex].options[optionIndex] = value;
//     setQuestions(updatedQuestions);
//   };

//   const handleFileChange = (index, field, file) => {
//     const updatedQuestions = [...questions];
//     updatedQuestions[index][field] = file;
//     setQuestions(updatedQuestions);
//   };

//   const submitHandler = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append("name", name);
//     formData.append("difficulty", difficulty);
//     formData.append("classs", classs);
//     formData.append("division", division);
//     formData.append("subject", subject);
//     if (file) {
//       formData.append("quiz_image", file);
//     }
//     formData.append(
//       "questions",
//       JSON.stringify(
//         questions.map((q) => {
//           const question_image = q.question_image
//             ? `${q.id}_question_image`
//             : null;
//           const answer_image = q.answer_image ? `${q.id}_answer_image` : null;
//           return { ...q, question_image, answer_image };
//         })
//       )
//     );
//     questions.forEach((q, index) => {
//       if (q.question_image) {
//         formData.append(
//           "questions_images",
//           q.question_image,
//           `${q.id}_question_image`
//         );
//       }
//       if (q.answer_image) {
//         formData.append(
//           "questions_images",
//           q.answer_image,
//           `${q.id}_answer_image`
//         );
//       }
//     });

//     try {
//       const response = await axios.post("/api/quizs/addquiz", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       console.log(response);
//       setSubmitSuccess(true);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div className="container">
//       <hr></hr>
//       <h1>Add New Quiz</h1>
//       <hr></hr>
//       <form onSubmit={submitHandler} encType="multipart/form-data">
//         <div className="form-group">
//           <label>Name</label>
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Enter quiz name"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             style={{ border: "1px solid black", marginBottom: "10px" }}
//           />
//         </div>
//         <div className="form-group">
//           <label>Category</label>
//           <select
//             className="form-control"
//             value={difficulty}
//             onChange={(e) => setDifficulty(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Category</option>
//             {difficultys &&
//               difficultys.map((diff) => (
//                 <option key={diff._id} value={diff.diff}>
//                   {diff.diff}
//                 </option>
//               ))}
//           </select>
//         </div>

//         <div className="form-group">
//           <label>Class</label>
//           <select
//             className="form-control"
//             value={classs}
//             onChange={(e) => setClasss(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Class</option>
//             {classss &&
//               classss.map((cls) => (
//                 <option key={cls._id} value={cls.cls}>
//                   {cls.cls}
//                 </option>
//               ))}
//           </select>
//         </div>

//         <div className="form-group">
//           <label>Division</label>
//           <select
//             className="form-control"
//             value={division}
//             onChange={(e) => setDivision(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Division</option>

//             <option value="Udbodh">Udbodh</option>
//             <option value="Bauddha Dakshata">Bauddha Dakshata</option>
//           </select>
//         </div>

//         <div className="form-group">
//           <label>Subject</label>
//           <select
//             className="form-control"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Subject</option>
//             {subjects &&
//               subjects.map((sub) => (
//                 <option key={sub._id} value={sub.sub}>
//                   {sub.sub}
//                 </option>
//               ))}
//           </select>
//         </div>
//         <div className="form-group pd-5">
//           <label>Upload Quiz Image</label>
//           <input
//             type="file"
//             className="form-control-file"
//             name="quiz_image"
//             onChange={(e) => setFile(e.target.files[0])}
//             style={{ marginBottom: "30px" }}
//           />
//         </div>
//         {questions.map((question, index) => (
//           <div key={index} className="mb-3">
//             <label>Question {index + 1}</label>
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter question ${index + 1}`}
//               value={question.question || ""}
//               onChange={(e) =>
//                 handleQuestionChange(index, "question", e.target.value)
//               }
//             />
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter question ${index + 1} in Hindi`}
//               value={question.question_in_hindi || ""}
//               onChange={(e) =>
//                 handleQuestionChange(index, "question_in_hindi", e.target.value)
//               }
//             />
//             <label>Options</label>
//             {question.options.map((option, optionIndex) => (
//               <input
//                 key={optionIndex}
//                 type="text"
//                 className="form-control mb-2"
//                 placeholder={`Enter option ${optionIndex + 1}`}
//                 value={option || ""}
//                 onChange={(e) =>
//                   handleOptionChange(index, optionIndex, e.target.value)
//                 }
//               />
//             ))}
//             <div className="form-group">
//               <label>Correct Answer</label>
//               <select
//                 className="form-control"
//                 value={question.correct_answer || ""}
//                 onChange={(e) =>
//                   handleQuestionChange(index, "correct_answer", e.target.value)
//                 }
//                 onBlur={(e) =>
//                   handleQuestionChange(index, "correct_answer", e.target.value)
//                 }
//               >
//                 <option key="" value="">
//                   Select Correct Option
//                 </option>
//                 {question.options.map((option, optionIndex) => (
//                   <option key={optionIndex} value={option}>
//                     {option}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="form-group">
//               <label>Upload Question Image</label>
//               <input
//                 type="file"
//                 className="form-control-file"
//                 name="question_image"
//                 onChange={(e) =>
//                   handleFileChange(index, "question_image", e.target.files[0])
//                 }
//               />
//             </div>
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter answer explanation in English`}
//               value={question.answer_explanation_in_english || ""}
//               onChange={(e) =>
//                 handleQuestionChange(
//                   index,
//                   "answer_explanation_in_english",
//                   e.target.value
//                 )
//               }
//             />
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter answer explanation in Hindi`}
//               value={question.answer_explanation_in_hindi || ""}
//               onChange={(e) =>
//                 handleQuestionChange(
//                   index,
//                   "answer_explanation_in_hindi",
//                   e.target.value
//                 )
//               }
//             />
//             <div className="form-group">
//               <label>Upload Answer Image</label>
//               <input
//                 type="file"
//                 className="form-control-file"
//                 name="answer_image"
//                 onChange={(e) =>
//                   handleFileChange(index, "answer_image", e.target.files[0])
//                 }
//               />
//             </div>
//             <button
//               type="button"
//               className="btn btn-danger"
//               onClick={() => removeQuestion(index)}
//               style={{ marginTop: "10px" }}
//             >
//               Remove Question
//             </button>
//           </div>
//         ))}
//         {submitSuccess && (
//           <div className="alert alert-success" role="alert">
//             Quiz submitted successfully!
//           </div>
//         )}
//         <button
//           type="button"
//           className="btn btn-primary mb-3 mx-5"
//           onClick={addQuestion}
//         >
//           Add Question
//         </button>
//         <button
//           type="submit"
//           className="btn btn-primary"
//           style={{ marginBottom: "20px" }}
//         >
//           Submit
//         </button>
//       </form>
//     </div>
//   );
// };

// export default AddQuiz;
///////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import { getAllSubjects } from "../actions/subjectActions";
// import { getAllDifficultys } from "../actions/difficultyActions";
// import { getAllClassss } from "../actions/classsActions";

// const AddQuiz = () => {
//   const dispatch = useDispatch();
//   const [name, setName] = useState("");
//   const [difficulty, setDifficulty] = useState("");
//   const [classs, setClasss] = useState("");
//   const [division, setDivision] = useState("");
//   const [subject, setSubject] = useState("");
//   const [file, setFile] = useState(null);
//   const [questions, setQuestions] = useState([]);
//   const [submitSuccess, setSubmitSuccess] = useState(false);

//   const subjectsState = useSelector((state) => state.getAllSubjectsReducer);
//   const {
//     subjects,
//     loading: subjectsLoading,
//     error: subjectsError,
//   } = subjectsState;

//   const difficultysState = useSelector(
//     (state) => state.getAllDifficultysReducer
//   );
//   const {
//     difficultys,
//     loading: difficultysLoading,
//     error: difficultysError,
//   } = difficultysState;

//   const classssState = useSelector((state) => state.getAllClassssReducer);
//   const {
//     classss,
//     loading: classssLoading,
//     error: classssError,
//   } = classssState;

//   useEffect(() => {
//     dispatch(getAllSubjects());
//     dispatch(getAllDifficultys());
//     dispatch(getAllClassss());
//   }, [dispatch]);

//   const addQuestion = () => {
//     setQuestions([
//       ...questions,
//       {
//         id: questions.length + 1,
//         question: "",
//         question_in_hindi: "",
//         question_image: null,
//         options: ["", "", "", ""],
//         correct_answer: "",
//         answer_explanation_in_english: "",
//         answer_explanation_in_hindi: "",
//         answer_image: null,
//       },
//     ]);
//   };

//   const removeQuestion = (index) => {
//     setQuestions(questions.filter((_, i) => i !== index));
//   };

//   const handleQuestionChange = (index, field, value) => {
//     const updatedQuestions = [...questions];
//     updatedQuestions[index][field] = value;
//     setQuestions(updatedQuestions);
//   };

//   const handleOptionChange = (questionIndex, optionIndex, value) => {
//     const updatedQuestions = [...questions];
//     updatedQuestions[questionIndex].options[optionIndex] = value;
//     setQuestions(updatedQuestions);
//   };

//   const handleFileChange = (index, field, file) => {
//     const updatedQuestions = [...questions];
//     updatedQuestions[index][field] = file;
//     setQuestions(updatedQuestions);
//   };

//   const saveToLocalStorage = () => {
//     const data = {
//       name,
//       difficulty,
//       classs,
//       division,
//       subject,
//       questions,
//     };
//     localStorage.setItem("quizData", JSON.stringify(data));
//     alert("Data saved to local storage!");
//   };

//   const restoreFromLocalStorage = () => {
//     const data = JSON.parse(localStorage.getItem("quizData"));
//     if (data) {
//       setName(data.name);
//       setDifficulty(data.difficulty);
//       setClasss(data.classs);
//       setDivision(data.division);
//       setSubject(data.subject);
//       setQuestions(data.questions);
//       alert("Data restored from local storage!");
//     } else {
//       alert("No data found in local storage!");
//     }
//   };

//   const submitHandler = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append("name", name);
//     formData.append("difficulty", difficulty);
//     formData.append("classs", classs);
//     formData.append("division", division);
//     formData.append("subject", subject);
//     if (file) {
//       formData.append("quiz_image", file);
//     }
//     formData.append(
//       "questions",
//       JSON.stringify(
//         questions.map((q) => {
//           const question_image = q.question_image
//             ? `${q.id}_question_image`
//             : null;
//           const answer_image = q.answer_image ? `${q.id}_answer_image` : null;
//           return { ...q, question_image, answer_image };
//         })
//       )
//     );
//     questions.forEach((q, index) => {
//       if (q.question_image) {
//         formData.append(
//           "questions_images",
//           q.question_image,
//           `${q.id}_question_image`
//         );
//       }
//       if (q.answer_image) {
//         formData.append(
//           "questions_images",
//           q.answer_image,
//           `${q.id}_answer_image`
//         );
//       }
//     });

//     try {
//       const response = await axios.post("/api/quizs/addquiz", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       console.log(response);
//       setSubmitSuccess(true);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div className="container">
//       <hr></hr>
//       <h1>Add New Quiz</h1>
//       <hr></hr>
//       <form onSubmit={submitHandler} encType="multipart/form-data">
//         <div className="form-group">
//           <label>Name</label>
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Enter quiz name"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             style={{ border: "1px solid black", marginBottom: "10px" }}
//           />
//         </div>
//         <div className="form-group">
//           <label>Category</label>
//           <select
//             className="form-control"
//             value={difficulty}
//             onChange={(e) => setDifficulty(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Category</option>
//             {difficultys &&
//               difficultys.map((diff) => (
//                 <option key={diff._id} value={diff.diff}>
//                   {diff.diff}
//                 </option>
//               ))}
//           </select>
//         </div>

//         <div className="form-group">
//           <label>Class</label>
//           <select
//             className="form-control"
//             value={classs}
//             onChange={(e) => setClasss(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Class</option>
//             {classss &&
//               classss.map((cls) => (
//                 <option key={cls._id} value={cls.cls}>
//                   {cls.cls}
//                 </option>
//               ))}
//           </select>
//         </div>

//         <div className="form-group">
//           <label>Division</label>
//           <select
//             className="form-control"
//             value={division}
//             onChange={(e) => setDivision(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Division</option>

//             <option value="Udbodh">Udbodh</option>
//             <option value="Bauddha Dakshata">Bauddha Dakshata</option>
//           </select>
//         </div>

//         <div className="form-group">
//           <label>Subject</label>
//           <select
//             className="form-control"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//             style={{ marginBottom: "10px" }}
//           >
//             <option value="">Select Subject</option>
//             {subjects &&
//               subjects.map((sub) => (
//                 <option key={sub._id} value={sub.sub}>
//                   {sub.sub}
//                 </option>
//               ))}
//           </select>
//         </div>
//         <div className="form-group pd-5">
//           <label>Upload Quiz Image</label>
//           <input
//             type="file"
//             className="form-control-file"
//             name="quiz_image"
//             onChange={(e) => setFile(e.target.files[0])}
//             style={{ marginBottom: "30px" }}
//           />
//         </div>
//         {questions.map((question, index) => (
//           <div key={index} className="mb-3">
//             <label>Question {index + 1}</label>
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter question ${index + 1}`}
//               value={question.question || ""}
//               onChange={(e) =>
//                 handleQuestionChange(index, "question", e.target.value)
//               }
//             />
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter question ${index + 1} in Hindi`}
//               value={question.question_in_hindi || ""}
//               onChange={(e) =>
//                 handleQuestionChange(index, "question_in_hindi", e.target.value)
//               }
//             />
//             <label>Options</label>
//             {question.options.map((option, optionIndex) => (
//               <input
//                 key={optionIndex}
//                 type="text"
//                 className="form-control mb-2"
//                 placeholder={`Enter option ${optionIndex + 1}`}
//                 value={option || ""}
//                 onChange={(e) =>
//                   handleOptionChange(index, optionIndex, e.target.value)
//                 }
//               />
//             ))}
//             <div className="form-group">
//               <label>Correct Answer</label>
//               <select
//                 className="form-control"
//                 value={question.correct_answer || ""}
//                 onChange={(e) =>
//                   handleQuestionChange(index, "correct_answer", e.target.value)
//                 }
//                 onBlur={(e) =>
//                   handleQuestionChange(index, "correct_answer", e.target.value)
//                 }
//               >
//                 <option key="" value="">
//                   Select Correct Option
//                 </option>
//                 {question.options.map((option, optionIndex) => (
//                   <option key={optionIndex} value={option}>
//                     {option}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="form-group">
//               <label>Upload Question Image</label>
//               <input
//                 type="file"
//                 className="form-control-file"
//                 name="question_image"
//                 onChange={(e) =>
//                   handleFileChange(index, "question_image", e.target.files[0])
//                 }
//               />
//             </div>
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter answer explanation in English`}
//               value={question.answer_explanation_in_english || ""}
//               onChange={(e) =>
//                 handleQuestionChange(
//                   index,
//                   "answer_explanation_in_english",
//                   e.target.value
//                 )
//               }
//             />
//             <input
//               type="text"
//               className="form-control mb-2"
//               placeholder={`Enter answer explanation in Hindi`}
//               value={question.answer_explanation_in_hindi || ""}
//               onChange={(e) =>
//                 handleQuestionChange(
//                   index,
//                   "answer_explanation_in_hindi",
//                   e.target.value
//                 )
//               }
//             />
//             <div className="form-group">
//               <label>Upload Answer Image</label>
//               <input
//                 type="file"
//                 className="form-control-file"
//                 name="answer_image"
//                 onChange={(e) =>
//                   handleFileChange(index, "answer_image", e.target.files[0])
//                 }
//               />
//             </div>
//             <button
//               type="button"
//               className="btn btn-danger"
//               onClick={() => removeQuestion(index)}
//               style={{ marginTop: "10px" }}
//             >
//               Remove Question
//             </button>
//           </div>
//         ))}
//         {submitSuccess && (
//           <div className="alert alert-success" role="alert">
//             Quiz submitted successfully!
//           </div>
//         )}
//         <button
//           type="button"
//           className="btn btn-primary mb-3 mx-5"
//           onClick={addQuestion}
//         >
//           Add Question
//         </button>
//         <button type="submit" className="btn btn-primary mb-3 mx-5">
//           Submit
//         </button>
//         <button
//           type="button"
//           className="btn btn-secondary mb-3 mx-5"
//           onClick={saveToLocalStorage}
//         >
//           Save
//         </button>
//         <button
//           type="button"
//           className="btn btn-secondary mb-3 mx-5"
//           onClick={restoreFromLocalStorage}
//         >
//           Restore
//         </button>
//       </form>
//     </div>
//   );
// };

// export default AddQuiz;
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getAllSubjects } from "../actions/subjectActions";
import { getAllDifficultys } from "../actions/difficultyActions";
import { getAllClassss } from "../actions/classsActions";

const AddQuiz = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [classs, setClasss] = useState("");
  const [division, setDivision] = useState("");
  const [subject, setSubject] = useState("");
  const [file, setFile] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [loading, setLoading] = useState(false); // Loader state

  const subjectsState = useSelector((state) => state.getAllSubjectsReducer);
  const {
    subjects,
    loading: subjectsLoading,
    error: subjectsError,
  } = subjectsState;

  const difficultysState = useSelector(
    (state) => state.getAllDifficultysReducer
  );
  const {
    difficultys,
    loading: difficultysLoading,
    error: difficultysError,
  } = difficultysState;

  const classssState = useSelector((state) => state.getAllClassssReducer);
  const {
    classss,
    loading: classssLoading,
    error: classssError,
  } = classssState;

  useEffect(() => {
    dispatch(getAllSubjects());
    dispatch(getAllDifficultys());
    dispatch(getAllClassss());
  }, [dispatch]);

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        id: questions.length + 1,
        question: "",
        question_in_hindi: "",
        question_image: null,
        options: ["", "", "", ""],
        correct_answer: "",
        answer_explanation_in_english: "",
        answer_explanation_in_hindi: "",
        answer_image: null,
      },
    ]);
  };

  const removeQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  const handleFileChange = (index, field, file) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = file;
    setQuestions(updatedQuestions);
  };

  const saveToLocalStorage = () => {
    const data = {
      name,
      difficulty,
      classs,
      division,
      subject,
      questions,
    };
    localStorage.setItem("quizData", JSON.stringify(data));
    alert("Data saved to local storage!");
  };

  const restoreFromLocalStorage = () => {
    const data = JSON.parse(localStorage.getItem("quizData"));
    if (data) {
      setName(data.name);
      setDifficulty(data.difficulty);
      setClasss(data.classs);
      setDivision(data.division);
      setSubject(data.subject);
      setQuestions(data.questions);
      alert("Data restored from local storage!");
    } else {
      alert("No data found in local storage!");
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    const formData = new FormData();
    formData.append("name", name);
    formData.append("difficulty", difficulty);
    formData.append("classs", classs);
    formData.append("division", division);
    formData.append("subject", subject);
    if (file) {
      formData.append("quiz_image", file);
    }
    formData.append(
      "questions",
      JSON.stringify(
        questions.map((q) => {
          const question_image = q.question_image
            ? `${q.id}_question_image`
            : null;
          const answer_image = q.answer_image ? `${q.id}_answer_image` : null;
          return { ...q, question_image, answer_image };
        })
      )
    );
    questions.forEach((q, index) => {
      if (q.question_image && q.question_image instanceof Blob) {
        formData.append(
          "questions_images",
          q.question_image,
          `${q.id}_question_image`
        );
      }
      if (q.answer_image && q.answer_image instanceof Blob) {
        formData.append(
          "questions_images",
          q.answer_image,
          `${q.id}_answer_image`
        );
      }
    });

    try {
      const response = await axios.post("/api/quizs/addquiz", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      setSubmitSuccess(true);
      setLoading(false); // Hide loader

      alert("Quiz Submitted Successfully");
    } catch (error) {
      console.error(error);
      setLoading(false); // Hide loader
    }
  };

  return (
    <div className="container">
      <hr></hr>
      <h1>Add New Quiz</h1>
      <hr></hr>
      <form onSubmit={submitHandler} encType="multipart/form-data">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter quiz name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ border: "1px solid black", marginBottom: "10px" }}
          />
        </div>
        <div className="form-group">
          <label>Category</label>
          <select
            className="form-control"
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Category</option>
            {difficultys &&
              difficultys.map((diff) => (
                <option key={diff._id} value={diff.diff}>
                  {diff.diff}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Class</label>
          <select
            className="form-control"
            value={classs}
            onChange={(e) => setClasss(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Class</option>
            {classss &&
              classss.map((cls) => (
                <option key={cls._id} value={cls.cls}>
                  {cls.cls}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <label>Division</label>
          <select
            className="form-control"
            value={division}
            onChange={(e) => setDivision(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Division</option>

            <option value="Udbodh">Udbodh</option>
            <option value="Bauddha Dakshata">Bauddha Dakshata</option>
          </select>
        </div>

        <div className="form-group">
          <label>Subject</label>
          <select
            className="form-control"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            style={{ marginBottom: "10px" }}
          >
            <option value="">Select Subject</option>
            {subjects &&
              subjects.map((sub) => (
                <option key={sub._id} value={sub.sub}>
                  {sub.sub}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group pd-5">
          <label>Upload Quiz Image</label>
          <input
            type="file"
            className="form-control-file"
            name="quiz_image"
            onChange={(e) => setFile(e.target.files[0])}
            style={{ marginBottom: "30px" }}
          />
        </div>
        {questions.map((question, index) => (
          <div key={index} className="mb-3">
            <label>Question {index + 1}</label>
            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter question ${index + 1}`}
              value={question.question || ""}
              onChange={(e) =>
                handleQuestionChange(index, "question", e.target.value)
              }
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter question ${index + 1} in Hindi`}
              value={question.question_in_hindi || ""}
              onChange={(e) =>
                handleQuestionChange(index, "question_in_hindi", e.target.value)
              }
            />
            <label>Options</label>
            {question.options.map((option, optionIndex) => (
              <input
                key={optionIndex}
                type="text"
                className="form-control mb-2"
                placeholder={`Enter option ${optionIndex + 1}`}
                value={option || ""}
                onChange={(e) =>
                  handleOptionChange(index, optionIndex, e.target.value)
                }
              />
            ))}
            <div className="form-group">
              <label>Correct Answer</label>
              <select
                className="form-control"
                value={question.correct_answer || ""}
                onChange={(e) =>
                  handleQuestionChange(index, "correct_answer", e.target.value)
                }
                onBlur={(e) =>
                  handleQuestionChange(index, "correct_answer", e.target.value)
                }
              >
                <option key="" value="">
                  Select Correct Option
                </option>
                {question.options.map((option, optionIndex) => (
                  <option key={optionIndex} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Upload Question Image</label>
              <input
                type="file"
                className="form-control-file"
                name="question_image"
                onChange={(e) =>
                  handleFileChange(index, "question_image", e.target.files[0])
                }
              />
            </div>
            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter answer explanation in English`}
              value={question.answer_explanation_in_english || ""}
              onChange={(e) =>
                handleQuestionChange(
                  index,
                  "answer_explanation_in_english",
                  e.target.value
                )
              }
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder={`Enter answer explanation in Hindi`}
              value={question.answer_explanation_in_hindi || ""}
              onChange={(e) =>
                handleQuestionChange(
                  index,
                  "answer_explanation_in_hindi",
                  e.target.value
                )
              }
            />
            <div className="form-group">
              <label>Upload Answer Image</label>
              <input
                type="file"
                className="form-control-file"
                name="answer_image"
                onChange={(e) =>
                  handleFileChange(index, "answer_image", e.target.files[0])
                }
              />
            </div>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => removeQuestion(index)}
              style={{ marginTop: "10px" }}
            >
              Remove Question
            </button>
          </div>
        ))}
        {/* {submitSuccess && (
          <div className="alert alert-success" role="alert">
            Quiz submitted successfully!
          </div>
        )} */}
        {loading && (
          <div className="loader">Submitting...</div> // Loader message
        )}
        <button
          type="button"
          className="btn btn-primary mb-3 mx-5"
          onClick={addQuestion}
        >
          Add Question
        </button>
        <button type="submit" className="btn btn-primary mb-3 mx-5">
          Submit
        </button>
        <button
          type="button"
          className="btn btn-secondary mb-3 mx-5"
          onClick={saveToLocalStorage}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-secondary mb-3 mx-5"
          onClick={restoreFromLocalStorage}
        >
          Restore
        </button>
      </form>
    </div>
  );
};

export default AddQuiz;
