export const getAllRollnumbersReducer = (
  state = { rollnumbers: [] },
  action
) => {
  switch (action.type) {
    case "GET_ROLLNUMBERS_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_ROLLNUMBERS_SUCCESS":
      return {
        loading: false,
        rollnumbers: action.payload,
      };
    case "GET_ROLLNUMBERS_FAILED":
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const addRollnumberReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_ROLLNUMBER_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "ADD_ROLLNUMBER_SUCCESS":
      return {
        loading: false,
        success: true,
      };
    case "ADD_ROLLNUMBER_FAILED":
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const getRollnumberByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_ROLLNUMBERYID_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_ROLLNUMBERBYID_SUCCESS":
      return {
        loading: false,
        rollnumber: action.payload,
      };
    case "GET_ROLLNUMBERBYID_FAILED":
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
