// import React, { useState } from "react";
// import toast from "react-hot-toast";
// import "./pay.css";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   deleteUser,
//   getAllUsers,
//   updatePaidStatus,
// } from "../actions/userActions";

// const Paymentplansscreen = () => {
//   const usersstate = useSelector((state) => state.getAllUsersReducer);
//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userstate;
//   const { error, loading, users } = usersstate;
//   const buttonShadowStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//     transition: "background-color 0.3s",
//   };

//   // handlePayment Function
//   const handlePayment = async (amount) => {
//     try {
//       const res = await fetch(`/api/payment/order`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//         },
//         body: JSON.stringify({
//           amount,
//         }),
//       });

//       const data = await res.json();
//       console.log(data);
//       handlePaymentVerify(data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   // handlePaymentVerify Function
//   const handlePaymentVerify = async (data) => {
//     const options = {
//       key: process.env.REACT_APP_RAZORPAY_KEY_ID,
//       amount: data.amount,
//       currency: data.currency,
//       name: "Shashwat Singh",
//       description: "Test Mode",
//       order_id: data.id,
//       handler: async (response) => {
//         console.log("response", response);
//         try {
//           const res = await fetch(`/api/payment/verify`, {
//             method: "POST",
//             headers: {
//               "content-type": "application/json",
//             },
//             body: JSON.stringify({
//               razorpay_order_id: response.razorpay_order_id,
//               razorpay_payment_id: response.razorpay_payment_id,
//               razorpay_signature: response.razorpay_signature,
//               userId: currentUser.userId,
//             }),
//           });

//           const verifyData = await res.json();

//           if (verifyData.message) {
//             toast.success(verifyData.message);
//           }
//         } catch (error) {
//           console.log(error);
//         }
//       },
//       theme: {
//         color: "#5f63b8",
//       },
//     };
//     const rzp1 = new window.Razorpay(options);
//     rzp1.open();
//   };

//   return (
//     <div className="pricing-table" style={{ marginTop: "70px" }}>
//       <h3>
//         In order to access more quizzes, please purchase one of the below
//         mentioned plans:
//       </h3>
//       <div className="plans d-flex flex-wrap">
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">₹ 1799</p>
//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Udbodh as well as Bauddha Dakshata.</li>
//           </ul>
//           <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() => handlePayment(1799)}
//           >
//             Get Now
//           </button>
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">₹ 999</p>
//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Udbodh as well as Bauddha Dakshata.</li>
//           </ul>
//           <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() => handlePayment(999)}
//           >
//             Get Now
//           </button>
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">₹ 549</p>
//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Udbodh as well as Bauddha Dakshata.</li>
//           </ul>
//           <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() => handlePayment(549)}
//           >
//             Get Now
//           </button>
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">₹ 199</p>
//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Udbodh as well as Bauddha Dakshata.</li>
//           </ul>
//           <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() => handlePayment(199)}
//           >
//             Get Now
//           </button>
//         </div>

//         {/* <div className="plan shadow">
//           <h3 className="plan-title free">Free Plan</h3>
//           <p className="plan-price">₹ 0</p>
//           <ul className="plan-details">
//             <li>School Code Required.</li>
//             <li>Udbodh as well as Bauddha Dakshata.</li>
//           </ul>
//           <button className="plan-button" style={{ ...buttonShadowStyle }}>
//             Get Now
//           </button>
//         </div> */}
//       </div>
//       <div className="contact-info">
//         <p>FOR ANY QUERY PLEASE CONTACT:</p>
//         <p>9643009106 / ashutosh@gyankosha.com</p>
//       </div>
//     </div>
//   );
// };

// export default Paymentplansscreen;
///////////////////////////////////////////////////////////////////////
// import React, { useState } from "react";
// import toast from "react-hot-toast";
// import "./pay.css";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   deleteUser,
//   getAllUsers,
//   updatePaidStatus,
// } from "../actions/userActions";

// const Paymentplansscreen = () => {
//   const usersstate = useSelector((state) => state.getAllUsersReducer);
//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userstate;
//   const { error, loading, users } = usersstate;
//   const buttonShadowStyle = {
//     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
//     transition: "background-color 0.3s",
//   };

//   // handlePayment Function
//   const handlePayment = async (amount) => {
//     try {
//       const res = await fetch(`/api/payment/order`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//         },
//         body: JSON.stringify({
//           amount,
//         }),
//       });

//       const data = await res.json();
//       console.log(data);
//       handlePaymentVerify(data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   // handlePaymentVerify Function
//   const handlePaymentVerify = async (data) => {
//     const options = {
//       key: process.env.REACT_APP_RAZORPAY_KEY_ID,
//       amount: data.amount,
//       currency: data.currency,
//       name: "Shashwat Singh",
//       description: "Test Mode",
//       order_id: data.id,
//       handler: async (response) => {
//         console.log("response", response);
//         try {
//           const res = await fetch(`/api/payment/verify`, {
//             method: "POST",
//             headers: {
//               "content-type": "application/json",
//             },
//             body: JSON.stringify({
//               razorpay_order_id: response.razorpay_order_id,
//               razorpay_payment_id: response.razorpay_payment_id,
//               razorpay_signature: response.razorpay_signature,
//               userId: currentUser.userId,
//             }),
//           });

//           const verifyData = await res.json();

//           if (verifyData.message) {
//             toast.success(verifyData.message);
//           }
//         } catch (error) {
//           console.log(error);
//         }
//       },
//       theme: {
//         color: "#5f63b8",
//       },
//     };
//     const rzp1 = new window.Razorpay(options);
//     rzp1.open();
//   };

//   return (
//     <div className="pricing-table" style={{ marginTop: "70px" }}>
//       <h3>
//         In order to access more quizzes, please purchase one of the below
//         mentioned plans:
//       </h3>
//       <p className="early-bird-notice">
//         <img src="early-bird.png" width="100px" height="auto" />
//         <br></br>
//         Prices listed below are for this year only. Next year, the price will be
//         ₹999 per month.
//       </p>
//       <div className="plans d-flex flex-wrap">
//         <div className="plan shadow">
//           <h3 className="plan-title yearly">Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 11988</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹5499
//           </p>
//           <p className="save">
//             <small>
//               <small>
//                 <small>
//                   <small
//                     className="bg-danger rounded-pill"
//                     style={{
//                       padding: "10px",
//                       color: "white",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     <small>
//                       <small>
//                         <small>Save 54%</small>
//                       </small>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//           </p>
//           <ul className="plan-details">
//             <li>Validity: 365 days from the date of subscription.</li>
//             <li>Udbodh as well as Bauddha Dakshata.</li>
//           </ul>
//           <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() => handlePayment(5499)}
//           >
//             Get Now
//           </button>
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 5994</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹2699
//           </p>
//           <p className="save">
//             <small>
//               <small>
//                 <small>
//                   <small
//                     className="bg-danger rounded-pill"
//                     style={{
//                       padding: "10px",
//                       color: "white",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     <small>
//                       <small>
//                         <small>Save 53%</small>
//                       </small>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//           </p>
//           <ul className="plan-details">
//             <li>Validity: 180 days from the date of subscription.</li>
//             <li>Udbodh as well as Bauddha Dakshata.</li>
//           </ul>
//           <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() => handlePayment(2699)}
//           >
//             Get Now
//           </button>
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title quarterly">Quarterly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 2997</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹1399
//           </p>
//           <p className="save">
//             <small>
//               <small>
//                 <small>
//                   <small
//                     className="bg-danger rounded-pill"
//                     style={{
//                       padding: "10px",
//                       color: "white",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     <small>
//                       <small>
//                         <small>Save 53%</small>
//                       </small>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//           </p>
//           <ul className="plan-details">
//             <li>Validity: 90 days from the date of subscription.</li>
//             <li>Udbodh as well as Bauddha Dakshata.</li>
//           </ul>
//           <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() => handlePayment(1399)}
//           >
//             Get Now
//           </button>
//         </div>

//         <div className="plan shadow">
//           <h3 className="plan-title monthly">Monthly Plan</h3>
//           <p className="plan-price">
//             <small>
//               <small>
//                 <small>
//                   <small>
//                     <small>
//                       <s className="text-muted">₹ 999</s>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//             {"\n "}₹499
//           </p>
//           <p className="save">
//             <small>
//               <small>
//                 <small>
//                   <small
//                     className="bg-danger rounded-pill"
//                     style={{
//                       padding: "10px",
//                       color: "white",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     <small>
//                       <small>
//                         <small>Save 50%</small>
//                       </small>
//                     </small>
//                   </small>
//                 </small>
//               </small>
//             </small>
//           </p>
//           <ul className="plan-details">
//             <li>Validity: 30 days from the date of subscription.</li>
//             <li>Udbodh as well as Bauddha Dakshata.</li>
//           </ul>
//           <button
//             className="plan-button"
//             style={{ ...buttonShadowStyle }}
//             onClick={() => handlePayment(499)}
//           >
//             Get Now
//           </button>
//         </div>
//       </div>
//       <div className="contact-info">
//         <p>FOR ANY QUERY PLEASE CONTACT:</p>
//         <p>9643009106 / ashutosh@gyankosha.com</p>
//       </div>
//     </div>
//   );
// };

// export default Paymentplansscreen;
//////////////////////////////////////////////////////////////////////////////////////
import React, { useState } from "react";
import toast from "react-hot-toast";
import "./pay.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getAllUsers,
  updatePaidStatus,
} from "../actions/userActions";

const Paymentplansscreen = () => {
  const usersstate = useSelector((state) => state.getAllUsersReducer);
  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userstate;
  const { error, loading, users } = usersstate;
  const buttonShadowStyle = {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
    transition: "background-color 0.3s",
  };

  // handleSubscription Function
  const handleSubscription = async (plan_id, total_count) => {
    try {
      const res = await fetch(`/api/payment/subscription`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          plan_id,
          userId: currentUser._id, // Pass user ID to backend
          total_count,
        }),
      });

      const data = await res.json();
      console.log(data);
      handlePaymentVerify(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // handlePaymentVerify Function
  const handlePaymentVerify = async (data) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      subscription_id: data.id,
      name: "Gyankosha",
      description: "Payment for purchasing Quiz",
      handler: async (response) => {
        console.log("response", response);
        try {
          const res = await fetch(`/api/payment/verify`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              razorpay_subscription_id: response.razorpay_subscription_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              userId: currentUser._id, // Pass user ID for verification
            }),
          });

          const verifyData = await res.json();

          if (verifyData.message) {
            toast.success(verifyData.message);

            // Reload the page after 2 seconds
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#5f63b8",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <div className="pricing-table" style={{ marginTop: "70px" }}>
      <h3>
        In order to access more quizzes, please purchase one of the below
        mentioned plans:
      </h3>
      <p className="early-bird-notice">
        <img src="early-bird.png" width="100px" height="auto" />
        <br></br>
        Prices listed below are for this year only. Next year, the price will be
        ₹999 per month.
      </p>
      <div className="plans d-flex flex-wrap">
        <div className="plan shadow">
          <h3 className="plan-title yearly">Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 11988</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹5499
          </p>
          <p className="save">
            <small>
              <small>
                <small>
                  <small
                    className="bg-danger rounded-pill"
                    style={{
                      padding: "10px",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    <small>
                      <small>
                        <small>Save 54%</small>
                      </small>
                    </small>
                  </small>
                </small>
              </small>
            </small>
          </p>
          <ul className="plan-details">
            <li>Validity: 365 days from the date of subscription.</li>
            <li>Udbodh as well as Bauddha Dakshata.</li>
          </ul>
          <button
            className="plan-button"
            style={{ ...buttonShadowStyle }}
            onClick={() =>
              handleSubscription(process.env.REACT_APP_PLAN_ID_ANUALLY, 8)
            }
          >
            Get Now
          </button>
        </div>

        <div className="plan shadow">
          <h3 className="plan-title half-yearly">Half Yearly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 5994</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹2699
          </p>
          <p className="save">
            <small>
              <small>
                <small>
                  <small
                    className="bg-danger rounded-pill"
                    style={{
                      padding: "10px",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    <small>
                      <small>
                        <small>Save 53%</small>
                      </small>
                    </small>
                  </small>
                </small>
              </small>
            </small>
          </p>
          <ul className="plan-details">
            <li>Validity: 180 days from the date of subscription.</li>
            <li>Udbodh as well as Bauddha Dakshata.</li>
          </ul>
          <button
            className="plan-button"
            style={{ ...buttonShadowStyle }}
            onClick={() =>
              handleSubscription(process.env.REACT_APP_PLAN_ID_HALFYEARLY, 16)
            }
          >
            Get Now
          </button>
        </div>

        <div className="plan shadow">
          <h3 className="plan-title quarterly">Quarterly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 2997</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹1399
          </p>
          <p className="save">
            <small>
              <small>
                <small>
                  <small
                    className="bg-danger rounded-pill"
                    style={{
                      padding: "10px",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    <small>
                      <small>
                        <small>Save 53%</small>
                      </small>
                    </small>
                  </small>
                </small>
              </small>
            </small>
          </p>
          <ul className="plan-details">
            <li>Validity: 90 days from the date of subscription.</li>
            <li>Udbodh as well as Bauddha Dakshata.</li>
          </ul>
          <button
            className="plan-button"
            style={{ ...buttonShadowStyle }}
            onClick={() =>
              handleSubscription(process.env.REACT_APP_PLAN_ID_QUARTERLY, 32)
            }
          >
            Get Now
          </button>
        </div>

        <div className="plan shadow">
          <h3 className="plan-title monthly">Monthly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 999</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹499
          </p>
          <p className="save">
            <small>
              <small>
                <small>
                  <small
                    className="bg-danger rounded-pill"
                    style={{
                      padding: "10px",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    <small>
                      <small>
                        <small>Save 50%</small>
                      </small>
                    </small>
                  </small>
                </small>
              </small>
            </small>
          </p>
          <ul className="plan-details">
            <li>Validity: 30 days from the date of subscription.</li>
            <li>Udbodh as well as Bauddha Dakshata.</li>
          </ul>
          <button
            className="plan-button"
            style={{ ...buttonShadowStyle }}
            onClick={() =>
              handleSubscription(process.env.REACT_APP_PLAN_ID_MONTHLY, 96)
            }
          >
            Get Now
          </button>
        </div>

        <div className="plan shadow">
          <h3 className="plan-title yearly">Testing Weekly Plan</h3>
          <p className="plan-price">
            <small>
              <small>
                <small>
                  <small>
                    <small>
                      <s className="text-muted">₹ 11988</s>
                    </small>
                  </small>
                </small>
              </small>
            </small>
            {"\n "}₹99
          </p>
          <p className="save">
            <small>
              <small>
                <small>
                  <small
                    className="bg-danger rounded-pill"
                    style={{
                      padding: "10px",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    <small>
                      <small>
                        <small>Save 54%</small>
                      </small>
                    </small>
                  </small>
                </small>
              </small>
            </small>
          </p>
          <ul className="plan-details">
            <li>Validity: 7 days from the date of subscription.</li>
            <li>Udbodh as well as Bauddha Dakshata.</li>
          </ul>
          <button
            className="plan-button"
            style={{ ...buttonShadowStyle }}
            onClick={() =>
              handleSubscription(process.env.REACT_APP_PLAN_ID_WEEKLY, 4)
            }
          >
            Get Now
          </button>
        </div>
      </div>
      <div className="contact-info">
        <p>FOR ANY QUERY PLEASE CONTACT:</p>
        <p>9643009106 / ashutosh@gyankosha.com</p>
      </div>
    </div>
  );
};

export default Paymentplansscreen;
