import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoVolumeHighOutline } from "react-icons/io5";
import axios from "axios";
import "./QuestionPage.css"; // Import the CSS file

function QuestionPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const questions = location.state ? location.state.questions : null;
  const name = location.state ? location.state.name : null;
  const division = location.state ? location.state.division : null;
  const userId = location.state ? location.state.userId : null;
  const quizId = location.state ? location.state.quizId : null;
  const quizName = location.state ? location.state.quizName : null;
  const quizCategory = location.state ? location.state.quizCategory : null;
  const quizSubject = location.state ? location.state.quizSubject : null;
  const userName = location.state ? location.state.userName : null;
  const userSchoolcode = location.state ? location.state.userSchoolcode : null;
  const userClass = location.state ? location.state.userClass : null;
  const userSection = location.state ? location.state.userSection : null;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [showQuestionImage, setShowQuestionImage] = useState(true);
  const [showAnswerImage, setShowAnswerImage] = useState(false);
  const [shouldReadExplanation, setShouldReadExplanation] = useState(false);
  const [result, setResult] = useState(Array(questions.length).fill(-1));
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showAnswer, setShowAnswer] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when the component mounts
  }, []);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const handleKeyDown = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const currentQuestion = questions[currentQuestionIndex];
    if (event.target.value === currentQuestion.correct_answer) {
      setFeedbackMessage("Correct Option Chosen");
      setIsCorrect(true);
      setShowAnswerImage(true);
      setShouldReadExplanation(true);
      if (result[currentQuestionIndex] === -1) {
        updateResult(1);
      }

      setTimeout(function () {
        const element = document.getElementById("scrollcorrect");
        element?.scrollIntoView({
          behavior: "smooth",
        });
      }, 200);
    } else {
      setFeedbackMessage("Wrong Answer Chosen");
      setIsCorrect(false);
      setShowAnswerImage(false);
      setShouldReadExplanation(false);

      if (result[currentQuestionIndex] === -1) {
        updateResult(0);
      }

      setTimeout(function () {
        const element = document.getElementById("scrollwrong");
        element?.scrollIntoView({
          behavior: "smooth",
        });
      }, 200);
    }
  };

  const updateResult = (value) => {
    setResult((prevResult) => {
      const newResult = [...prevResult];
      newResult[currentQuestionIndex] = value;
      return newResult;
    });
  };

  const clearOptions = () => {
    setSelectedOption(null);
    setFeedbackMessage("");
    setShowAnswerImage(false);
    setShouldReadExplanation(false);
    setShowAnswer(false);

    if (result[currentQuestionIndex] === -1) {
      updateResult(-1);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption(null);
      setFeedbackMessage("");
      setShowAnswerImage(false);
      setShouldReadExplanation(false);
      setShowQuestionImage(true);
      setShowAnswer(false);
      setIsCorrect(false);
      setTimeout(function () {
        const element = document.getElementById("top");
        element?.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedOption(null);
      setFeedbackMessage("");
      setShowAnswerImage(false);
      setShouldReadExplanation(false);
      setShowQuestionImage(true);
      setShowAnswer(false);
      setIsCorrect(false);
      setTimeout(function () {
        const element = document.getElementById("top");
        element?.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  };

  const handleQuestionChange = (index) => {
    setCurrentQuestionIndex(index);
    setSelectedOption(null);
    setFeedbackMessage("");
    setShowAnswerImage(false);
    setShouldReadExplanation(false);
    setShowQuestionImage(true);
    setShowAnswer(false);
    setIsCorrect(false);
    setTimeout(function () {
      const element = document.getElementById("top");
      element?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };

  const handleVoiceReading = () => {
    const speech = new SpeechSynthesisUtterance();
    const currentQuestion = questions[currentQuestionIndex];
    let fullText = `Question ${currentQuestionIndex + 1}. ${
      currentQuestion.question
    }. ${currentQuestion.question_in_hindi}. Options are: `;
    currentQuestion.options.forEach(
      (option, index) => (fullText += `${option}. `)
    );
    if (shouldReadExplanation) {
      fullText += `Explanation in English: ${currentQuestion.answer_explanation_in_english}. Explanation in Hindi: ${currentQuestion.answer_explanation_in_hindi}.`;
    }
    speech.text = fullText;
    speech.volume = 1;
    speech.rate = 1;
    speech.pitch = 1;
    speech.lang = "hi-IN"; // Set language to Hindi
    window.speechSynthesis.speak(speech);
  };

  const modifyImageUrl = (imageUrl) => {
    const fileId = imageUrl.match(/\/d\/(.+?)\//)[1]; // Extract file ID from URL
    return `https://lh3.googleusercontent.com/d/${fileId}=s1900?authuser=0`; // Construct thumbnail URL
  };

  if (!questions) {
    return <div className="container mt-3">Loading...</div>;
  }

  const handleReturnToQuizList = async () => {
    try {
      const totalQuestions = result.length;
      const correctAnswers = result.filter((r) => r === 1).length;
      const wrongAnswers = result.filter((r) => r === 0).length;
      const attemptedQuestions = result.filter((r) => r !== -1).length;

      await axios.post("/api/users/attemptquiz", {
        userId,
        quizId,
        totalQuestions,
        correctAnswers,
        wrongAnswers,
        attemptedQuestions,
        division,
        userName,
        userSchoolcode,
        userClass,
        userSection,
        quizName,
        quizCategory,
        quizSubject,
      });

      navigate(division === "Udbodh" ? "/udbodh" : "/bauddhadakshata");
    } catch (error) {
      console.error("Error at Exit Quiz", error);
    }
    navigate(division === "Udbodh" ? "/udbodh" : "/bauddhadakshata");
  };

  const handleSubmitQuiz = async () => {
    try {
      const totalQuestions = result.length;
      const correctAnswers = result.filter((r) => r === 1).length;
      const wrongAnswers = result.filter((r) => r === 0).length;
      const attemptedQuestions = result.filter((r) => r !== -1).length;

      await axios.post("/api/users/completequiz", {
        userId,
        quizId,
        totalQuestions,
        correctAnswers,
        wrongAnswers,
        attemptedQuestions,
        division,
        userName,
        userSchoolcode,
        userClass,
        userSection,
        quizName,
        quizCategory,
        quizSubject,
      });

      // await axios.post("/api/users/sendresult", {
      //   userId,
      //   totalQuestions,
      //   attemptedQuestions,
      //   correctAnswers,
      //   wrongAnswers,
      // });

      navigate("/quizresult", {
        state: {
          totalQuestions,
          correctAnswers,
          wrongAnswers,
          attemptedQuestions,
          division,
        },
      });
    } catch (error) {
      console.error("Error at Submitting Quiz", error);
    }
  };

  const handleShowAnswer = () => {
    setShowAnswer(true);

    setTimeout(function () {
      const element = document.getElementById("scrollcorrect");
      element?.scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  };

  return (
    <div className="container-fluid question-page-container">
      <div className="question-content mt-5">
        <div>
          <button
            className="btn btn-primary"
            onClick={handleReturnToQuizList}
            style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}
            id="top"
          >
            Exit Quiz
          </button>
          <button
            className="btn btn-primary"
            style={{
              marginLeft: "20px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            }}
            onClick={handleSubmitQuiz}
          >
            Submit
          </button>
        </div>
        <br></br>
        <div>
          <div className="navigation-bar">
            {questions.map((_, index) => (
              <button
                key={index}
                className={`btn btn-link navigation-button ${
                  currentQuestionIndex === index ? "active" : ""
                }`}
                onClick={() => handleQuestionChange(index)}
                style={{
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                }}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
        <br></br>
        <p style={{ color: "red", fontStyle: "italic" }}>
          Only First Attempted Option will be counted in Final Result
        </p>
        <h1 className="question-header">
          Question {currentQuestionIndex + 1}
          <button
            className="voice-button"
            onClick={handleVoiceReading}
            style={{
              fontSize: "14px",
              marginLeft: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          >
            <IoVolumeHighOutline style={{ color: "black" }} />
          </button>
        </h1>
        <p className="question-text">
          {questions[currentQuestionIndex].question}
        </p>
        <p className="question-text-bold">
          {questions[currentQuestionIndex].question_in_hindi}
        </p>
        {questions[currentQuestionIndex].question_image && (
          <div>
            <img
              src={modifyImageUrl(
                questions[currentQuestionIndex].question_image
              )}
              alt="Question Image"
              className="question-image"
            />
          </div>
        )}
        <form className="options-form">
          {questions[currentQuestionIndex].options.map((option, index) => (
            <div className="form-check option-item" key={index}>
              <input
                className="form-check-input"
                type="radio"
                id={`option${index}`}
                name="option"
                value={option}
                checked={option === selectedOption}
                onChange={handleOptionChange}
              />
              <label className="form-check-label" htmlFor={`option${index}`}>
                {option}
              </label>
            </div>
          ))}
        </form>
        <div>
          {currentQuestionIndex > 0 && (
            <button
              className="btn btn-secondary"
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              }}
              onClick={handlePreviousQuestion}
            >
              Previous
            </button>
          )}
          {currentQuestionIndex < questions.length - 1 && (
            <button
              className="btn btn-primary"
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              }}
              onClick={handleNextQuestion}
            >
              Next
            </button>
          )}
          <button
            className="btn btn-warning"
            onClick={clearOptions}
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              marginTop: "10px",
              marginBottom: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          >
            Clear Options
          </button>
          <button
            className="btn btn-info"
            onClick={handleShowAnswer}
            disabled={isCorrect || !selectedOption || isCorrect}
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              marginTop: "10px",
              marginBottom: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          >
            Show Answer
          </button>
          <div
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              marginTop: "10px",
              marginBottom: "10px",
              fontWeight: "bold",
              color: isCorrect ? "green" : "red",
            }}
          >
            {feedbackMessage}
          </div>
          <div id="scrollwrong" style={{ color: "white" }}>
            .
          </div>
          {(isCorrect || showAnswer) && (
            <div className="answer-explanation-container">
              <div className="explanation-text-container">
                <p
                  className="explanation-text"
                  style={{
                    fontFamily: "'Maged Bold', Georgia, serif",
                    fontWeight: "bold",
                    //fontSize: "20px",
                  }}
                >
                  {
                    questions[currentQuestionIndex]
                      .answer_explanation_in_english
                  }
                </p>
              </div>
              {questions[currentQuestionIndex].answer_image && (
                <div className="answer-image-container">
                  <img
                    src={modifyImageUrl(
                      questions[currentQuestionIndex].answer_image
                    )}
                    alt="Answer Image"
                    className="answer-image"
                  />
                </div>
              )}
              <div className="explanation-text-container">
                <p
                  className="explanation-text"
                  style={{
                    fontFamily: "'Maged Bold', Georgia, serif",
                    fontWeight: "bold",
                    //fontSize: "20px",
                  }}
                  id="scrollcorrect"
                >
                  {questions[currentQuestionIndex].answer_explanation_in_hindi}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionPage;
///////////////////////////////////////////////////////////////////////////////////////////////////////////////
