// import React from "react";
// import { useSelector } from "react-redux";

// function Dashboard() {
//   const userLogin = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userLogin;

//   return (
//     <div className="container mt-4">
//       <h2>Dashboard</h2>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{currentUser.quizs.length}</td>
//               <td>{currentUser.attemptedquizs.length}</td>
//               <td>
//                 {currentUser.quizs.length - currentUser.attemptedquizs.length}
//               </td>
//               <td>{currentUser.completedquizs.length}</td>
//               <td>
//                 {currentUser.attemptedquizs.length -
//                   currentUser.completedquizs.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default Dashboard;
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// import React from "react";
// import { useSelector } from "react-redux";

// function Dashboard() {
//   const userLogin = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userLogin;

//   // Filter quizzes with division "Udbodh"
//   const udbodhQuizzes = currentUser.quizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );
//   const udbodhAttemptedQuizzes = currentUser.attemptedquizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );
//   const udbodhCompletedQuizzes = currentUser.completedquizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );

//   return (
//     <div className="container mt-4">
//       <h2>Dashboard</h2>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{currentUser.quizs.length}</td>
//               <td>{currentUser.attemptedquizs.length}</td>
//               <td>
//                 {currentUser.quizs.length - currentUser.attemptedquizs.length}
//               </td>
//               <td>{currentUser.completedquizs.length}</td>
//               <td>
//                 {currentUser.attemptedquizs.length -
//                   currentUser.completedquizs.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <h3 className="mt-4">Udbodh Quizzes</h3>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{udbodhQuizzes.length}</td>
//               <td>{udbodhAttemptedQuizzes.length}</td>
//               <td>{udbodhQuizzes.length - udbodhAttemptedQuizzes.length}</td>
//               <td>{udbodhCompletedQuizzes.length}</td>
//               <td>
//                 {udbodhAttemptedQuizzes.length - udbodhCompletedQuizzes.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default Dashboard;
///////////////////////////////////////////////////////////////////////////////////////////////////
// import React from "react";
// import { useSelector } from "react-redux";

// function Dashboard() {
//   const userLogin = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userLogin;

//   // Filter quizzes with division "Udbodh"
//   const udbodhQuizzes = currentUser.quizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );
//   const udbodhAttemptedQuizzes = currentUser.attemptedquizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );
//   const udbodhCompletedQuizzes = currentUser.completedquizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );

//   // Filter quizzes with division "Bauddha Dakshata"
//   const bauddhaDakshataQuizzes = currentUser.quizs.filter(
//     (quiz) => quiz.division === "Bauddha Dakshata"
//   );
//   const bauddhaDakshataAttemptedQuizzes = currentUser.attemptedquizs.filter(
//     (quiz) => quiz.division === "Bauddha Dakshata"
//   );
//   const bauddhaDakshataCompletedQuizzes = currentUser.completedquizs.filter(
//     (quiz) => quiz.division === "Bauddha Dakshata"
//   );

//   return (
//     <div className="container mt-4">
//       <h2>Dashboard</h2>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{currentUser.quizs.length}</td>
//               <td>{currentUser.attemptedquizs.length}</td>
//               <td>
//                 {currentUser.quizs.length - currentUser.attemptedquizs.length}
//               </td>
//               <td>{currentUser.completedquizs.length}</td>
//               <td>
//                 {currentUser.attemptedquizs.length -
//                   currentUser.completedquizs.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <h3 className="mt-4">Udbodh Quizzes</h3>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{udbodhQuizzes.length}</td>
//               <td>{udbodhAttemptedQuizzes.length}</td>
//               <td>{udbodhQuizzes.length - udbodhAttemptedQuizzes.length}</td>
//               <td>{udbodhCompletedQuizzes.length}</td>
//               <td>
//                 {udbodhAttemptedQuizzes.length - udbodhCompletedQuizzes.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <h3 className="mt-4">Bauddha Dakshata Quizzes</h3>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{bauddhaDakshataQuizzes.length}</td>
//               <td>{bauddhaDakshataAttemptedQuizzes.length}</td>
//               <td>
//                 {bauddhaDakshataQuizzes.length -
//                   bauddhaDakshataAttemptedQuizzes.length}
//               </td>
//               <td>{bauddhaDakshataCompletedQuizzes.length}</td>
//               <td>
//                 {bauddhaDakshataAttemptedQuizzes.length -
//                   bauddhaDakshataCompletedQuizzes.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default Dashboard;
//////////////////////////////////////////////////////////////////////////////////////////////////
// import React from "react";
// import { useSelector } from "react-redux";

// function Dashboard() {
//   const userLogin = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userLogin;

//   // Filter quizzes with division "Udbodh"
//   const udbodhQuizzes = currentUser.quizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );
//   const udbodhAttemptedQuizzes = currentUser.attemptedquizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );
//   const udbodhCompletedQuizzes = currentUser.completedquizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );

//   // Filter quizzes with division "Bauddha Dakshata"
//   const bauddhaDakshataQuizzes = currentUser.quizs.filter(
//     (quiz) => quiz.division === "Bauddha Dakshata"
//   );
//   const bauddhaDakshataAttemptedQuizzes = currentUser.attemptedquizs.filter(
//     (quiz) => quiz.division === "Bauddha Dakshata"
//   );
//   const bauddhaDakshataCompletedQuizzes = currentUser.completedquizs.filter(
//     (quiz) => quiz.division === "Bauddha Dakshata"
//   );

//   // Group quizzes by subject
//   const quizzesBySubject = currentUser.quizs.reduce((acc, quiz) => {
//     if (!acc[quiz.subject]) {
//       acc[quiz.subject] = {
//         total: 0,
//         attempted: 0,
//         completed: 0,
//       };
//     }
//     acc[quiz.subject].total += 1;
//     if (currentUser.attemptedquizs.includes(quiz._id)) {
//       acc[quiz.subject].attempted += 1;
//     }
//     if (currentUser.completedquizs.includes(quiz._id)) {
//       acc[quiz.subject].completed += 1;
//     }
//     return acc;
//   }, {});

//   return (
//     <div className="container mt-4">
//       <h2>Dashboard</h2>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{currentUser.quizs.length}</td>
//               <td>{currentUser.attemptedquizs.length}</td>
//               <td>
//                 {currentUser.quizs.length - currentUser.attemptedquizs.length}
//               </td>
//               <td>{currentUser.completedquizs.length}</td>
//               <td>
//                 {currentUser.attemptedquizs.length -
//                   currentUser.completedquizs.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <h3 className="mt-4">Udbodh Quizzes</h3>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{udbodhQuizzes.length}</td>
//               <td>{udbodhAttemptedQuizzes.length}</td>
//               <td>{udbodhQuizzes.length - udbodhAttemptedQuizzes.length}</td>
//               <td>{udbodhCompletedQuizzes.length}</td>
//               <td>
//                 {udbodhAttemptedQuizzes.length - udbodhCompletedQuizzes.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <h3 className="mt-4">Bauddha Dakshata Quizzes</h3>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{bauddhaDakshataQuizzes.length}</td>
//               <td>{bauddhaDakshataAttemptedQuizzes.length}</td>
//               <td>
//                 {bauddhaDakshataQuizzes.length -
//                   bauddhaDakshataAttemptedQuizzes.length}
//               </td>
//               <td>{bauddhaDakshataCompletedQuizzes.length}</td>
//               <td>
//                 {bauddhaDakshataAttemptedQuizzes.length -
//                   bauddhaDakshataCompletedQuizzes.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <h3 className="mt-4">Quizzes by Subject</h3>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Subject</th>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             {Object.keys(quizzesBySubject).map((subject) => (
//               <tr key={subject}>
//                 <td>{subject}</td>
//                 <td>{quizzesBySubject[subject].total}</td>
//                 <td>{quizzesBySubject[subject].attempted}</td>
//                 <td>
//                   {quizzesBySubject[subject].total -
//                     quizzesBySubject[subject].attempted}
//                 </td>
//                 <td>{quizzesBySubject[subject].completed}</td>
//                 <td>
//                   {quizzesBySubject[subject].attempted -
//                     quizzesBySubject[subject].completed}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default Dashboard;
////////////////////////////////////////////////////////////////////////////////////
// import React from "react";
// import { useSelector } from "react-redux";

// function Dashboard() {
//   const userLogin = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userLogin;

//   // Filter quizzes with division "Udbodh"
//   const udbodhQuizzes = currentUser.quizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );
//   const udbodhAttemptedQuizzes = currentUser.attemptedquizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );
//   const udbodhCompletedQuizzes = currentUser.completedquizs.filter(
//     (quiz) => quiz.division === "Udbodh"
//   );

//   // Filter quizzes with division "Bauddha Dakshata"
//   const bauddhaDakshataQuizzes = currentUser.quizs.filter(
//     (quiz) => quiz.division === "Bauddha Dakshata"
//   );
//   const bauddhaDakshataAttemptedQuizzes = currentUser.attemptedquizs.filter(
//     (quiz) => quiz.division === "Bauddha Dakshata"
//   );
//   const bauddhaDakshataCompletedQuizzes = currentUser.completedquizs.filter(
//     (quiz) => quiz.division === "Bauddha Dakshata"
//   );

//   // Group quizzes by subject
//   const quizzesBySubject = currentUser.quizs.reduce((acc, quiz) => {
//     if (!acc[quiz.subject]) {
//       acc[quiz.subject] = {
//         total: 0,
//         attempted: 0,
//         completed: 0,
//       };
//     }
//     acc[quiz.subject].total += 1;
//     if (
//       currentUser.attemptedquizs.some(
//         (attemptedQuiz) => attemptedQuiz._id === quiz._id
//       )
//     ) {
//       acc[quiz.subject].attempted += 1;
//     }
//     if (
//       currentUser.completedquizs.some(
//         (completedQuiz) => completedQuiz._id === quiz._id
//       )
//     ) {
//       acc[quiz.subject].completed += 1;
//     }
//     return acc;
//   }, {});

//   return (
//     <div className="container mt-4">
//       <h2>Dashboard</h2>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{currentUser.quizs.length}</td>
//               <td>{currentUser.attemptedquizs.length}</td>
//               <td>
//                 {currentUser.quizs.length - currentUser.attemptedquizs.length}
//               </td>
//               <td>{currentUser.completedquizs.length}</td>
//               <td>
//                 {currentUser.attemptedquizs.length -
//                   currentUser.completedquizs.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <h3 className="mt-4">Udbodh Quizzes</h3>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{udbodhQuizzes.length}</td>
//               <td>{udbodhAttemptedQuizzes.length}</td>
//               <td>{udbodhQuizzes.length - udbodhAttemptedQuizzes.length}</td>
//               <td>{udbodhCompletedQuizzes.length}</td>
//               <td>
//                 {udbodhAttemptedQuizzes.length - udbodhCompletedQuizzes.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <h3 className="mt-4">Bauddha Dakshata Quizzes</h3>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>{bauddhaDakshataQuizzes.length}</td>
//               <td>{bauddhaDakshataAttemptedQuizzes.length}</td>
//               <td>
//                 {bauddhaDakshataQuizzes.length -
//                   bauddhaDakshataAttemptedQuizzes.length}
//               </td>
//               <td>{bauddhaDakshataCompletedQuizzes.length}</td>
//               <td>
//                 {bauddhaDakshataAttemptedQuizzes.length -
//                   bauddhaDakshataCompletedQuizzes.length}
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//       <h3 className="mt-4">Quizzes by Subject</h3>
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th>Subject</th>
//               <th>Total Quiz</th>
//               <th>Attempted Quiz</th>
//               <th>Unattempted Quiz</th>
//               <th>Completed Quiz</th>
//               <th>Incompleted Quiz</th>
//             </tr>
//           </thead>
//           <tbody>
//             {Object.keys(quizzesBySubject).map((subject) => (
//               <tr key={subject}>
//                 <td>{subject}</td>
//                 <td>{quizzesBySubject[subject].total}</td>
//                 <td>{quizzesBySubject[subject].attempted}</td>
//                 <td>
//                   {quizzesBySubject[subject].total -
//                     quizzesBySubject[subject].attempted}
//                 </td>
//                 <td>{quizzesBySubject[subject].completed}</td>
//                 <td>
//                   {quizzesBySubject[subject].attempted -
//                     quizzesBySubject[subject].completed}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// export default Dashboard;

///////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios"; // Import axios

function Dashboard() {
  const userLogin = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userLogin;
  const [userData, setUserData] = useState(null); // State to store fetched user data

  // Fetch user data by ID
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await axios.get(
          `/api/users/getuserbyid/${currentUser._id}`
        );
        setUserData(data);
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    if (currentUser) {
      fetchUserData();
    }
  }, []);

  if (!userData) {
    return <p>Loading...</p>; // Show loading while fetching data
  }

  // Filter quizzes with division "Udbodh"
  const udbodhQuizzes = userData.quizs.filter(
    (quiz) => quiz.division === "Udbodh"
  );
  const udbodhAttemptedQuizzes = userData.attemptedquizs.filter(
    (quiz) => quiz.division === "Udbodh"
  );
  const udbodhCompletedQuizzes = userData.completedquizs.filter(
    (quiz) => quiz.division === "Udbodh"
  );

  // Filter quizzes with division "Bauddha Dakshata"
  const bauddhaDakshataQuizzes = userData.quizs.filter(
    (quiz) => quiz.division === "Bauddha Dakshata"
  );
  const bauddhaDakshataAttemptedQuizzes = userData.attemptedquizs.filter(
    (quiz) => quiz.division === "Bauddha Dakshata"
  );
  const bauddhaDakshataCompletedQuizzes = userData.completedquizs.filter(
    (quiz) => quiz.division === "Bauddha Dakshata"
  );

  // Group quizzes by subject
  const quizzesBySubject = userData.quizs.reduce((acc, quiz) => {
    if (!acc[quiz.subject]) {
      acc[quiz.subject] = {
        total: 0,
        attempted: 0,
        completed: 0,
      };
    }
    acc[quiz.subject].total += 1;
    if (
      userData.attemptedquizs.some(
        (attemptedQuiz) => attemptedQuiz._id === quiz._id
      )
    ) {
      acc[quiz.subject].attempted += 1;
    }
    if (
      userData.completedquizs.some(
        (completedQuiz) => completedQuiz._id === quiz._id
      )
    ) {
      acc[quiz.subject].completed += 1;
    }
    return acc;
  }, {});

  return (
    <div className="container mt-4">
      <h2>Dashboard</h2>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="table-dark">
            <tr>
              <th>Total Quiz</th>
              <th>Attempted Quiz</th>
              <th>Unattempted Quiz</th>
              <th>Completed Quiz</th>
              <th>Incompleted Quiz</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{userData.quizs.length}</td>
              <td>{userData.attemptedquizs.length}</td>
              <td>{userData.quizs.length - userData.attemptedquizs.length}</td>
              <td>{userData.completedquizs.length}</td>
              <td>
                {userData.attemptedquizs.length -
                  userData.completedquizs.length}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 className="mt-4">Udbodh Quizzes</h3>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="table-dark">
            <tr>
              <th>Total Quiz</th>
              <th>Attempted Quiz</th>
              <th>Unattempted Quiz</th>
              <th>Completed Quiz</th>
              <th>Incompleted Quiz</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{udbodhQuizzes.length}</td>
              <td>{udbodhAttemptedQuizzes.length}</td>
              <td>{udbodhQuizzes.length - udbodhAttemptedQuizzes.length}</td>
              <td>{udbodhCompletedQuizzes.length}</td>
              <td>
                {udbodhAttemptedQuizzes.length - udbodhCompletedQuizzes.length}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 className="mt-4">Bauddha Dakshata Quizzes</h3>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="table-dark">
            <tr>
              <th>Total Quiz</th>
              <th>Attempted Quiz</th>
              <th>Unattempted Quiz</th>
              <th>Completed Quiz</th>
              <th>Incompleted Quiz</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{bauddhaDakshataQuizzes.length}</td>
              <td>{bauddhaDakshataAttemptedQuizzes.length}</td>
              <td>
                {bauddhaDakshataQuizzes.length -
                  bauddhaDakshataAttemptedQuizzes.length}
              </td>
              <td>{bauddhaDakshataCompletedQuizzes.length}</td>
              <td>
                {bauddhaDakshataAttemptedQuizzes.length -
                  bauddhaDakshataCompletedQuizzes.length}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3 className="mt-4">Quizzes by Subject</h3>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="table-dark">
            <tr>
              <th>Subject</th>
              <th>Total Quiz</th>
              <th>Attempted Quiz</th>
              <th>Unattempted Quiz</th>
              <th>Completed Quiz</th>
              <th>Incompleted Quiz</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(quizzesBySubject).map((subject) => (
              <tr key={subject}>
                <td>{subject}</td>
                <td>{quizzesBySubject[subject].total}</td>
                <td>{quizzesBySubject[subject].attempted}</td>
                <td>
                  {quizzesBySubject[subject].total -
                    quizzesBySubject[subject].attempted}
                </td>
                <td>{quizzesBySubject[subject].completed}</td>
                <td>
                  {quizzesBySubject[subject].attempted -
                    quizzesBySubject[subject].completed}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Dashboard;
