import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../actions/userActions";
import Error from "../components/Error";
import Loading from "../components/Loading";
import { useLocation, useNavigate } from "react-router";

export default function Loginscreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const page = location.state ? location.state.page : null;
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const loginstate = useSelector((state) => state.loginUserReducer);
  const { loading, error } = loginstate;
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("currentUser")) {
      window.location.href = "/";
    }
  });

  function login() {
    const user = { email, password };
    dispatch(loginUser(user, page));
  }
  return (
    <div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-5 mt-5 text-start shadow-lg p-3 mb-5 bg-white rounded-5">
          <h2
            className="text-center m-2"
            style={{
              fontSize: "35px",
              fontFamily: '"Libre Baskerville", serif',
              fontWeight: "bold",
            }}
          >
            Login
          </h2>

          {loading && <Loading />}
          {error && <Error error="Invalid Credentials" />}

          <div>
            <input
              required
              type="text"
              placeholder="email"
              className="form-control"
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            />
            <input
              type="password"
              placeholder="password"
              className="form-control"
              value={password}
              required
              onChange={(e) => {
                setpassword(e.target.value);
              }}
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            />
            <button
              onClick={login}
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
              className="btn mt-3 mb-3"
            >
              LOGIN
            </button>
            <br></br>
            <a
              style={{
                color: "black",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
              href="/register"
            >
              Click Here To Register if You are a New User
            </a>
            <br></br>
            <a
              style={{
                color: "black",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
              href="/forgotpassword"
            >
              Forgot Password ?
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
