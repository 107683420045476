// import React from "react";

// const Downloadcsv = () => {
//   const handleDownload = () => {
//     fetch("/api/users/exportuser")
//       .then((response) => {
//         if (response.status !== 200) {
//           throw new Error("Failed to download CSV");
//         }
//         return response.blob();
//       })
//       .then((blob) => {
//         const url = window.URL.createObjectURL(blob);
//         const a = document.createElement("a");
//         a.style.display = "none";
//         a.href = url;
//         a.download = "usersData.csv";
//         document.body.appendChild(a);
//         a.click();
//         window.URL.revokeObjectURL(url);
//       })
//       .catch((error) => {
//         console.error("Error downloading CSV:", error);
//       });
//   };

//   return (
//     <div>
//       <button onClick={handleDownload}>Download</button>
//     </div>
//   );
// };

// export default Downloadcsv;
////////////////////////////////////////////////////////////////////////////
// import React, { useState } from "react";

// const Downloadcsv = () => {
//   const [fromDate, setFromDate] = useState("");
//   const [toDate, setToDate] = useState("");

//   const handleDownload = () => {
//     if (!fromDate || !toDate) {
//       alert("Please select both From and To dates.");
//       return;
//     }

//     const query = new URLSearchParams({ fromDate, toDate }).toString();

//     fetch(`/api/users/exportuser?${query}`)
//       .then((response) => {
//         if (response.status !== 200) {
//           throw new Error("Failed to download CSV");
//         }
//         return response.blob();
//       })
//       .then((blob) => {
//         const url = window.URL.createObjectURL(blob);
//         const a = document.createElement("a");
//         a.style.display = "none";
//         a.href = url;
//         a.download = "usersData.csv";
//         document.body.appendChild(a);
//         a.click();
//         window.URL.revokeObjectURL(url);
//       })
//       .catch((error) => {
//         console.error("Error downloading CSV:", error);
//       });
//   };

//   return (
//     <div>
//       <div>
//         <label>
//           From:
//           <input
//             type="date"
//             value={fromDate}
//             onChange={(e) => setFromDate(e.target.value)}
//           />
//         </label>
//         <label>
//           To:
//           <input
//             type="date"
//             value={toDate}
//             onChange={(e) => setToDate(e.target.value)}
//           />
//         </label>
//       </div>
//       <button onClick={handleDownload}>Download</button>
//     </div>
//   );
// };

// export default Downloadcsv;
////////////////////////////////////////////////////////////////////////////////
// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllSchoolcodes } from "../actions/schoolcodeActions";
// import { getAllClassss } from "../actions/classsActions";

// const Downloadcsv = () => {
//   const [fromDate, setFromDate] = useState("");
//   const [toDate, setToDate] = useState("");
//   const [schoolCode, setSchoolCode] = useState("");
//   const [classs, setClasss] = useState("");

//   const dispatch = useDispatch();

//   const schoolcodes = useSelector(
//     (state) => state.getAllSchoolcodesReducer.schoolcodes
//   );
//   const classssState = useSelector((state) => state.getAllClassssReducer);
//   const { classss: classes } = classssState;

//   useEffect(() => {
//     dispatch(getAllSchoolcodes());
//     dispatch(getAllClassss());
//   }, [dispatch]);

//   const handleDownload = () => {
//     if (!fromDate || !toDate) {
//       alert("Please select both From and To dates.");
//       return;
//     }

//     const query = new URLSearchParams({
//       fromDate,
//       toDate,
//       schoolCode: schoolCode || undefined,
//       classs: classs || undefined,
//     }).toString();

//     fetch(`/api/users/exportuser?${query}`)
//       .then((response) => {
//         if (response.status !== 200) {
//           throw new Error("Failed to download CSV");
//         }
//         return response.blob();
//       })
//       .then((blob) => {
//         const url = window.URL.createObjectURL(blob);
//         const a = document.createElement("a");
//         a.style.display = "none";
//         a.href = url;
//         a.download = "usersData.csv";
//         document.body.appendChild(a);
//         a.click();
//         window.URL.revokeObjectURL(url);
//       })
//       .catch((error) => {
//         console.error("Error downloading CSV:", error);
//       });
//   };

//   return (
//     <div>
//       <div>
//         <label>
//           From:
//           <input
//             type="date"
//             value={fromDate}
//             onChange={(e) => setFromDate(e.target.value)}
//           />
//         </label>
//         <label>
//           To:
//           <input
//             type="date"
//             value={toDate}
//             onChange={(e) => setToDate(e.target.value)}
//           />
//         </label>
//         <label>
//           School Code:
//           <select
//             className="form-control"
//             value={schoolCode}
//             onChange={(e) => setSchoolCode(e.target.value)}
//           >
//             <option value="">Select School Code</option>
//             {schoolcodes &&
//               schoolcodes.map((code) => (
//                 <option key={code._id} value={code.code}>
//                   {code.code}
//                 </option>
//               ))}
//           </select>
//         </label>
//         <label>
//           Class:
//           <select
//             className="form-control"
//             value={classs}
//             onChange={(e) => setClasss(e.target.value)}
//           >
//             <option value="">Select Class</option>
//             {classes &&
//               classes.map((cls) => (
//                 <option key={cls._id} value={cls.cls}>
//                   {cls.cls}
//                 </option>
//               ))}
//           </select>
//         </label>
//       </div>
//       <button onClick={handleDownload}>Download</button>
//     </div>
//   );
// };

// export default Downloadcsv;
////////////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSchoolcodes } from "../actions/schoolcodeActions";
import { getAllClassss } from "../actions/classsActions";

const Downloadcsv = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [schoolCode, setSchoolCode] = useState("All");
  const [classs, setClasss] = useState("All");
  const [section, setSection] = useState("All");

  const dispatch = useDispatch();

  const schoolcodes = useSelector(
    (state) => state.getAllSchoolcodesReducer.schoolcodes
  );
  const classssState = useSelector((state) => state.getAllClassssReducer);
  const { classss: classes } = classssState;

  useEffect(() => {
    dispatch(getAllSchoolcodes());
    dispatch(getAllClassss());
  }, [dispatch]);

  const handleDownload = () => {
    if (!fromDate || !toDate) {
      alert("Please select both From and To dates.");
      return;
    }

    const query = new URLSearchParams({
      fromDate,
      toDate,
      schoolCode: schoolCode !== "All" ? schoolCode : "All",
      classs: classs !== "All" ? classs : "All",
      section: section !== "All" ? section : "All",
    }).toString();

    fetch(`/api/users/exportuser?${query}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Failed to download CSV");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "usersData.csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading CSV:", error);
      });
  };

  // return (
  //   <div>
  //     <div>
  //       <label>
  //         From:
  //         <input
  //           type="date"
  //           value={fromDate}
  //           onChange={(e) => setFromDate(e.target.value)}
  //         />
  //       </label>
  //       <label>
  //         To:
  //         <input
  //           type="date"
  //           value={toDate}
  //           onChange={(e) => setToDate(e.target.value)}
  //         />
  //       </label>
  //       <label>
  //         School Code:
  //         <select
  //           className="form-control"
  //           value={schoolCode}
  //           onChange={(e) => setSchoolCode(e.target.value)}
  //         >
  //           <option value="All">All</option>
  //           {schoolcodes &&
  //             schoolcodes.map((code) => (
  //               <option key={code._id} value={code.code}>
  //                 {code.code}
  //               </option>
  //             ))}
  //         </select>
  //       </label>
  //       <label>
  //         Class:
  //         <select
  //           className="form-control"
  //           value={classs}
  //           onChange={(e) => setClasss(e.target.value)}
  //         >
  //           <option value="All">All</option>
  //           {classes &&
  //             classes.map((cls) => (
  //               <option key={cls._id} value={cls.cls}>
  //                 {cls.cls}
  //               </option>
  //             ))}
  //         </select>
  //       </label>

  //       <label>
  //         Section:
  //         <select
  //           className="form-control"
  //           value={section}
  //           onChange={(e) => setSection(e.target.value)}
  //           style={{
  //             marginBottom: "0px",
  //             fontFamily: '"Libre Baskerville", serif',
  //             fontWeight: "bold",
  //           }}
  //         >
  //           <option key="All" value="All">
  //             All
  //           </option>

  //           <option key="Section Unavailable" value="Section Unavailable">
  //             Section Unavailable
  //           </option>
  //           <option key="A" value="A">
  //             A
  //           </option>
  //           <option key="B" value="B">
  //             B
  //           </option>
  //           <option key="C" value="C">
  //             C
  //           </option>
  //           <option key="D" value="D">
  //             D
  //           </option>
  //           <option key="E" value="E">
  //             E
  //           </option>
  //           <option key="F" value="F">
  //             F
  //           </option>
  //           <option key="G" value="G">
  //             G
  //           </option>
  //           <option key="H" value="H">
  //             H
  //           </option>
  //         </select>
  //       </label>
  //     </div>
  //     <button onClick={handleDownload}>Download</button>
  //   </div>
  // );

  return (
    <>
      <hr></hr>
      <h1>Download CSV File</h1>
      <hr></hr>
      <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <label>
            From:
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              style={{ marginLeft: "10px", padding: "5px" }}
            />
          </label>
          <label>
            To:
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              style={{ marginLeft: "35px", padding: "5px" }}
            />
          </label>
          <label>
            School Code:
            <select
              className="form-control"
              value={schoolCode}
              onChange={(e) => setSchoolCode(e.target.value)}
              style={{ marginLeft: "10px", padding: "5px" }}
            >
              <option value="All">All</option>
              {schoolcodes &&
                schoolcodes.map((code) => (
                  <option key={code._id} value={code.code}>
                    {code.code}
                  </option>
                ))}
            </select>
          </label>
          <label>
            Class:
            <select
              className="form-control"
              value={classs}
              onChange={(e) => setClasss(e.target.value)}
              style={{ marginLeft: "10px", padding: "5px" }}
            >
              <option value="All">All</option>
              {classes &&
                classes.map((cls) => (
                  <option key={cls._id} value={cls.cls}>
                    {cls.cls}
                  </option>
                ))}
            </select>
          </label>
          <label>
            Section:
            <select
              className="form-control"
              value={section}
              onChange={(e) => setSection(e.target.value)}
              style={{
                marginLeft: "10px",
                padding: "5px",
                marginBottom: "0px",
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            >
              <option key="All" value="All">
                All
              </option>

              <option key="Section Unavailable" value="Section Unavailable">
                Section Unavailable
              </option>
              <option key="A" value="A">
                A
              </option>
              <option key="B" value="B">
                B
              </option>
              <option key="C" value="C">
                C
              </option>
              <option key="D" value="D">
                D
              </option>
              <option key="E" value="E">
                E
              </option>
              <option key="F" value="F">
                F
              </option>
              <option key="G" value="G">
                G
              </option>
              <option key="H" value="H">
                H
              </option>
            </select>
          </label>
          <button
            onClick={handleDownload}
            style={{
              padding: "10px",
              marginTop: "10px",
              marginLeft: "20px",
              cursor: "pointer",
              backgroundColor: "#ff0000",
              color: "white",
              border: "none",
              borderRadius: "5px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              transition: "background-color 0.3s",
            }}
          >
            Download
          </button>
        </div>
      </div>
    </>
  );
};

export default Downloadcsv;
