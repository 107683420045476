import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import Loading from "../components/Loading";
import Error from "../components/Error";
import Success from "../components/Success";

export default function UploadCSV() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      setError("Please select a file to upload.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/api/students/uploadcsv", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSuccess(response.data);
    } catch (error) {
      setError("Error uploading file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <hr></hr>
      <h1>Upload CSV File</h1>
      <hr></hr>
      {loading && <Loading />}
      {error && <Error error={error} />}
      {success && <Success success={success} />}
      <form onSubmit={handleUpload}>
        <div className="form-group">
          <input type="file" onChange={handleFileChange} />
        </div>
        <button className="btn btn-primary" type="submit">
          Upload
        </button>
      </form>
    </div>
  );
}
