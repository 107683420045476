export const getAllStudentsReducer = (state = { students: [] }, action) => {
  switch (action.type) {
    case "GET_STUDENTS_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_STUDENTS_SUCCESS":
      return {
        loading: false,
        students: action.payload,
      };
    case "GET_STUDENTS_FAILED":
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const addStudentReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD_STUDENT_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "ADD_STUDENT_SUCCESS":
      return {
        loading: false,
        success: true,
      };
    case "ADD_STUDENT_FAILED":
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const editStudentReducer = (state = {}, action) => {
  switch (action.type) {
    case "EDIT_STUDENT_REQUEST":
      return {
        editloading: true,
        ...state,
      };
    case "EDIT_STUDENT_SUCCESS":
      return {
        editloading: false,
        editsuccess: true,
      };
    case "EDIT_STUDENT_FAILED":
      return {
        editerror: action.payload,
        editloading: false,
      };
    default:
      return state;
  }
};

export const getStudentByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_STUDENTBYID_REQUEST":
      return {
        loading: true,
        ...state,
      };
    case "GET_STUDENTBYID_SUCCESS":
      return {
        loading: false,
        student: action.payload,
      };
    case "GET_STUDENTBYID_FAILED":
      return {
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
