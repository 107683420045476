import axios from "axios";
export const getAllStudents = () => async (dispatch) => {
  dispatch({ type: "GET_STUDENTS_REQUEST" });

  try {
    const response = await axios.get(`/api/students/getallstudents`);
    console.log(response);
    dispatch({ type: "GET_STUDENTS_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({ type: "GET_STUDENTS_FAILED", payload: error });
  }
};

export const addStudent = (student) => async (dispatch) => {
  dispatch({ type: "ADD_STUDENT_REQUEST" });
  try {
    const response = await axios.post(`/api/students/addstudent`, { student });
    console.log(response);
    dispatch({ type: "ADD_STUDENT_SUCCESS" });
  } catch (error) {
    dispatch({ type: "ADD_STUDENT_FAILED", payload: error });
  }
};

export const editStudent = (editedstudent) => async (dispatch) => {
  dispatch({ type: "EDIT_STUDENT_REQUEST" });
  try {
    const response = await axios.post(`/api/students/editstudent`, {
      editedstudent,
    });
    console.log(response);
    dispatch({ type: "EDIT_STUDENT_SUCCESS" });
    window.location.href = "/admin/studentslist";
  } catch (error) {
    dispatch({ type: "EDIT_STUDENT_FAILED", payload: error });
  }
};

export const getStudentById = (studentid) => async (dispatch) => {
  dispatch({ type: "GET_STUDENTBYID_REQUEST" });

  try {
    const response = await axios.post(`/api/students/getstudentbyid`, {
      studentid,
    });
    console.log(response);
    dispatch({ type: "GET_STUDENTBYID_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({ type: "GET_STUDENTBYID_FAILED", payload: error });
  }
};

export const deleteStudent = (studentid) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/students/deletestudent`, {
      studentid,
    });
    alert("Student Deleted Successfully");
    console.log(response);
    //window.location.reload();
    window.location.href = "/adminpanel";
  } catch (error) {
    alert("Something went wrong");
    console.log(error);
  }
};
