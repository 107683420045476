import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./verification.css";

export default function Verification() {
  const navigate = useNavigate();
  const [userOTP, setUserOTP] = useState("");
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          toast.error("Verification time expired. Please register again.");
          navigate("/register");
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  async function handleVerification(e) {
    e.preventDefault();
    if (userOTP === "") {
      return toast.error("Please enter the otp...");
    }
    const callAPI = await fetch("/api/users/verificationcheck", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ otp: userOTP }),
    });
    const response = await callAPI.json();
    if (response.error) {
      return toast.error(response.error);
    }
    //alert("User Registered Successfully");
    toast.success("User Registered Successfully");
    navigate("/login");
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div className="verification-container">
        <h2>OTP Verification</h2>
        <form onSubmit={handleVerification}>
          <input
            type="text"
            name="otp"
            onChange={(e) => setUserOTP(e.target.value)}
            placeholder="Enter OTP Recieved on your Email..."
          />
          <button type="submit">Verify</button>
        </form>
        <div className="timer">
          <p style={{ color: "white" }}>Time left: {formatTime(timeLeft)}</p>
        </div>
      </div>
    </div>
  );
}
